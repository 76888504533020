import { DatePipe } from '@angular/common';

export class Transaction
{ 
    $key?: string;
    ide: string;
    date :any;
    Id:string;
    status:string;
    iduser:string;
    voucher:string;
    coupon:string;
    package:string;
    credit:number;
    amount:number;
    adress:string
    state:string;
    city:string
    email:string;
    idAnalyse:string;
    zipCode:any;
    nomDomaine:string;
    type:string;

}