import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { QueryRealtimeParamsModel, HttpExtenstionsModel } from '../../_base/crud';
import { TransactioRedModel } from '../_models/TransactioRedModel';
import { TransactioRedState } from '../_reducers/TransactioRed.reducers';

export const selectTransactioRedsState = createFeatureSelector<TransactioRedState>('TransactioRed');

export const selectTransactioRedById = (TransactioRedId: number) => createSelector(
    selectTransactioRedsState,
    TransactioRedState => TransactioRedState.entities[TransactioRedId]
);

export const selectTransactioRedsPageLoading = createSelector(
    selectTransactioRedsState,
    TransactioRedState => TransactioRedState.listLoading
);

export const selectTransactioRedsActionLoading = createSelector(
    selectTransactioRedsState,
    TransactioRedState => TransactioRedState.actionsloading
);

export const selectLastCreatedTransactioRedId = createSelector(
    selectTransactioRedsState,
    TransactioRedState => TransactioRedState.lastCreatedTransactioRedId
);

export const selectTransactioRedsShowInitWaitingMessage = createSelector(
    selectTransactioRedsState,
    TransactioRedState => TransactioRedState.showInitWaitingMessage
);

export const selectTransactioRedsInStore = createSelector(
    selectTransactioRedsState,
    TransactioRedState => {
        const items: TransactioRedModel[] = [];
        each(TransactioRedState.entities, element => {
            items.push(element);
        });        
        const httpExtension = new HttpExtenstionsModel();
        const result: TransactioRedModel[] = httpExtension.sortArray(items, TransactioRedState.lastQuery.sortField, TransactioRedState.lastQuery.sortOrder);
        return new QueryRealtimeParamsModel(result);
    }
);

