import { mergeMap, tap } from 'rxjs/operators';
import { delay, distinctUntilChanged, skip, filter, take, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { BaseDataSource, QueryRealtimeParamsModel } from '../../_base/crud';
import { AppState } from '../../reducers';
import { selectTransactioRedsInStore, selectTransactioRedsPageLoading, selectTransactioRedsShowInitWaitingMessage } from '../_selectors/TransactioRed.selectors';


export class TransactioRedsDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
        super();

		this.loading$ = this.store.pipe(
			select(selectTransactioRedsPageLoading),
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectTransactioRedsShowInitWaitingMessage)
        );
        
        this.store.pipe(
			select(selectTransactioRedsInStore),
		).subscribe((response: QueryRealtimeParamsModel) => {
			// this.paginatorTotalSubject.next(response.totalCount);			
			this.entitySubject.next(response.items);
		});

    }
}