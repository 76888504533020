import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, NgZone, ChangeDetectorRef } from '@angular/core';
import { PropertyAnalysisModel, PropertyAnalysissDataSource, PropertyAnalysissPageRequested, OnePropertyAnalysisDeleted, PropertyAnalysisOnServerCreated, PropertyAnalysissService } from '../../../../core/property-analysis';
import { StaticData } from '../../../../core/property-analysis/_models/StaticData';
import { MapsAPILoader } from '@agm/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { FormControl } from '@angular/forms';
import { google } from "google-maps";
import { AppState } from '../../../../core/reducers';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { MomentDateFormatter } from '../../../../core/property-analysis/_services/ngbDateCustomParserFormetter';
import { DatePipe } from '@angular/common';
import * as moment from 'moment'
import swal from 'sweetalert2';
@Component({
  selector: 'kt-addgeneral',
  templateUrl: './addgeneral.component.html',
  styleUrls: ['./addgeneral.component.scss']
})
export class AddgeneralComponent implements OnInit {

  @ViewChild('wizard', { static: true }) el: ElementRef;
  //  @ViewChild('wizard2', {static: true}) e2: ElementRef;
  @ViewChild('wizard2', { static: false }) e2: ElementRef;
  @ViewChild('wizard3', { static: false }) e3: ElementRef;
  @ViewChild('wizard4', { static: false }) e4: ElementRef;
  // }
  latitude: number = 0;
  longitude: number = 0;
  zoom: number;
  submitted = false;
  google: google;




  propertyConditions = StaticData.propertyConditions;
  constructionQualitys = StaticData.constructionQualitys;
  constructionTypes = StaticData.constructionTypes;
  buildingClasss = StaticData.buildingClasss;
  parkingTypes = StaticData.parkingTypes;
  industrial = StaticData.industrial;
  multiFamily = StaticData.multiFamily;
  office = StaticData.office;
  retail = StaticData.retail;
  years = StaticData.years;
  private propertyAnalysis = new PropertyAnalysisModel();
  @ViewChild('f', { static: true }) analyisForm: NgForm;
  next: boolean = false;
  selectedIndex = 0;
  address: string;
  idDomaine: string;

  estimateScore: number;
  estimateValue: number;


  yearfinace: number = 1;
  zipcodeExist: boolean = false;
  pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }


  id: string;

  @ViewChild("search", { static: true })
  public searchElementRef: ElementRef;
  marker: any[];
  mapAddress: any;
  yearOne: boolean = false;
  yearTwo: boolean = false;
  yearThree: boolean = false;
  ExpensesDetails: boolean = false;
  form: FormGroup;
  form2: FormGroup;
  form3: FormGroup;
  form4: FormGroup;
  form5: FormGroup;
  form6: FormGroup;
  today: number = Date.now();
  today2: any;
  nums: number[] = [];
  private _matDialog: MatDialog;
  list: any;
  registerForm: FormGroup;
  options = {
    startStep: 1,
    clickableSteps: false
  };
  youCanSendIt: boolean = true;
  ok = false
  data: any[];
  addressOk: boolean = true;
  domaine;
  datenow: any;
  api_key;
  product_Typedomaine;
  constructor(
    public snackBar: MatSnackBar,
    private store: Store<AppState>,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public router: Router,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private authService: AuthService,
    private _cdr: ChangeDetectorRef,
    private analysisDataService: PropertyAnalysissService,
    private firestore: AngularFirestore,
    private MomentDateFormatter: MomentDateFormatter,
    private changeDetectorRef: ChangeDetectorRef,
    public datePipe: DatePipe,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe(params => {
      this.api_key = params['api_key'];
      this.propertyAnalysis.name = params['name'];
      this.propertyAnalysis.surname = params['surname'];
      this.propertyAnalysis.userid = params['userid'];
      this.propertyAnalysis.bankid = params['bank'];
      this.propertyAnalysis.projectid = params['projectid'];
      this.propertyAnalysis.firstreport = params['firstreport'];
      this.id = this.firestore.createId();
      this.analysisDataService.gettoken(this.api_key).subscribe(res => {
        if (res === null) {
          this.router.navigate(['/auth/underConstrunctionPage']);
        } else {
          this.idDomaine = res.eid;
          this.authService.getBusinessName(this.idDomaine).subscribe(
            (list: any) => {
              if (list) {
                this.domaine = list
                this.changeStatus();
                this.product_Typedomaine = list.product_Type;
              }
            }
          );
        }
      });

    });
    this.datenow = (new Date()).getFullYear();
    this.today2 = datePipe.transform(this.today, 'MM/dd/yyyy');
    // this.propertyAnalysis.valuationDate = this.today2;
    this.propertyAnalysis.datelistPrice = this.today2;
    // this.authService.user$.subscribe(res => {
    //   this.idDomaine = res.idDomaine;
    // });
    this.form = this._formBuilder.group({
      year: ['', Validators.compose([Validators.max(this.datenow), Validators.min(1900)])]
    }
    );
    this.form4 = this._formBuilder.group({
      RenoYr: ['', Validators.compose([Validators.max(this.datenow), Validators.min(1950)])]
    }
    );
    this.form2 = this._formBuilder.group({
      numBuild: ['', Validators.compose([Validators.max(100), Validators.min(1)])]
    });
    this.form3 = this._formBuilder.group({
      occupancyRate: ['', Validators.compose([Validators.max(100), Validators.min(0)])]
    });
    this.form5 = this._formBuilder.group({
      percentAC: ['', Validators.compose([Validators.max(100), Validators.min(0)])]
    });
    this.form6 = this._formBuilder.group({
      VaccancyStabOne: ['', Validators.compose([Validators.max(99), Validators.min(0)])]
    });
  }
  free: number = -1;
  gofree(i) {

    this.free = +i;
  }
  date: Date;
  ngOnInit() {
    this.propertyAnalysis.netRentableArea = "";
    this.propertyAnalysis.review = 2;
    // this.propertyAnalysis.grossBuildingArea = "";
    this.propertyAnalysis.numberStories = "";
    this.propertyAnalysis.ProductType = 'Valnow Valuation';

    this.propertyAnalysis.valuationDate = {
      "year": parseInt(moment(new Date()).format('YYYY')),
      "month": parseInt(moment(new Date()).format('MM')),
      "day": parseInt(moment(new Date()).format('DD'))
    }
    this.zoom = 10;
    this.latitude = 36.778259;
    this.longitude = -119.417931;

    this.setCurrentPosition();
    this.mapsAPILoader.load().then(res => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: [],
      });

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.propertyAnalysis.address = place;
          this.mapAddress = place.formatted_address;
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.propertyAnalysis.longitude = this.longitude;
          this.propertyAnalysis.latitude = this.latitude;
          this.zoom = 12;
          if (this.propertyAnalysis.latitude == 0 && this.propertyAnalysis.longitude == 0) {
            this.addressOk = false;
          } else {
            this.addressOk = true;
          }
          for (var i = 0; i < place.address_components.length; i++) {
            for (var j = 0; j < place.address_components[i].types.length; j++) {

              if (place.address_components[i].types[j] == "locality") {
                this.propertyAnalysis.city = place.address_components[i].long_name;
              }
              if (place.address_components[i].types[j] == "administrative_area_level_1") {
                this.propertyAnalysis.state = place.address_components[i].long_name;
              }
              if (place.address_components[i].types[j] == "postal_code") {
                // this.getZips(place.address_components[i].long_name);
                this.getZips('');
                this.propertyAnalysis.zipCode = this.pad(parseInt(place.address_components[i].long_name), 5)
              }
            }
            this.changeStatus();

          }
        });
      });
    });
  }
  calculeTotal() {
    if (!this.propertyAnalysis.autoNET) {
      if (this.propertyAnalysis.total === false || !this.propertyAnalysis.total) {
        this.propertyAnalysis.netOperatingIncomeYearOne = this.propertyAnalysis.effectiveGrossIncomeYearOne - this.propertyAnalysis.totalExpensesUserEnteredYearOne
      } else if (this.propertyAnalysis.total === true) {
        this.propertyAnalysis.netOperatingIncomeYearOne = this.propertyAnalysis.effectiveGrossIncomeYearOne - (this.propertyAnalysis.payrollYearOne +
          this.propertyAnalysis.administrationYearOne +
          this.propertyAnalysis.utilitiesYearOne +
          this.propertyAnalysis.repairsMaintenanceYearOne +
          this.propertyAnalysis.cleaningJanitorialYearOne +
          this.propertyAnalysis.camYearOne +
          this.propertyAnalysis.realEstateTaxesYearOne +
          this.propertyAnalysis.insuranceYearOne +
          this.propertyAnalysis.replacementReservesYearOne)
      }
    }
  }
  changeStatus(): void {

    setTimeout(() => {

      this._cdr.detectChanges()
      this._cdr.markForCheck()

    }, 500);
  }
  Onlycalcul() {
    this.propertyAnalysis.autoEffective = !this.propertyAnalysis.autoEffective
    this.calculEffective()
  }
  retur() {
    const wizard = new KTWizard(this.el.nativeElement, {
      startStep: 2
    });
    wizard.goLast();

  }
  Onlycalcul2() {
    this.propertyAnalysis.autoNET = !this.propertyAnalysis.autoNET
    this.calculeTotal()
  }
  financial(x) {
    swal.fire({
      title: 'Providing operating data can significantly improve the accuracy of the valuation',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Got it !'
    })
  }
  calculEffective() {
    if ((this.propertyAnalysis.potentialGrossIncome || this.propertyAnalysis.VaccancyStabOne) && !this.propertyAnalysis.autoEffective) {
      this.propertyAnalysis.effectiveGrossIncomeYearOne = ((1 - (this.propertyAnalysis.VaccancyStabOne / 100)) * this.propertyAnalysis.potentialGrossIncome)
    }
  }
  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;


      });
    }
  }
  onLeft() {
    if (this.yearfinace == 1) {
      this.yearfinace = 3;
      setTimeout(() => { this.changeDetectorRef.detectChanges(); }, 500);
    } else if (this.yearfinace == 3) {
      this.yearfinace = 2;
      setTimeout(() => { this.changeDetectorRef.detectChanges(); }, 500);
    } else
      if (this.yearfinace = 2) {
        this.yearfinace = 1;
        setTimeout(() => { this.changeDetectorRef.detectChanges(); }, 500);
      }
  }
  onRight() {
    if (this.yearfinace == 1) {
      this.yearfinace = 2;
      setTimeout(() => { this.changeDetectorRef.detectChanges(); }, 500);
    } else
      if (this.yearfinace == 2) {
        this.yearfinace = 3;
        setTimeout(() => { this.changeDetectorRef.detectChanges(); }, 500);
      } else
        if (this.yearfinace == 3) {
          this.yearfinace = 1;
          setTimeout(() => { this.changeDetectorRef.detectChanges(); }, 500);
        }
  }
  calculeYearOne() {
    if (!this.AutoTotalOperatingExpensesYearOneSwitch) {
      this.propertyAnalysis.totalExpensesUserEnteredYearOne =
        this.propertyAnalysis.payrollYearOne +
        this.propertyAnalysis.administrationYearOne +
        this.propertyAnalysis.utilitiesYearOne +
        this.propertyAnalysis.repairsMaintenanceYearOne +
        this.propertyAnalysis.cleaningJanitorialYearOne +
        this.propertyAnalysis.camYearOne +
        this.propertyAnalysis.realEstateTaxesYearOne +
        this.propertyAnalysis.insuranceYearOne +
        this.propertyAnalysis.replacementReservesYearOne
    }
    this.propertyAnalysis.netOperatingIncomeYearOne = this.propertyAnalysis.effectiveGrossIncomeYearOne - this.propertyAnalysis.totalExpensesUserEnteredYearOne
  }



  calculeYearTwo() {
    if (!this.AutoTotalOperatingExpensesYearTwoSwitch) {
      this.propertyAnalysis.totalExpensesUserEnteredYearTwo =
        this.propertyAnalysis.payrollYearTwo +
        this.propertyAnalysis.administrationYearTwo +
        this.propertyAnalysis.utilitiesYearTwo +
        this.propertyAnalysis.repairsMaintenanceYearTwo +
        this.propertyAnalysis.cleaningJanitorialYearTwo +
        this.propertyAnalysis.camYearTwo +
        this.propertyAnalysis.realEstateTaxesYearTwo +
        this.propertyAnalysis.insuranceYearTwo +
        this.propertyAnalysis.replacementReservesYearTwo
    }
    this.propertyAnalysis.netOperatingIncomeYearTwo = this.propertyAnalysis.effectiveGrossIncomeYearTwo - this.propertyAnalysis.totalExpensesUserEnteredYearTwo
  }

  calculeYearThree() {
    if (!this.AutoTotalOperatingExpensesYearThreeSwitch) {
      this.propertyAnalysis.totalExpensesUserEnteredYearThree =
        this.propertyAnalysis.payrollYearThree +
        this.propertyAnalysis.administrationYearThree +
        this.propertyAnalysis.utilitiesYearThree +
        this.propertyAnalysis.repairsMaintenanceYearThree +
        this.propertyAnalysis.cleaningJanitorialYearThree +
        this.propertyAnalysis.camYearThree +
        this.propertyAnalysis.realEstateTaxesYearThree +
        this.propertyAnalysis.insuranceYearThree +
        this.propertyAnalysis.replacementReservesYearThree
    }
    this.propertyAnalysis.netOperatingIncomeYearThree = this.propertyAnalysis.effectiveGrossIncomeYearThree - this.propertyAnalysis.totalExpensesUserEnteredYearThree
  }

  goLast() {
    const wizard = new KTWizard(this.el.nativeElement, this.options);
    wizard.goNext();
  }
  ngAfterViewInit(): void {
    // Initialize form wizard
    const wizard = new KTWizard(this.el.nativeElement, this.options);
    wizard.on('beforeNext', function (wizardObj) {

    });

    // Change event
    wizard.on('change', function (wizard) {
      var options = {
        startStep: 1,
        clickableSteps: false
      };
      setTimeout(function () {
        KTUtil.scrollTop();
      }, 500);
    });
    const wizard2 = new KTWizard2(this.e2.nativeElement, {
      startStep: 1
    });
    wizard2.on('beforeNext', function (wizardObj) {
    });
    wizard2.on('change', function (wizard) {
      var options = {
        clickableSteps: false
      };
      setTimeout(function () {
        KTUtil.scrollTop();
      }, 500);
    });

    const wizard3 = new KTWizard2(this.e3.nativeElement, {
      startStep: 1
    });
    wizard3.on('beforeNext', function (wizardObj) {
    });
    wizard3.on('change', function (wizard) {
      var options = {
        startStep: 1,
        clickableSteps: false
      };
      setTimeout(function () {
        KTUtil.scrollTop();
      }, 500);
    });

    const wizard4 = new KTWizard2(this.e4.nativeElement, {
      startStep: 1
    });

    wizard4.on('beforeNext', function (wizardObj) {
    });

    wizard4.on('change', function (wizard) {
      setTimeout(function () {
        KTUtil.scrollTop();
      }, 500);
    });

  }
  good = false;
  getZips(zip) {
    if (!this.propertyAnalysis.propertyType) {
      swal.fire({
        icon: 'error',
        title: 'Property type is mandatory',
        text: '',
      });
    } else {
      if (zip.length === 5) {
        this.good = null;
        swal.fire({
          text: 'Checking to determine if there is sufficient relevant data for the analysis',
          title: 'Please wait....',
          timer: 2000,
          allowOutsideClick: false,
          timerProgressBar: true,
          onBeforeOpen: () => {
            swal.showLoading();
            this.analysisDataService.getCheck_existing_properties(zip, this.propertyAnalysis.propertyType).subscribe(list => {
              this.changeStatus();
              if (list === 'E') {
                this.good = false;
              } else {
                this.good = true;
              }

            })
          }
        })

      } else {
        this.good = false;
      }
    }

  }
  verifZipCode(zip) {
    // for (var i = 0; i <= this.nums.length; i++) {
    if (this.good === true) {
      return true
      // }
    }
    return false;
  }
  verifData(propertyAnalysis: PropertyAnalysisModel) {
    var okName = false;
    var okType = false;
    var okZipCode = false;
    var oktype = false;
    if (!propertyAnalysis.propertyType) {
      okType = false;
      swal.fire({
        icon: 'error',
        title: 'Property type is mandatory',
        text: '',
      });
    } else {
      okType = true;
    }
    if (!propertyAnalysis.propertySubType) {
      okName = false;
      swal.fire({
        icon: 'error',
        title: 'Property SubType is required',
        text: '',
      });
    } else {

      okName = true;
    }
    if (!propertyAnalysis.ProductType) {
      oktype = false;
      swal.fire({
        icon: 'error',
        title: 'Property  report Type is required',
        text: '',
      });
    } else {

      oktype = true;
    }
    // if (propertyAnalysis.valuationDate === "") {
    //   okName2 = false;

    //   this.snackBar.open('Valuation Date is required', '', {
    //     verticalPosition: 'top',
    //     duration: 4000,
    //     panelClass: ['error-toaster']
    //   });
    // } else {

    //   okName2 = true;
    // }
    // if (!this.propertyAnalysis.zipCode && this.propertyAnalysis.zipCode == undefined) {
    if (!this.propertyAnalysis.zipCode || this.pad(this.propertyAnalysis.zipCode, 5).toString().length != 5 || this.propertyAnalysis.zipCode == 0 || this.propertyAnalysis.zipCode == undefined) {
      swal.fire({
        icon: 'error',
        title: 'We need to check your zipcode eligibility. <br> Please go back to Subject Property and click NEXT',
        text: '',
      });
    } else {
      // if (this.verifZipCode(this.propertyAnalysis.zipCode)) {
      //   okZipCode = true;
      // } else {
      okZipCode = true;
      // this.snackBar.open('This ZipCode is out of our range', '', {
      //   verticalPosition: 'top',
      //   duration: 4000,
      //   panelClass: ['error-toaster']
      // });
      // swal.fire({
      //   icon: 'error',
      //   title: 'The zip code is out of our scope of data used for the analysis. We cannot provide you with an automated valuation at this moment.',
      // })
      // }
    }

    return okName && okType && okZipCode && oktype
  }
  onNext(propertyAnalysis: PropertyAnalysisModel) {
    console.log(this.good);

    // if (!this.next) {
    var options = {
      startStep: 1,
      clickableSteps: true
    };

    // this.analyisForm.form.markAllAsTouched();
    const wizard = new KTWizard(this.el.nativeElement, {
      startStep: 1
    });

    if (this.good != true) {
      if (!this.propertyAnalysis.propertyType) {
        swal.fire({
          icon: 'error',
          title: 'Property type is mandatory',
          text: '',
        });
        this.good = false;
        this.next = false;
        // this.propertyAnalysis.valuationDate = {
        //   "year": parseInt(moment(new Date()).format('YYYY')),
        //   "month": parseInt(moment(new Date()).format('MM')),
        //   "day": parseInt(moment(new Date()).format('DD'))
        // }
        wizard.stop();
      } else {
        if (this.verifData(propertyAnalysis) && this.verifAdress2()) {
          this.good = null;
          wizard.stop();
          swal.fire({
            text: 'Checking to determine if there is sufficient relevant data for the analysis',
            title: 'Please wait....',
            timer: 2000,
            allowOutsideClick: false,
            timerProgressBar: true,
            onBeforeOpen: () => {
              swal.showLoading();
              this.analysisDataService.getCheck_existing_properties(this.propertyAnalysis.zipCode.toString(), this.propertyAnalysis.propertyType).subscribe(list => {
                this.changeStatus();
                if (list === 'E') {
                  this.good = false;
                  this.next = false;
                  // this.propertyAnalysis.valuationDate = {
                  //   "year": parseInt(moment(new Date()).format('YYYY')),
                  //   "month": parseInt(moment(new Date()).format('MM')),
                  //   "day": parseInt(moment(new Date()).format('DD'))
                  // }
                  swal.fire({
                    icon: 'error',
                    title: 'Unfortunately, we don’t have enough relevant data in the MSA related to the zip code you specified. We cannot provide you with an automated valuation at this stage.',
                    text: '',
                  });
                  wizard.stop();
                } else {
                  const datet = this.propertyAnalysis.valuationDate;
                  const date = this.MomentDateFormatter.format(this.propertyAnalysis.valuationDate);
                  this.propertyAnalysis.valuationDate = date;
                  const dd = new Date(this.propertyAnalysis.valuationDate);
                  this.propertyAnalysis.valuationDate2 = dd.getTime();
                  this.good = true;
                  // if (this.verifData(propertyAnalysis) && this.verifAdress(propertyAnalysis)) {
                  this.propertyAnalysis.draft = 1;
                  this.propertyAnalysis.longitude = this.longitude;
                  this.propertyAnalysis.latitude = this.latitude;
                  this.next = true;
                  this.propertyAnalysis.address = this.mapAddress;
                  this.propertyAnalysis.createdBy = '';
                  this.propertyAnalysis.createdByName = '';
                  this.propertyAnalysis.estimateValue = this.estimateValue;
                  this.propertyAnalysis.estimateScore = this.estimateScore;
                  wizard.goNext();
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  });
                  console.log(this.propertyAnalysis);
                  console.log(this.idDomaine);
                  console.log(this.id);

                  this.analysisDataService.creatAnalysisall(this.propertyAnalysis, this.idDomaine, this.id);
                  this.propertyAnalysis.valuationDate = datet;
                  const d = new Date(this.propertyAnalysis.valuationDate);
                  this.propertyAnalysis.valuationDate2 = d.getTime();
                  this.selectedIndex = 1;
                  // } else {
                  //   this.next = false; 
                  //   wizard.stop();
                  //   this.propertyAnalysis.valuationDate = datet;
                  //   const d = new Date(this.propertyAnalysis.valuationDate);
                  //   this.propertyAnalysis.valuationDate2 = d.getTime();

                  // }
                }

              })
            }
          })
        } else {
          this.good = false;
          this.next = false;
          // this.propertyAnalysis.valuationDate = {
          //   "year": parseInt(moment(new Date()).format('YYYY')),
          //   "month": parseInt(moment(new Date()).format('MM')),
          //   "day": parseInt(moment(new Date()).format('DD'))
          // }
          wizard.stop();


        }
      }
    } else {
      wizard.goNext();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
    // } else {
    // }


  }

  onReset() {
    this.next = false;
    this.address = "";
    this.analyisForm.reset();
    this.propertyAnalysis = new PropertyAnalysisModel();
  }

  onDuplicate() {
    this.propertyAnalysis.address = this.mapAddress;
    this.propertyAnalysis.createdBy = this.authService.authUser.email;
    this.propertyAnalysis.createdByName = this.authService.authUser.displayName;
    this.propertyAnalysis.createdOn = Date.now();
    this.propertyAnalysis.estimateValue = this.estimateValue;
    this.propertyAnalysis.estimateScore = this.estimateScore;
    const date = this.MomentDateFormatter.format(this.propertyAnalysis.valuationDate);
    this.propertyAnalysis.valuationDate = date;
    const d = new Date(this.propertyAnalysis.valuationDate);
    this.propertyAnalysis.valuationDate2 = d.getTime();
    const date2 = this.MomentDateFormatter.format(this.propertyAnalysis.dateMostRecentSale);
    this.propertyAnalysis.dateMostRecentSale = date2;

    const date3 = this.MomentDateFormatter.format(this.propertyAnalysis.dateRecentAppraisal);
    this.propertyAnalysis.dateRecentAppraisal = date3;

    // const date4 = this.MomentDateFormatter.format(this.propertyAnalysis.PriorDate);
    // this.propertyAnalysis.PriorDate = date4;
    // const date4 = this.MomentDateFormatter.format(this.propertyAnalysis.datelistPrice);
    // this.propertyAnalysis.datelistPrice = date4;

    this.analysisDataService.creatAnalysis(this.propertyAnalysis, this.idDomaine, this.id);
    // this.router.navigate(['/list-property-analysis/myList']);
  }
  getTEValeus() {
    var yearOneTE: number = 0;
    var yearTwoTE: number = 0;
    var yearThreeTE: number = 0;
    var cmpt = 0;

    if (this.propertyAnalysis.totalExpensesUserEnteredYearOne) {
      yearOneTE = this.propertyAnalysis.totalExpensesUserEnteredYearOne;
      cmpt = cmpt + 1;

    }
    if (this.propertyAnalysis.totalExpensesUserEnteredYearTwo) {
      yearTwoTE = this.propertyAnalysis.totalExpensesUserEnteredYearTwo;
      cmpt = cmpt + 1;

    }
    if (this.propertyAnalysis.totalExpensesUserEnteredYearThree) {
      yearThreeTE = this.propertyAnalysis.totalExpensesUserEnteredYearThree;
      cmpt = cmpt + 1;

    }

    if ((yearOneTE != 0) || (yearTwoTE != 0) || (yearThreeTE != 0)) {
      if (cmpt >= 2) {
        return (yearOneTE * 10) / 100 + (yearTwoTE * 20) / 100 + (yearThreeTE * 70) / 100
      }
      else {
        return yearOneTE + yearTwoTE + yearThreeTE;
      }
    } else {
      return "";
    }
  }
  getVacuValeus() {
    var yearOneGI: number = 0;
    var yearTwoGI: number = 0;
    var yearThreeGI: number = 0;
    var cmpt = 0;
    if (this.propertyAnalysis.VaccancyStabOne) {
      yearOneGI = this.propertyAnalysis.VaccancyStabOne;
      cmpt = cmpt + 1;

    }

    if (this.propertyAnalysis.VaccancyStabTow) {
      yearTwoGI = this.propertyAnalysis.VaccancyStabTow;
      cmpt = cmpt + 1;
    }

    if (this.propertyAnalysis.VaccancyStabThree) {
      yearThreeGI = this.propertyAnalysis.VaccancyStabThree;
      cmpt = cmpt + 1;
    }

    if ((yearOneGI != 0) || (yearTwoGI != 0) || (yearThreeGI != 0)) {
      if (cmpt >= 2) {
        return (yearOneGI * 10) / 100 + (yearTwoGI * 20) / 100 + (yearThreeGI * 70) / 100
      }
      else {
        return yearOneGI + yearTwoGI + yearThreeGI;
      }
    } else {
      return "";
    }
  }
  getNOIValeus() {
    var yearOneNOI: number = 0;
    var yearTwoNOI: number = 0;
    var yearThreeNOI: number = 0;
    var cmpt = 0;

    if (this.propertyAnalysis.netOperatingIncomeYearOne) {
      yearOneNOI = this.propertyAnalysis.netOperatingIncomeYearOne;
    }

    if (this.propertyAnalysis.netOperatingIncomeYearTwo) {
      yearTwoNOI = this.propertyAnalysis.netOperatingIncomeYearTwo;
    }

    if (this.propertyAnalysis.netOperatingIncomeYearThree) {
      yearThreeNOI = +this.propertyAnalysis.netOperatingIncomeYearThree;
    }

    if ((yearOneNOI != 0) || (yearTwoNOI != 0) || (yearThreeNOI != 0)) {
      if (cmpt >= 2) {
        return (yearOneNOI * 10) / 100 + (yearTwoNOI * 20) / 100 + (yearThreeNOI * 70) / 100
      }
      else {
        return yearOneNOI + yearTwoNOI + yearThreeNOI;
      }
    }

    if ((yearOneNOI != 0) || (yearTwoNOI != 0) || (yearThreeNOI != 0)) {
      return (yearOneNOI * 10) / 100 + (yearTwoNOI * 20) / 100 + (yearThreeNOI * 70) / 100
    } else {
      return "";
    }
  }
  getGEIValeus() {
    var yearOneGI: number = 0;
    var yearTwoGI: number = 0;
    var yearThreeGI: number = 0;
    var cmpt = 0;
    if (this.propertyAnalysis.effectiveGrossIncomeYearOne) {
      yearOneGI = this.propertyAnalysis.effectiveGrossIncomeYearOne;
      cmpt = cmpt + 1;

    }

    if (this.propertyAnalysis.effectiveGrossIncomeYearTwo) {
      yearTwoGI = this.propertyAnalysis.effectiveGrossIncomeYearTwo;
      cmpt = cmpt + 1;
    }

    if (this.propertyAnalysis.effectiveGrossIncomeYearThree) {
      yearThreeGI = this.propertyAnalysis.effectiveGrossIncomeYearThree;
      cmpt = cmpt + 1;
    }

    if ((yearOneGI != 0) || (yearTwoGI != 0) || (yearThreeGI != 0)) {
      if (cmpt >= 2) {
        return (yearOneGI * 10) / 100 + (yearTwoGI * 20) / 100 + (yearThreeGI * 70) / 100
      }
      else {
        return yearOneGI + yearTwoGI + yearThreeGI;
      }
    } else {
      return "";
    }
  }
  onSave() {

    if (this.verifData(this.propertyAnalysis) && this.verifAdress2()) {
      this.propertyAnalysis.netOperatingIncome = this.getNOIValeus();
      this.propertyAnalysis.totalExpensesUserEntered = this.getTEValeus();
      this.propertyAnalysis.effectiveGrossIncome = this.getGEIValeus();
      this.propertyAnalysis.VaccancyStab = this.getVacuValeus();


      this.propertyAnalysis.address = this.mapAddress;
      this.propertyAnalysis.createdBy = this.authService.authUser.email;
      this.propertyAnalysis.createdByName = this.authService.authUser.displayName;
      this.propertyAnalysis.estimateValue = this.estimateValue;
      this.propertyAnalysis.estimateScore = this.estimateScore;
      const date = this.MomentDateFormatter.format(this.propertyAnalysis.valuationDate);
      this.propertyAnalysis.valuationDate = date;
      const d = new Date(this.propertyAnalysis.valuationDate);
      this.propertyAnalysis.valuationDate2 = d.getTime();
      const date2 = this.MomentDateFormatter.format(this.propertyAnalysis.dateMostRecentSale);
      this.propertyAnalysis.dateMostRecentSale = date2;
      const date3 = this.MomentDateFormatter.format(this.propertyAnalysis.dateRecentAppraisal);
      this.propertyAnalysis.dateRecentAppraisal = date3;
      // const date4 = this.MomentDateFormatter.format(this.propertyAnalysis.datelistPrice);
      // this.propertyAnalysis.datelistPrice = date4;
      // const date4 = this.MomentDateFormatter.format(this.propertyAnalysis.PriorDate);
      // this.propertyAnalysis.PriorDate = date4;
      this.analysisDataService.updateAnalysis(this.propertyAnalysis, this.id, this.idDomaine);
      this.youCanSendIt = false;
      // this.router.navigate(['/list-property-analysis/myList']);

    }
  }
  review() {
    if (this.propertyAnalysis.review === 2) {
      swal.fire({
        title: 'Providing operating data can significantly improve the accuracy of the valuation',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Got it !'
      }).then((result) => {
        if (result.value) {
          this.propertyAnalysis.address = this.mapAddress;
          this.propertyAnalysis.createdBy = '';
          this.propertyAnalysis.createdByName = '';
          if (!this.propertyAnalysis.createdOn) { this.propertyAnalysis.createdOn = Date.now(); }
          this.propertyAnalysis.netOperatingIncome = this.getNOIValeus();
          this.propertyAnalysis.totalExpensesUserEntered = this.getTEValeus();
          this.propertyAnalysis.effectiveGrossIncome = this.getGEIValeus();
          this.propertyAnalysis.VaccancyStab = this.getVacuValeus();

          this.propertyAnalysis.estimateValue = this.estimateValue;
          this.propertyAnalysis.estimateScore = this.estimateScore;
          const date = this.MomentDateFormatter.format(this.propertyAnalysis.valuationDate);
          this.propertyAnalysis.valuationDate = date;
          const d = new Date(this.propertyAnalysis.valuationDate);
          this.propertyAnalysis.valuationDate2 = d.getTime();
          const date2 = this.MomentDateFormatter.format(this.propertyAnalysis.dateMostRecentSale);
          this.propertyAnalysis.dateMostRecentSale = date2;
          const date3 = this.MomentDateFormatter.format(this.propertyAnalysis.dateRecentAppraisal);
          this.propertyAnalysis.dateRecentAppraisal = date3;
          // const date4 = this.MomentDateFormatter.format(this.propertyAnalysis.datelistPrice);
          // this.propertyAnalysis.datelistPrice = date4;
          // const date4 = this.MomentDateFormatter.format(this.propertyAnalysis.PriorDate);
          // this.propertyAnalysis.PriorDate = date4;
          this.analysisDataService.creatAnalysisall(this.propertyAnalysis, this.idDomaine, this.id).then(res => {
            this.router.navigate(['/auth/review', res, this.idDomaine], { queryParams: { valid: !this.analyisForm.invalid } });

          });
          this.analyisForm.reset();

        }
      })
    } else {
      this.propertyAnalysis.address = this.mapAddress;
      this.propertyAnalysis.createdBy = "";
      this.propertyAnalysis.createdByName = "";
      if (!this.propertyAnalysis.createdOn) { this.propertyAnalysis.createdOn = Date.now(); }
      this.propertyAnalysis.netOperatingIncome = this.getNOIValeus();
      this.propertyAnalysis.totalExpensesUserEntered = this.getTEValeus();
      this.propertyAnalysis.effectiveGrossIncome = this.getGEIValeus();
      this.propertyAnalysis.VaccancyStab = this.getVacuValeus();

      this.propertyAnalysis.estimateValue = this.estimateValue;
      this.propertyAnalysis.estimateScore = this.estimateScore;
      const date = this.MomentDateFormatter.format(this.propertyAnalysis.valuationDate);
      this.propertyAnalysis.valuationDate = date;
      const d = new Date(this.propertyAnalysis.valuationDate);
      this.propertyAnalysis.valuationDate2 = d.getTime();
      const date2 = this.MomentDateFormatter.format(this.propertyAnalysis.dateMostRecentSale);
      this.propertyAnalysis.dateMostRecentSale = date2;
      const date3 = this.MomentDateFormatter.format(this.propertyAnalysis.dateRecentAppraisal);
      this.propertyAnalysis.dateRecentAppraisal = date3;
      // const date4 = this.MomentDateFormatter.format(this.propertyAnalysis.datelistPrice);
      // this.propertyAnalysis.datelistPrice = date4;
      //   const date4 = this.MomentDateFormatter.format(this.propertyAnalysis.PriorDate);
      // this.propertyAnalysis.PriorDate = date4;
      this.analysisDataService.creatAnalysisall(this.propertyAnalysis, this.idDomaine, this.id).then(res => {
        this.router.navigate(['/auth/review', res, this.idDomaine], { queryParams: { valid: !this.analyisForm.invalid } });

      });
      this.analyisForm.reset();
    }
  }

  lowest: number;
  highest: number;
  AutoTotalOperatingExpensesYearOne: boolean = false;
  AutoTotalOperatingExpensesYearOneGroupe: boolean = true;
  clickedYearOne: boolean = true;
  AutoTotalOperatingExpensesYearOneSwitch: boolean = true;
  YearOneOnCmp: number = 0;
  onOrOffCmp2YearOne: any;
  yearOneAuto2: boolean = true;


  AutoTotalOperatingExpensesYearTwo: boolean = false;
  AutoTotalOperatingExpensesYearTwoGroupe: boolean = true;
  clickedYearTwo: boolean = true;
  AutoTotalOperatingExpensesYearTwoSwitch: boolean = true;
  YearTwoOnCmp: number = 0;
  onOrOffCmp2YearTwo: any;
  yearTwoAuto2: boolean = true;

  AutoTotalOperatingExpensesYearThree: boolean = false;
  AutoTotalOperatingExpensesYearThreeGroupe: boolean = true;
  clickedYearThree: boolean = true;
  AutoTotalOperatingExpensesYearThreeSwitch: boolean = true;
  YearThreeOnCmp: number = 0;
  onOrOffCmp2YearThree: any;
  yearThreeAuto2: boolean = true;
  canvasWidth = 150
  needleValue;
  centralLabel = ''
  name = 'Confidence score'
  bottomLabel = '' + this.estimateScore;
  getEstim(estimateScore) {
    if (this.estimateScore == 0) {
      this.needleValue = 0;
    } else if (this.estimateScore == 1) {
      this.lowest = this.estimateValue - (this.estimateValue * 60) / 100;
      this.highest = this.estimateValue + (this.estimateValue * 60) / 100;

      this.needleValue = 25;
    } else if (this.estimateScore == 2) {
      this.lowest = this.estimateValue - (this.estimateValue * 40) / 100;
      this.highest = this.estimateValue + (this.estimateValue * 40) / 100;

      this.needleValue = 50;
    } else if (this.estimateScore == 3) {
      this.lowest = this.estimateValue - (this.estimateValue * 20) / 100;
      this.highest = this.estimateValue + (this.estimateValue * 20) / 100;
      this.needleValue = 75;
    } else if (this.estimateScore == 4) {
      this.lowest = this.estimateValue - (this.estimateValue * 10) / 100;
      this.highest = this.estimateValue + (this.estimateValue * 10) / 100;
      this.needleValue = 100;
    }
    this.bottomLabel = estimateScore;
  }

  yearOneAutoCalculationTotalOperatingExpensAllExpensesOrTotalOperatingExpensesOnly() {
    this.AutoTotalOperatingExpensesYearOneGroupe = !this.AutoTotalOperatingExpensesYearOneGroupe;
    this.AutoTotalOperatingExpensesYearOne = this.AutoTotalOperatingExpensesYearOneGroupe;
    this.AutoTotalOperatingExpensesYearOneSwitch = this.AutoTotalOperatingExpensesYearOne;

  }

  YearOneChoosed() {
    this.YearOneOnCmp = this.YearOneOnCmp + 1;
    if (this.YearOneOnCmp % 2 == 0) {
      this.AutoTotalOperatingExpensesYearOne = !this.AutoTotalOperatingExpensesYearOneGroupe;
      this.AutoTotalOperatingExpensesYearOneGroupe = true;
      this.AutoTotalOperatingExpensesYearOneSwitch = true;
    } else {
      this.AutoTotalOperatingExpensesYearOneGroupe = false;
      this.AutoTotalOperatingExpensesYearOne = false;
      this.AutoTotalOperatingExpensesYearOneSwitch = false;
    }
  }

  YearOneAuto2() {
    this.onOrOffCmp2YearOne = this.onOrOffCmp2YearOne + 1;
    if (this.onOrOffCmp2YearOne % 2 == 0) {
      this.yearOneAuto2 = false;
    } else {
      this.yearOneAuto2 = true;
    }
    this.calculeYearOne();
  }

  yearTwoAutoCalculationTotalOperatingExpensAllExpensesOrTotalOperatingExpensesOnly() {
    this.AutoTotalOperatingExpensesYearTwoGroupe = !this.AutoTotalOperatingExpensesYearTwoGroupe;
    this.AutoTotalOperatingExpensesYearTwo = this.AutoTotalOperatingExpensesYearTwoGroupe;
    this.AutoTotalOperatingExpensesYearTwoSwitch = this.AutoTotalOperatingExpensesYearTwo;

  }

  YearTwoChoosed() {
    this.YearTwoOnCmp = this.YearTwoOnCmp + 1;
    if (this.YearTwoOnCmp % 2 == 0) {
      this.AutoTotalOperatingExpensesYearTwo = !this.AutoTotalOperatingExpensesYearTwoGroupe;
      this.AutoTotalOperatingExpensesYearTwoGroupe = true;
      this.AutoTotalOperatingExpensesYearTwoSwitch = true;
    } else {
      this.AutoTotalOperatingExpensesYearTwoGroupe = false;
      this.AutoTotalOperatingExpensesYearTwo = false;
      this.AutoTotalOperatingExpensesYearTwoSwitch = false;
    }
  }

  YearTwoAuto2() {
    this.onOrOffCmp2YearTwo = this.onOrOffCmp2YearTwo + 1;
    if (this.onOrOffCmp2YearTwo % 2 == 0) {
      this.yearTwoAuto2 = false;
    } else {
      this.yearTwoAuto2 = true;
    }
    this.calculeYearTwo();
  }

  yearThreeAutoCalculationTotalOperatingExpensAllExpensesOrTotalOperatingExpensesOnly() {
    this.AutoTotalOperatingExpensesYearThreeGroupe = !this.AutoTotalOperatingExpensesYearThreeGroupe;
    this.AutoTotalOperatingExpensesYearThree = this.AutoTotalOperatingExpensesYearThreeGroupe;
    this.AutoTotalOperatingExpensesYearThreeSwitch = this.AutoTotalOperatingExpensesYearThree;

  }

  YearThreeChoosed() {
    this.YearThreeOnCmp = this.YearThreeOnCmp + 1;
    if (this.YearThreeOnCmp % 2 == 0) {
      this.AutoTotalOperatingExpensesYearThree = !this.AutoTotalOperatingExpensesYearThreeGroupe;
      this.AutoTotalOperatingExpensesYearThreeGroupe = true;
      this.AutoTotalOperatingExpensesYearThreeSwitch = true;
    } else {
      this.AutoTotalOperatingExpensesYearThreeGroupe = false;
      this.AutoTotalOperatingExpensesYearThree = false;
      this.AutoTotalOperatingExpensesYearThreeSwitch = false;
    }
  }

  YearThreeAuto2() {
    this.onOrOffCmp2YearThree = this.onOrOffCmp2YearThree + 1;
    if (this.onOrOffCmp2YearThree % 2 == 0) {
      this.yearThreeAuto2 = false;
    } else {
      this.yearThreeAuto2 = true;
    }
    this.calculeYearThree();
  }

  verifAdress() {
    console.log(this.propertyAnalysis.latitude);
    console.log(this.propertyAnalysis.longitude);
    console.log(this.latitude);
    console.log(this.longitude);
    console.log(this.mapAddress);

    this.setCurrentPosition();
    this.changeStatus();
    console.log(this.propertyAnalysis.latitude);
    console.log(this.propertyAnalysis.longitude);
    console.log(this.latitude);
    console.log(this.longitude);
    console.log(this.mapAddress);
    if (this.propertyAnalysis.latitude == 0 && this.propertyAnalysis.longitude == 0) {
      this.addressOk = false;
    } else {
      this.addressOk = true;
    }
    return this.addressOk;
  }

  verifAdress2() {
    if (this.propertyAnalysis.latitude == 0 && this.propertyAnalysis.longitude == 0) {
      this.addressOk = false;
    } else {
      this.addressOk = true;
    }
    return this.addressOk;
  }
}
