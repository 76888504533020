import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap, map, tap, delay } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryRealtimeParamsModel } from '../../_base/crud';
import { PropertyAnalysissService } from '../_services';
import { AppState } from '../../reducers';
import {

    PropertyDetailActionToggleLoading,
    PropertyDetailActionTypes,
    PropertyDetailsPageLoaded,
    PropertyDetailsPageRequested,
    PropertyDetailsPageToggleLoading,
    OnePropertyDetailDeleted,
    PropertyDetailOnServerCreated,
    PropertyDetailCreated

} from '../_actions/PropertyDetail.actions';
import { of } from 'rxjs';

@Injectable()
export class PropertyDetailEffects {
    showPageLoadingDistpatcher = new PropertyDetailsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new PropertyDetailActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new PropertyDetailActionToggleLoading({ isLoading: false });
    @Effect()
    loadPropertyDetailsPage$ = this.actions$.pipe(
        ofType<PropertyDetailsPageRequested>(PropertyDetailActionTypes.PropertyDetailsPageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            return this.PropertyDetailsService.getAnalysis(payload.list,payload.createdBy);

        }),
        map(response => {
            console.log(response);
            const result: QueryRealtimeParamsModel = response;
            const pageLoadedDispatch = new PropertyDetailsPageLoaded({
                PropertyDetails: result
            });
            return pageLoadedDispatch;
        })
    );

    // @Effect()
    // createUser$ = this.actions$
    //     .pipe(
    //         ofType<PropertyDetailOnServerCreated>(PropertyDetailActionTypes.PropertyDetailOnServerCreated),
    //         mergeMap(( { payload } ) => {
    //             this.store.dispatch(this.showActionLoadingDistpatcher);
    //             return this.PropertyDetailsService.createUser(payload.PropertyDetail).pipe(
    //                 tap(res => {
    //                     this.store.dispatch(new PropertyDetailCreated({ PropertyDetail: res }));
    //                 })
    //             );
    //         }),
    //         map(() => {
    //             return this.hideActionLoadingDistpatcher;
    //         }),
    //     );
   

    constructor(private actions$: Actions, private PropertyDetailsService: PropertyAnalysissService, private store: Store<AppState>) { }

}
