import { BaseModel } from '../../_base/crud';
import { DatePipe } from '@angular/common';

export class CouponModel  extends BaseModel {
	ID: string;
	CouponCode: string = "";
	EndDate: Date;
	Reduction: boolean;
	StartDate: Date;
	franchise: string;

	clear() {
		this.ID= '';
		this.CouponCode= "";
		this.franchise= "";



	}
}

