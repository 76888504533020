import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { QueryRealtimeParamsModel, HttpExtenstionsModel } from '../../_base/crud';
import { MyListModel } from '../_models/MyListModel';
import { MyListState } from '../_reducers/MyList.reducers';

export const selectMyListsState = createFeatureSelector<MyListState>('MyList');

export const selectMyListById = (MyListId: number) => createSelector(
    selectMyListsState,
    MyListState => MyListState.entities[MyListId]
);

export const selectMyListsPageLoading = createSelector(
    selectMyListsState,
    MyListState => MyListState.listLoading
);

export const selectMyListsActionLoading = createSelector(
    selectMyListsState,
    MyListState => MyListState.actionsloading
);

export const selectLastCreatedMyListId = createSelector(
    selectMyListsState,
    MyListState => MyListState.lastCreatedMyListId
);

export const selectMyListsShowInitWaitingMessage = createSelector(
    selectMyListsState,
    MyListState => MyListState.showInitWaitingMessage
);

export const selectMyListsInStore = createSelector(
    selectMyListsState,
    MyListState => {
        const items: MyListModel[] = [];
        each(MyListState.entities, element => {
            items.push(element);
        });        
        const httpExtension = new HttpExtenstionsModel();
        const result: MyListModel[] = httpExtension.sortArray(items, MyListState.lastQuery.sortField, MyListState.lastQuery.sortOrder);
        return new QueryRealtimeParamsModel(result);
    }
);

