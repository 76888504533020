import { BaseModel } from '../../_base/crud';
import { DatePipe } from '@angular/common';

export class MyListModel  extends BaseModel {
	id: string;
	model: string;
	active: boolean;
	yearOfConstruction: Date;
	numDoors: number;
	clim: number; 
	status: number; 
	timeStamp:number;
	indicateur:string;
	draft:any;
	adress_ip:string;
	sessionid:string;
	browser:string;
	city:string;
	devicetype:string;
	valuationDate:string;
	domain:string;
	event_id:string;
	path:string;
	referrer:string;
	title:string;
	type:string;
	temoin:string;
	url:string;
	visiteur_id:string;
	urlscrollepage:string;
	x:number;
	seq:number;
	y:number;
	date_hours:string;
	tags:string;
	clear() {
		this.id= '';
		this.model= "";
		this.valuationDate="";
		this.numDoors= 0;
		this.clim= 0; 
		this.status= 0; 
		this.timeStamp=0;
		this.indicateur="";
		this.adress_ip="";
		this.browser="";
		this.city="";
		this.devicetype="";
		this.domain="";
		this.event_id="";
		this.sessionid="";
		this.path="";
		this.referrer="";
		this.title="";
		this.type="";
		this.url="";
		this.urlscrollepage="";
		this.x=0;
		this.y=0;
		this.date_hours="";

	}
}

