// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { TransactioRedModel } from '../_models/TransactioRedModel';

export enum TransactioRedActionTypes {
    TransactioRedOnServerCreated = '[Edit TransactioRed Dialog] TransactioRed On Server Created',
    TransactioRedCreated = '[Edit TransactioRed Dialog] TransactioRed Created',
    TransactioRedUpdated = '[Edit TransactioRed Dialog] TransactioRed Updated',
    TransactioRedsStatusUpdated = '[TransactioReds List Page] TransactioReds Status Updated',
    OneTransactioRedDeleted = '[TransactioReds List Page] One TransactioRed Deleted',

    TransactioRedsPageRequested = '[TransactioReds List Page] TransactioReds Page Requested',
    TransactioRedsPageLoaded = '[TransactioReds API] TransactioReds Page Loaded',


    TransactioRedsPageCancelled = '[TransactioReds API] TransactioReds Page Cancelled',
    TransactioRedsPageToggleLoading = '[TransactioReds] TransactioReds Page Toggle Loading',
    TransactioRedActionToggleLoading = '[TransactioReds] TransactioReds Action Toggle Loading'
}

export class TransactioRedOnServerCreated implements Action {
    readonly type = TransactioRedActionTypes.TransactioRedOnServerCreated;
    constructor(public payload: { TransactioRed: TransactioRedModel ,id:string}) { }
}

export class TransactioRedCreated implements Action {
    readonly type = TransactioRedActionTypes.TransactioRedCreated;
    constructor(public payload: { TransactioRed: any }) { }
}

export class TransactioRedUpdated implements Action {
    readonly type = TransactioRedActionTypes.TransactioRedUpdated;
    constructor(public payload: {
        partialTransactioRed: Update<TransactioRedModel>, 
        TransactioRed: TransactioRedModel 
    }) { }
}

export class TransactioRedsStatusUpdated implements Action {
    readonly type = TransactioRedActionTypes.TransactioRedsStatusUpdated;
    constructor(public  payload: {
        TransactioReds: TransactioRedModel[],
        status: number
    }) { }
}

export class  OneTransactioRedDeleted implements Action {
    readonly type = TransactioRedActionTypes.OneTransactioRedDeleted;
    constructor(public payload: { id: string ,idNameSpace:string}) {}
}


export class TransactioRedsPageRequested implements Action {
    readonly type = TransactioRedActionTypes.TransactioRedsPageRequested;
    constructor(public payload: { list: any }) { }
}

export class TransactioRedsPageLoaded implements Action {
    readonly type = TransactioRedActionTypes.TransactioRedsPageLoaded;
    constructor(public payload: any) { }
}

export class TransactioRedsPageCancelled implements Action {
    readonly type = TransactioRedActionTypes.TransactioRedsPageCancelled;
}

export class TransactioRedsPageToggleLoading implements Action {
    readonly type = TransactioRedActionTypes.TransactioRedsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class TransactioRedActionToggleLoading implements Action {
    readonly type = TransactioRedActionTypes.TransactioRedActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}




export type TransactioRedActions = TransactioRedOnServerCreated
| TransactioRedCreated
| TransactioRedUpdated
| TransactioRedsStatusUpdated
| OneTransactioRedDeleted
| TransactioRedsPageRequested
| TransactioRedsPageLoaded
| TransactioRedsPageCancelled
| TransactioRedsPageToggleLoading
| TransactioRedActionToggleLoading;
