import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Domaine } from '../../../../core/auth/_models/Domaine.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar, MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { PropertyAnalysisModel } from '../../../../core/property-analysis/_models/PropertyAnalysisModel';
import { PropertyAnalysissService } from '../../../../core/property-analysis/_services/PropertyAnalysis.service';
import * as $ from 'jquery';
import swal from 'sweetalert2';
import { Transaction } from '../../../../core/property-analysis/_models/TransactionModel';
import { DatePipe } from '@angular/common';
import { TransactionsService } from '../../../../core/property-analysis/_services/transaction.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { TransactioRedsService } from '../../../../core/transaction';
// import { EvaluationComponent } from '../../evaluation/evaluation.component';
// import { QuestionaireComponent } from './questionaire/questionaire.component';
import { EvaluationComponent } from '../../property-analysis/property-analysis-report/evaluation/evaluation.component';
import { QuestionaireComponent } from '../../property-analysis/property-analysis-report/questionaire/questionaire.component';
import { loadStripe } from '@stripe/stripe-js';
import * as firebase from 'firebase';

const stripe = loadStripe('pk_live_51HKRdDH2nIzq8u28gx0TAtJJdqU4PUgaShDlDLu0Cmh72hg2SIRXAj9xDBLxVrdsYreXch6IZnhV2lmq8QxmGFrm00LLhVBfyR');

@Component({
  selector: 'kt-reviewgeneral',
  templateUrl: './reviewgeneral.component.html',
  styleUrls: ['./reviewgeneral.component.scss']
})
export class ReviewgeneralComponent implements OnInit {
  price_id;
  product_id;
  today2: any;
  today: number = Date.now();
  idReport: string;
  product = {
    price: 0,
    desc: 'None',
  };
  idDomaine: string;
  emailuser: string;
  propertyAnalysis = new PropertyAnalysisModel;
  loginForm: FormGroup;
  domaine: Domaine;
  transaction = new Transaction;
  canReviewed: boolean = true;
  valid: any;
  // user: any;
  nomDomaine: string;
  boostedScore: any;
  idRealTime: any;
  stripe; // : stripe.Stripe;
  cardErrors;
  product_Typedomaine;
  card: any;
  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private datePipe: DatePipe,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private transactionDataService: TransactionsService,
    private transactioRedsService: TransactioRedsService,
    private _cdr: ChangeDetectorRef,
    private analysisDataService: PropertyAnalysissService,
  ) {
    this.stripe = Stripe('pk_live_51HKRdDH2nIzq8u28gx0TAtJJdqU4PUgaShDlDLu0Cmh72hg2SIRXAj9xDBLxVrdsYreXch6IZnhV2lmq8QxmGFrm00LLhVBfyR');
    const elements = this.stripe.elements();

    this.card = elements.create('card');

    this.card.addEventListener('change', ({ error }) => {
      this.cardErrors = error && error.message;
    });

    // this.today2 = datePipe.transform(this.today, 'yyyy-MM-dd');
    this.today2 = this.today;

    // this.authService.user$.subscribe(res => {
    // if (res) {
    //   this.authService.getBusinessName(res.idDomaine).subscribe((list: any) => {
    //     if (list) {
    //       this.nomDomaine = list.businessName;
    //     }
    //   });
    // }
    // this.idDomaine = res.idDomaine;
    // this.user = res;
    this.route.params.subscribe(
      (params: Params) => {
        this.idReport = params['id'];
        this.idDomaine = params['iddomaine'];
        this.changeStatus();
        this.getAnalysis();
        this.analysisDataService.updateDraft2(this.idReport, this.idDomaine);
        this.authService.getBusinessName(this.idDomaine).subscribe(
          (list: any) => {
            if (list) {
              this.domaine = list
              this.changeStatus();
              this.product_Typedomaine = list.product_Type;
            }
          }
        );
      });
    // })
    this.valid = (this.route.snapshot.queryParams['valid'] || null);
  }
  listData: MatTableDataSource<any>;
  displayedColumns: string[] = ['Gross Effective Income', 'Total Operating Expenses', 'Net Operating Income', 'actions'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  searchKey: string = "";
  ngOnInit() {
    $('#kt_sweetalert_demo_3_3').click(function (e) {

    });

  }
  // changeStatus2(): void {

  //   setTimeout(() => {

  //     this._cdr.detectChanges()
  //     this._cdr.markForCheck()

  //   }, 1000);
  // }
  getAnalysis() {
    this.analysisDataService.getAnalysis(this.idReport, this.idDomaine).subscribe(
      (res: PropertyAnalysisModel) => {
        if (res) {
          this.propertyAnalysis = res;
          this.changeStatus();
        }
        // this.changeStatus2();
      },
      (error) => this.router.navigate(['/error500'])
    )
    this.authService.getBusinessName(this.idDomaine).subscribe(
      (res: Domaine) => {
        if (res) {
          this.domaine = res;


        }
      }
    );
  }

  cmpStringDate(d1: string, d2: string) {
    var date1 = d1;
    var date2 = d2
    var j1;
    var m1;
    var y1;
    var j2;
    var m2;
    var y2;
    m1 = date1.substring(0, 2);
    m2 = date2.substring(0, 2);
    j1 = date1.substring(5, 3);
    j2 = date2.substring(5, 3);
    y1 = date1.substring(10, 6);
    y2 = date2.substring(10, 6);
    function difference(a, b) {
      return Math.abs(a - b);
    }
    if (difference(Number(y1), Number(y2)) > 2) {
      return true;
    } else if (difference(Number(y1), Number(y2)) == 2) {
      if (Number(m1) < Number(m2)) {
        return true;
      }
      else if (Number(m1) == Number(m2)) {
        if (Number(j1) <= Number(j2)) {
          return true;
        }
      }
    } else {
      return false;
    }
    return false;
  }
  val: number;
  credit: number;
  validate() {
    var okNRA = true;
    var okPC = true;
    var okFS = true;
    var okCQ = true;
    var okAdres = true;
    var okCity = true;
    var okState = true;
    var okYearCOnst = true;
    var okNumBuildings = true;
    var okNumStories = true;
    var operation = true;
    var operation2 = true;
    if (this.propertyAnalysis.review === 1 && (!this.propertyAnalysis.netOperatingIncomeYearOne)) {
      $('#kt_sweetalert_demo_3_3').click(function (e) {
        swal.fire("Error!", "Operating Details Required !", "error");
      });
      operation = false;
    } else {
      operation = true;
    }
    if (this.propertyAnalysis.propertyType == 'Multi-Family' && (!this.propertyAnalysis.numberUnits)) {
      $('#kt_sweetalert_demo_3_3').click(function (e) {
        swal.fire("Error!", "Number of Units Required !", "error");
      });
      operation2 = false;
    } else {
      operation2 = true;
    }

    if (!this.propertyAnalysis.netRentableArea) {
      $('#kt_sweetalert_demo_3_3').click(function (e) {
        swal.fire("Error!", "Net Rentable Area Required !", "error");
      });
      okNRA = false;
    } else {
      okNRA = true;
    }

    if (!this.propertyAnalysis.propertyCondition) {
      $('#kt_sweetalert_demo_3_3').click(function (e) {
        swal.fire("Error!", "Property Condition Required !", "error");
      });
      okPC = false;
    } else {
      okPC = true;
    }
    if (!isNaN(parseFloat(this.propertyAnalysis.yearConstruction)) && !isNaN(+this.propertyAnalysis.yearConstruction - 0) && (+this.propertyAnalysis.yearConstruction < 1900 || +this.propertyAnalysis.yearConstruction > 2022)) {
      $('#kt_sweetalert_demo_3_3').click(function (e) {
        swal.fire("Error!", "Year of Construction must be between 1900 and 2022", "error");
      });
      okFS = false;
    } else {
      okFS = true;
    }
    if (!isNaN(parseFloat(this.propertyAnalysis.numberBuildings)) && !isNaN(+this.propertyAnalysis.numberBuildings - 0) && (+this.propertyAnalysis.numberBuildings < 1 || +this.propertyAnalysis.numberBuildings > 100)) {
      $('#kt_sweetalert_demo_3_3').click(function (e) {
        swal.fire("Error!", "Num buildings must be between 1 and 100", "error");
      });
      okNumBuildings = false;
    } else {
      okNumBuildings = true;
    }
    if (!isNaN(parseFloat(this.propertyAnalysis.occupancyRate)) && !isNaN(+this.propertyAnalysis.occupancyRate - 0) && (+this.propertyAnalysis.occupancyRate < 1 || +this.propertyAnalysis.occupancyRate > 100)) {
      $('#kt_sweetalert_demo_3_3').click(function (e) {
        swal.fire("Error!", "Occupancy Rate must be between 1 and 100", "error");
      });
      okNumStories = false;
    } else {
      okNumStories = true;
    }
    if (!isNaN(parseFloat(this.propertyAnalysis.RenoYr)) && !isNaN(+this.propertyAnalysis.RenoYr - 0) && (+this.propertyAnalysis.RenoYr < 1950 || +this.propertyAnalysis.RenoYr > 2022)) {
      $('#kt_sweetalert_demo_3_3').click(function (e) {
        swal.fire("Error!", "Most Recent Renovation Year must be between 1950 and 2022", "error");
      });
      okCQ = false;
    } else {
      okCQ = true;
    }


    if (!this.propertyAnalysis.address) {
      $('#kt_sweetalert_demo_3_3').click(function (e) {
        swal.fire("Error!", "address Required !", "error");
      });
      okAdres = false;
    } else {
      okAdres = true;
    }
    if (!this.propertyAnalysis.city) {
      $('#kt_sweetalert_demo_3_3').click(function (e) {
        swal.fire("Error!", "City Required !", "error");
      });
      okCity = false;
    } else {
      okCity = true;
    }
    if (!this.propertyAnalysis.state) {
      $('#kt_sweetalert_demo_3_3').click(function (e) {
        swal.fire("Error!", "State Required !", "error");
      });
      okState = false;
    } else {
      okState = true;
    }
    if (!this.propertyAnalysis.yearConstruction) {
      $('#kt_sweetalert_demo_3_3').click(function (e) {
        swal.fire("Error!", "Year Construction Required !", "error");
      });
      okYearCOnst = false;
    } else {
      okYearCOnst = true;
    }
    // if (!this.propertyAnalysis.numberBuildings) {
    //   $('#kt_sweetalert_demo_3_3').click(function (e) {
    //     swal.fire("Error!", "Number Buildings Required !", "error");
    //   });
    //   okNumBuildings = false;
    // } else {
    //   okNumBuildings = true;
    // }
    // if (!this.propertyAnalysis.numberStories) {
    //   $('#kt_sweetalert_demo_3_3').click(function (e) {
    //     swal.fire("Error!", "Number Of Stories Required !", "error");
    //   });
    //   okNumStories = false;
    // } else {
    //   okNumStories = true;
    // }
    if (okNRA && okPC && okCQ && okAdres && okNumStories && okNumBuildings && okFS && okCity && operation2 && okState && okYearCOnst && operation) {
      this.canReviewed = false;
      this.analysisDataService.updateDrafvalid(this.idReport, this.idDomaine); this.changeStatus();
      swal.fire({
        title: 'Data check correct !',
        text: "Analysis will be performed based on the provided user input. You will only be billed if results are provided.",
        icon: 'success',
        confirmButtonColor: '#003366',
        confirmButtonText: 'RUN AVM'
      }).then((result) => {
        if (result.value) {
          this.submit()
        }
      })
    } else {
      this.analysisDataService.updateDraft1(this.idReport, this.idDomaine); this.changeStatus();

    }

  }
  changeStatus(): void {

    setTimeout(() => {

      this._cdr.detectChanges()
      this._cdr.markForCheck()

    }, 500);
  }
  submit() {
    this.propertyAnalysis.estimateValue = 0;
    this.propertyAnalysis.score = 0;
    // if (this.domaine.credit <= 0 || this.domaine.status != 'ACTIVE') {
    //   swal.fire({
    //     title: 'Insufficent credit',
    //     text: "You have insufficent credit to run a valuation .",
    //     icon: 'error',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Buy Credit'
    //   }).then((result) => {
    //     if (result.value) {
    //       this.router.navigate(['/payment/checkout'])
    //     }
    //   })
    // }
    // else {
    if (this.propertyAnalysis.ProductType != 'Market Trends Analysis' && this.propertyAnalysis.ProductType != 'Market Data Analysis') {
      swal.fire({
        title: 'Please Wait...',
        text: "This may take one or two minutes to process",
        timer: 90000,
        allowOutsideClick: false,
        timerProgressBar: true,
        onBeforeOpen: () => {
          swal.showLoading();
          this.analysisDataService.getvaluation(this.propertyAnalysis, this.idReport).subscribe(res => {
            if (res) {
              this.changeScore(res[0].score);
              this.propertyAnalysis.score = res[0].score;
              this.idRealTime = res[0].result;
              this.boostedScore = (res[0].score + ((res[0].score * res[0].addscale) / 100))
              this.getEstim((res[0].score + ((res[0].score * res[0].addscale) / 100)), this.idRealTime);
              if (this.idRealTime) {
                if (this.propertyAnalysis.ContractPrice && (this.propertyAnalysis.lowest < +this.propertyAnalysis.ContractPrice && this.propertyAnalysis.highest > +this.propertyAnalysis.ContractPrice)) {
                  if (this.estimateScore === 'A') {
                    this.propertyAnalysis.estimateValue = (this.idRealTime * 0.3) + (this.propertyAnalysis.ContractPrice * 0.66);
                    this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                  } else if (this.estimateScore === 'B') {
                    this.propertyAnalysis.estimateValue = (this.idRealTime * 0.25) + (this.propertyAnalysis.ContractPrice * 0.75);
                    this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                  } else if (this.estimateScore === 'C') {
                    this.propertyAnalysis.estimateValue = (this.idRealTime * 0.20) + (this.propertyAnalysis.ContractPrice * 0.80);
                    this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                  } else if (this.estimateScore === 'D') {
                    this.propertyAnalysis.estimateValue = (this.idRealTime * 0.10) + (this.propertyAnalysis.ContractPrice * 0.90);
                    this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                  }


                }
                else
                  if (((!this.propertyAnalysis.ContractPrice || this.propertyAnalysis.ContractPrice == "") && (this.propertyAnalysis.dateMostRecentSale && this.propertyAnalysis.mostRecentSalePrice) && (this.propertyAnalysis.lowest < +this.propertyAnalysis.mostRecentSalePrice && this.propertyAnalysis.highest > +this.propertyAnalysis.mostRecentSalePrice)) && (this.cmpStringDate(this.propertyAnalysis.dateMostRecentSale, this.propertyAnalysis.valuationDate))) {
                    if (this.estimateScore === 'A') {
                      this.propertyAnalysis.estimateValue = (this.idRealTime * 0.3) + (this.propertyAnalysis.mostRecentSalePrice * 0.66);
                      this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                    } else if (this.estimateScore === 'B') {
                      this.propertyAnalysis.estimateValue = (this.idRealTime * 0.25) + (this.propertyAnalysis.mostRecentSalePrice * 0.75);
                      this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                    } else if (this.estimateScore === 'C') {
                      this.propertyAnalysis.estimateValue = (this.idRealTime * 0.20) + (this.propertyAnalysis.mostRecentSalePrice * 0.80);
                      this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                    } else if (this.estimateScore === 'D') {
                      this.propertyAnalysis.estimateValue = (this.idRealTime * 0.10) + (this.propertyAnalysis.mostRecentSalePrice * 0.90);
                      this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                    }
                  }
                  else
                    if (((!this.propertyAnalysis.ContractPrice || this.propertyAnalysis.ContractPrice == "") && (this.propertyAnalysis.dateRecentAppraisal && this.propertyAnalysis.recentAppraisal) && (this.propertyAnalysis.lowest < +this.propertyAnalysis.recentAppraisal && this.propertyAnalysis.highest > +this.propertyAnalysis.recentAppraisal)) && (this.cmpStringDate(this.propertyAnalysis.dateRecentAppraisal, this.propertyAnalysis.valuationDate))) {
                      if (this.estimateScore === 'A') {
                        this.propertyAnalysis.estimateValue = (this.idRealTime * 0.50) + (this.propertyAnalysis.recentAppraisal * 0.50);
                        this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                      } else if (this.estimateScore === 'B') {
                        this.propertyAnalysis.estimateValue = (this.idRealTime * 0.40) + (this.propertyAnalysis.recentAppraisal * 0.60);
                        this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                      } else if (this.estimateScore === 'C') {
                        this.propertyAnalysis.estimateValue = (this.idRealTime * 0.30) + (this.propertyAnalysis.recentAppraisal * 0.70);
                        this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                      } else if (this.estimateScore === 'D') {
                        this.propertyAnalysis.estimateValue = (this.idRealTime * 0.20) + (this.propertyAnalysis.recentAppraisal * 0.80);
                        this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                      }
                    }
                    else
                      if (((!this.propertyAnalysis.ContractPrice || this.propertyAnalysis.ContractPrice == "") && (this.propertyAnalysis.datelistPrice && this.propertyAnalysis.listPrice) && (this.propertyAnalysis.lowest < +this.propertyAnalysis.listPrice && this.propertyAnalysis.highest > +this.propertyAnalysis.listPrice))) {
                        if (this.estimateScore === 'A') {
                          this.propertyAnalysis.estimateValue = (this.idRealTime * 0.50) + (this.propertyAnalysis.listPrice * 0.50);
                          this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                        } else if (this.estimateScore === 'B') {
                          this.propertyAnalysis.estimateValue = (this.idRealTime * 0.40) + (this.propertyAnalysis.listPrice * 0.60);
                          this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                        } else if (this.estimateScore === 'C') {
                          this.propertyAnalysis.estimateValue = (this.idRealTime * 0.30) + (this.propertyAnalysis.listPrice * 0.70);
                          this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                        } else if (this.estimateScore === 'D') {
                          this.propertyAnalysis.estimateValue = (this.idRealTime * 0.20) + (this.propertyAnalysis.listPrice * 0.80);
                          this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                        }
                      }
                      else {
                        this.propertyAnalysis.estimateValue = this.idRealTime;
                        this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();

                      }
              }
              this.analysisDataService.Stats_analysis(this.propertyAnalysis, this.propertyAnalysis.estimateValue).subscribe((list0: any) => {
                if (list0) {
                  this.propertyAnalysis.Income_Trends = list0.Income_trend;
                  this.propertyAnalysis.neareast = list0.nearest;
                  let subtypeNb = 0;
                  if (list0.nearest.Address[0] != "") {
                    subtypeNb++;
                  }
                  if (list0.nearest.Address[1] != "") {
                    subtypeNb++;
                  }
                  if (list0.nearest.Address[2] != "") {
                    subtypeNb++;
                  }
                  this.propertyAnalysis.comparablesnb = subtypeNb;
                  this.propertyAnalysis.Market_Value_Trends = list0.Market_value_trends;
                  this.propertyAnalysis.Capitalization_Rate_Analysis = list0.Capitalization_rate;
                  this.analysisDataService.updateAnal3(this.propertyAnalysis, this.idReport, this.idDomaine);
                  this.changeStatus();
                }
                this.propertyAnalysis.draft = 2;

                this.analysisDataService.updateAnalysisjust(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
              });

            }
          });
        }
      }).then((resul) => {
        this.getAnalysis();
        console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");

        console.log(this.propertyAnalysis.score);
        console.log(this.estimateScore);

        if ( this.propertyAnalysis.score == 0 ) {
          swal.fire({
            title: 'Please Wait...',
            text: "This may take one or two minutes to process",
            timer: 25000,
            allowOutsideClick: false,
            timerProgressBar: true,
            onBeforeOpen: () => {
              swal.showLoading();
              this.analysisDataService.getvaluation(this.propertyAnalysis, this.idReport).subscribe(res => {
                if (res) {
                  this.changeScore(res[0].score);
                  this.propertyAnalysis.score = res[0].score;
                  this.idRealTime = res[0].result;
                  this.boostedScore = (res[0].score + ((res[0].score * res[0].addscale) / 100))
                  this.getEstim((res[0].score + ((res[0].score * res[0].addscale) / 100)), this.idRealTime);
                  if (this.idRealTime) {
                    if (this.propertyAnalysis.ContractPrice && (this.propertyAnalysis.lowest < +this.propertyAnalysis.ContractPrice && this.propertyAnalysis.highest > +this.propertyAnalysis.ContractPrice)) {
                      if (this.estimateScore === 'A') {
                        this.propertyAnalysis.estimateValue = (this.idRealTime * 0.3) + (this.propertyAnalysis.ContractPrice * 0.66);
                        this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                      } else if (this.estimateScore === 'B') {
                        this.propertyAnalysis.estimateValue = (this.idRealTime * 0.25) + (this.propertyAnalysis.ContractPrice * 0.75);
                        this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                      } else if (this.estimateScore === 'C') {
                        this.propertyAnalysis.estimateValue = (this.idRealTime * 0.20) + (this.propertyAnalysis.ContractPrice * 0.80);
                        this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                      } else if (this.estimateScore === 'D') {
                        this.propertyAnalysis.estimateValue = (this.idRealTime * 0.10) + (this.propertyAnalysis.ContractPrice * 0.90);
                        this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                      }


                    }
                    else
                      if (((!this.propertyAnalysis.ContractPrice || this.propertyAnalysis.ContractPrice == "") && (this.propertyAnalysis.dateMostRecentSale && this.propertyAnalysis.mostRecentSalePrice) && (this.propertyAnalysis.lowest < +this.propertyAnalysis.mostRecentSalePrice && this.propertyAnalysis.highest > +this.propertyAnalysis.mostRecentSalePrice)) && (this.cmpStringDate(this.propertyAnalysis.dateMostRecentSale, this.propertyAnalysis.valuationDate))) {
                        if (this.estimateScore === 'A') {
                          this.propertyAnalysis.estimateValue = (this.idRealTime * 0.3) + (this.propertyAnalysis.mostRecentSalePrice * 0.66);
                          this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                        } else if (this.estimateScore === 'B') {
                          this.propertyAnalysis.estimateValue = (this.idRealTime * 0.25) + (this.propertyAnalysis.mostRecentSalePrice * 0.75);
                          this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                        } else if (this.estimateScore === 'C') {
                          this.propertyAnalysis.estimateValue = (this.idRealTime * 0.20) + (this.propertyAnalysis.mostRecentSalePrice * 0.80);
                          this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                        } else if (this.estimateScore === 'D') {
                          this.propertyAnalysis.estimateValue = (this.idRealTime * 0.10) + (this.propertyAnalysis.mostRecentSalePrice * 0.90);
                          this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                        }
                      }
                      else
                        if (((!this.propertyAnalysis.ContractPrice || this.propertyAnalysis.ContractPrice == "") && (this.propertyAnalysis.dateRecentAppraisal && this.propertyAnalysis.recentAppraisal) && (this.propertyAnalysis.lowest < +this.propertyAnalysis.recentAppraisal && this.propertyAnalysis.highest > +this.propertyAnalysis.recentAppraisal)) && (this.cmpStringDate(this.propertyAnalysis.dateRecentAppraisal, this.propertyAnalysis.valuationDate))) {
                          if (this.estimateScore === 'A') {
                            this.propertyAnalysis.estimateValue = (this.idRealTime * 0.50) + (this.propertyAnalysis.recentAppraisal * 0.50);
                            this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                          } else if (this.estimateScore === 'B') {
                            this.propertyAnalysis.estimateValue = (this.idRealTime * 0.40) + (this.propertyAnalysis.recentAppraisal * 0.60);
                            this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                          } else if (this.estimateScore === 'C') {
                            this.propertyAnalysis.estimateValue = (this.idRealTime * 0.30) + (this.propertyAnalysis.recentAppraisal * 0.70);
                            this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                          } else if (this.estimateScore === 'D') {
                            this.propertyAnalysis.estimateValue = (this.idRealTime * 0.20) + (this.propertyAnalysis.recentAppraisal * 0.80);
                            this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                          }
                        }
                        else
                          if (((!this.propertyAnalysis.ContractPrice || this.propertyAnalysis.ContractPrice == "") && (this.propertyAnalysis.datelistPrice && this.propertyAnalysis.listPrice) && (this.propertyAnalysis.lowest < +this.propertyAnalysis.listPrice && this.propertyAnalysis.highest > +this.propertyAnalysis.listPrice))) {
                            if (this.estimateScore === 'A') {
                              this.propertyAnalysis.estimateValue = (this.idRealTime * 0.50) + (this.propertyAnalysis.listPrice * 0.50);
                              this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                            } else if (this.estimateScore === 'B') {
                              this.propertyAnalysis.estimateValue = (this.idRealTime * 0.40) + (this.propertyAnalysis.listPrice * 0.60);
                              this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                            } else if (this.estimateScore === 'C') {
                              this.propertyAnalysis.estimateValue = (this.idRealTime * 0.30) + (this.propertyAnalysis.listPrice * 0.70);
                              this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                            } else if (this.estimateScore === 'D') {
                              this.propertyAnalysis.estimateValue = (this.idRealTime * 0.20) + (this.propertyAnalysis.listPrice * 0.80);
                              this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                            }
                          }
                          else {
                            this.propertyAnalysis.estimateValue = this.idRealTime;
                            this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();

                          }
                  }
                  this.analysisDataService.Stats_analysis(this.propertyAnalysis, this.propertyAnalysis.estimateValue).subscribe((list0: any) => {
                    if (list0) {
                      this.propertyAnalysis.Income_Trends = list0.Income_trend;
                      this.propertyAnalysis.neareast = list0.nearest;
                      let subtypeNb = 0;
                      if (list0.nearest.Address[0] != "") {
                        subtypeNb++;
                      }
                      if (list0.nearest.Address[1] != "") {
                        subtypeNb++;
                      }
                      if (list0.nearest.Address[2] != "") {
                        subtypeNb++;
                      }
                      this.propertyAnalysis.comparablesnb = subtypeNb;
                      this.propertyAnalysis.Market_Value_Trends = list0.Market_value_trends;
                      this.propertyAnalysis.Capitalization_Rate_Analysis = list0.Capitalization_rate;
                      this.analysisDataService.updateAnal3(this.propertyAnalysis, this.idReport, this.idDomaine);
                      this.changeStatus();
                    }
                    this.propertyAnalysis.draft = 2;

                    this.analysisDataService.updateAnalysisjust(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                  });
                }
              });
            }
          }).then((resul) => {
            this.getAnalysis();
            console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
            this.analysisDataService.updateAnalysisjust(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
            console.log(this.propertyAnalysis.score);
            console.log(this.estimateScore);
            if (this.propertyAnalysis.score == 0) {
              swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'A problem occured while loading the report, please try again',
              });
            } else {

              if ((100 - this.propertyAnalysis.score) < 65) {
                swal.fire({
                  icon: 'error',
                  title: 'Caution',
                  text: 'The AVM value does not meet minimum quality standards, no value is available.',
                });
                this.analysisDataService.updateDraft3(this.idReport, this.idDomaine);
              } else {
                if (this.propertyAnalysis.estimateValue < 500000) {
                  this.estimateValue = (Math.round(this.propertyAnalysis.estimateValue / 1000) * 1000);
                  this.changeStatus();
                }
                else if (this.propertyAnalysis.estimateValue > 500000 && this.propertyAnalysis.estimateValue < 2000000) {
                  this.estimateValue = (Math.round(this.propertyAnalysis.estimateValue / 10000) * 10000);
                  this.changeStatus();
                }
                else if (this.propertyAnalysis.estimateValue > 2000001 && this.propertyAnalysis.estimateValue < 10000000) {
                  this.estimateValue = (Math.round(this.propertyAnalysis.estimateValue / 100000) * 100000);
                  this.changeStatus();
                }
                else if (this.propertyAnalysis.estimateValue > 10000001) {
                  this.estimateValue = (Math.round(this.propertyAnalysis.estimateValue / 500000) * 500000);
                  this.changeStatus();
                }

                swal.fire(
                  {
                    title: 'Important message',
                    html: 'According to the data you entered and our model, we are able to provide you with a valuation of your property with the following <br> <br>  Valonow valuation analyses are based on artificial intelligence analysis of several hundred thousand transactions.<br>  <br>' +

                      'The comparables sales are selected based on the comparables that best support the valuation per square foot or per unit.  <br> <br> <b > Comparables sales number found :' + this.propertyAnalysis.comparablesnb + '  </b>    <br>  <br> <b> Confidence Score :  ' + this.estimateScore + ' (' + (100 - this.propertyAnalysis.score).toFixed(1) + '%)'
                      + ' </b> <br> <img src="./assets/Capture.PNG" style="width:350px; height:50px;"> <br> ',
                    // +'/4 </b>',
                    inputPlaceholder: 'I agree to the Valnow' + '<a href="https://val-now.com/terms-of-service.html" target="_blank"> Terms of Service</a> ' + '<a href="https://val-now.com/privacy-policy.html" target="_blank">and Privacy Policies</a> ',
                    input: 'checkbox',
                    confirmButtonText: 'PROCEED',
                    showCancelButton: true,
                    cancelButtonText: 'THIS REPORT DOES NOT HELP AT THIS TIME',
                    inputValidator: (result) => {
                      return !result && 'You need to agree with Valnow Terms of Service and Privacy Policies'
                    }
                  }
                ).then(res => {
                  if (res.value) {
                    this.analysisDataService.getDomaine(this.idDomaine).subscribe((res: any) => {
                      this.cre = +res.credit;
                    })


                    if (this.domaine.status === 'SUSPENDED') {
                      swal.fire({
                        title: 'Your account is suspended',
                        text: "In order to reactivate it, please contact us at blee@val-now.com .",
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ok'
                      })
                    } else {
                      if (this.domaine.status === 'MANAGED ACCOUNT') {
                        if (this.domaine.credit <= 0) {
                          swal.fire({
                            title: 'Caution',
                            text: "By proceeding, you agree to be charged according to our agreement for this report generation.",
                            icon: 'error',
                            showCancelButton: false,
                            // showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Ok'
                            // confirmButtonText: 'Buy Credit'
                          })
                            .then((result) => {
                              console.log("eeeeeeeeeeeeeeeeeeedddddddddddddddddd");

                              console.log(result);
                              this.transaction.date = this.today2;
                              this.transaction.package = this.product.desc;
                              this.transaction.credit = -1;
                              this.transaction.Id = this.firestore.createId();
                              this.transaction.iduser = this.emailuser;
                              // this.transaction.email = this.user.email;
                              this.transaction.type = "Valuation";
                              this.transaction.ide = this.idDomaine;
                              this.transaction.zipCode = this.propertyAnalysis.zipCode;
                              this.transaction.nomDomaine = this.nomDomaine;
                              this.transaction.amount = 0;
                              this.transaction.coupon = "";
                              this.transaction.state = this.propertyAnalysis.state;
                              this.transaction.city = this.propertyAnalysis.city;
                              this.transaction.adress = this.propertyAnalysis.address;

                              this.transactionDataService.creattransactionficras(this.transaction, this.idDomaine, this.today2);

                              this.analysisDataService.updateCreditficras(this.cre - 1, 'Billable', this.idDomaine);
                              this.analysisDataService.updateInvoicingStatusficras(this.propertyAnalysis.ProductType, this.propertyAnalysis.userid, this.idDomaine, 'Billable', this.idReport).then(y => {
                                this.router.navigate(['/auth/report/', this.idReport, this.idDomaine])
                              })


                            })
                        } else {
                          swal.fire({
                            title: 'You are about to generate a report',
                            text: "This action will constitute a charge against your account and billed at the end of the month",
                            icon: 'warning',
                            input: 'radio',
                            inputOptions:
                            {
                              'Market Trends Analysis': 'Market Trends Analysis($ 60)',
                              'Market Data Analysis': 'Market Data Analysis  &nbsp; &nbsp;  ($ 130)',
                              'Valnow Valuation': 'Valnow Valuation  &nbsp; &nbsp;  ($ 310)'
                            },
                            inputValidator: (value) => {
                              if (!value) {
                                return 'You need to choose something!'
                              }
                            },
                            inputValue: this.propertyAnalysis.ProductType,
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'PROCEED'
                          }).then((result) => {
                            if (result.value) {
                              console.log("eeeeeeeeeeeeeeeeeeedddddddddddddddddd");

                              console.log(result);
                              this.transaction.date = this.today2;
                              this.transaction.package = this.product.desc;
                              this.transaction.credit = -1;
                              this.transaction.Id = this.firestore.createId();
                              this.transaction.iduser = this.emailuser;
                              // this.transaction.email = this.user.email;
                              this.transaction.type = "Valuation";
                              this.transaction.ide = this.idDomaine;
                              this.transaction.zipCode = this.propertyAnalysis.zipCode;
                              this.transaction.nomDomaine = this.nomDomaine;
                              this.transaction.amount = 0;
                              this.transaction.coupon = "";
                              this.transaction.state = this.propertyAnalysis.state;
                              this.transaction.city = this.propertyAnalysis.city;
                              this.transaction.adress = this.propertyAnalysis.address;

                              this.transactionDataService.creattransactionficras(this.transaction, this.idDomaine, this.today2);

                              this.analysisDataService.updateCreditficras(this.cre - 1, 'Not to bill', this.idDomaine);
                              this.analysisDataService.updateInvoicingStatusficras(result.value, this.propertyAnalysis.userid, this.idDomaine, 'Not to bill', this.idReport).then(y => {
                                this.router.navigate(['/auth/report/', this.idReport, this.idDomaine])
                              })

                            }
                          })
                        }
                      } else {
                        if (this.domaine.status === 'Unlimited Offer') {
                          swal.fire({
                            title: 'You are about to generate a report',
                            text: "This action will constitute a charge against your account and billed at the end of the month",
                            icon: 'warning',
                            input: 'radio',
                            inputOptions:
                            {
                              'Market Trends Analysis': 'Market Trends Analysis($ 60)',
                              'Market Data Analysis': 'Market Data Analysis  &nbsp; &nbsp;  ($ 130)',
                              'Valnow Valuation': 'Valnow Valuation  &nbsp; &nbsp;  ($ 310)'
                            },
                            inputValidator: (value) => {
                              if (!value) {
                                return 'You need to choose something!'
                              }
                            },
                            inputValue: this.propertyAnalysis.ProductType,
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'PROCEED'
                          }).then((result) => {
                            if (result.value) {
                              console.log("eeeeeeeeeeeeeeeeeeedddddddddddddddddd");

                              console.log(result);
                              this.transaction.date = this.today2;
                              this.transaction.package = this.product.desc;
                              this.transaction.credit = -1;
                              this.transaction.Id = this.firestore.createId();
                              this.transaction.iduser = this.emailuser;
                              // this.transaction.email = this.user.email;
                              this.transaction.type = "Valuation";
                              this.transaction.ide = this.idDomaine;
                              this.transaction.zipCode = this.propertyAnalysis.zipCode;
                              this.transaction.nomDomaine = this.nomDomaine;
                              this.transaction.amount = 0;
                              this.transaction.coupon = "";
                              this.transaction.state = this.propertyAnalysis.state;
                              this.transaction.city = this.propertyAnalysis.city;
                              this.transaction.adress = this.propertyAnalysis.address;

                              this.transactionDataService.creattransactionficras(this.transaction, this.idDomaine, this.today2);

                              this.analysisDataService.updateCreditficras(this.cre - 1, 'Not to bill', this.idDomaine);
                              this.analysisDataService.updateInvoicingStatusficras(result.value, this.propertyAnalysis.userid, this.idDomaine, 'Not to bill', this.idReport).then(y => {
                                this.router.navigate(['/auth/report/', this.idReport, this.idDomaine])
                              })

                            }
                          })
                        }else{
                          if (this.domaine.credit <= 0) {
                            swal.fire({
                              title: 'Unfortunately, you have no more free credit',
                              html: "If you proceed, you will be directed to Stripe checkout service to be charged, then redirected back to your report. You can check our pricing model here " + '<a href="https://val-now.com/pricing.html"> val-now.com </a> ' + ". <br> If you want to be billed separately at month-end or per report, or if you want to ask for a specific agreement, please contact us at blee@val-now.com.",
                              icon: 'error',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Buy'
                            }).then((result) => {
                              if (result.value) {
                                firebase.auth().currentUser.getIdTokenResult().then(res => {
                                  console.log(this.propertyAnalysis.ProductType);
                                  if (this.propertyAnalysis.ProductType == 'Valnow Valuation') {
                                    this.product_id = "prod_M3wLNoNs7RXkZ5"
                                    this.price_id = "price_1LLoSqH2nIzq8u28iFWhpR9a"
                                  } else {
                                    if (this.propertyAnalysis.ProductType == 'Market Data Analysis') {
                                      this.product_id = "prod_M3wLwLEYhYJHBZ"
                                      this.price_id = "price_1LLoSxH2nIzq8u28nz3O1Vce"
                                    }
                                    else {
                                      if (this.propertyAnalysis.ProductType == 'Market Trends Analysis') {
                                        this.product_id = "prod_M3wL6eu9zyO2HZ"
                                        this.price_id = "price_1LLoT4H2nIzq8u28ysWruhE0"
                                      }
                                    }
                                  }
                                  if (this.product_id) {
                                    this.analysisDataService.checkoutstripe(this.product_id, this.price_id, this.idReport, res.token)
                                      .subscribe((res: any) => {
                                        console.log(res);
                                        console.log(res);
                                        this.analysisDataService.updateInvoicingStatusficras(this.propertyAnalysis.ProductType, this.propertyAnalysis.userid, this.idDomaine, 'Invoiced', this.idReport);
  
                                        this.analysisDataService.updateCreditficras(this.cre - 1, 'Invoiced', this.idDomaine).then(y => {
                                          this.router.navigate(['/auth/report/', this.idReport, this.idDomaine])
                                        })
                                        this.sessionId = res.id;
  
                                        console.log(res.toString());
                                        console.log(this.sessionId);
  
                                        this.idSes = this.sessionId;
                                        this.openPay();
                                      })
                                  }
  
                                });
                              }
                            })
                          } else {
                            swal.fire({
                              title: 'You are about to generate a report',
                              text: "This action will constitute a charge against your account and billed at the end of the month",
                              icon: 'warning',
                              input: 'radio',
                              inputOptions:
                              {
                                'Market Trends Analysis': 'Market Trends Analysis  ($ 60)',
                                'Market Data Analysis': 'Market Data Analysis  &nbsp; &nbsp;  ($ 130)',
                                'Valnow Valuation': 'Valnow Valuation  &nbsp; &nbsp;  ($ 310)'
                              },
                              inputValidator: (value) => {
                                if (!value) {
                                  return 'You need to choose something!'
                                }
                              },
                              inputValue: this.propertyAnalysis.ProductType,
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'PROCEED'
                            }).then((result) => {
                              if (result.value) {
                                console.log("eeeeeeeeeeeeeeeeeeedddddddddddddddddd");
  
                                console.log(result);
                                this.transaction.date = this.today2;
                                this.transaction.package = this.product.desc;
                                this.transaction.credit = -1;
                                this.transaction.Id = this.firestore.createId();
                                this.transaction.iduser = this.emailuser;
                                // this.transaction.email = this.user.email;
                                this.transaction.type = "Valuation";
                                this.transaction.ide = this.idDomaine;
                                this.transaction.zipCode = this.propertyAnalysis.zipCode;
                                this.transaction.nomDomaine = this.nomDomaine;
                                this.transaction.amount = 0;
                                this.transaction.coupon = "";
                                this.transaction.state = this.propertyAnalysis.state;
                                this.transaction.city = this.propertyAnalysis.city;
                                this.transaction.adress = this.propertyAnalysis.address;
                                this.analysisDataService.updateInvoicingStatusficras(result.value, this.propertyAnalysis.userid, this.idDomaine, 'Not to bill', this.idReport);
                                this.transactionDataService.creattransactionficras(this.transaction, this.idDomaine, this.today2);
                                this.analysisDataService.updateCreditficras(this.cre - 1, 'Not to bill', this.idDomaine).then(y => {
                                  this.router.navigate(['/auth/report/', this.idReport, this.idDomaine])
                                })
  
                              }
                            })
                          }
                        }
                        
                      }
                    }
                  }
                  else if (res.dismiss === swal.DismissReason.cancel) {
                    this.propertyAnalysis.score = 0;
                    this.propertyAnalysis.estimateValue = 0;
                    this.propertyAnalysis.draft = 0;

                    this.propertyAnalysis.estimateScore = 0;
                    this.analysisDataService.updateAnalysisjust0(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                    // this.analysisDataService.updateDraft3(this.idReport, this.idDomaine);
                  }
                })
              }
            }
          })
        } else {
          if ((100 - this.propertyAnalysis.score) < 65) {
            swal.fire({
              icon: 'error',
              title: 'Caution',
              text: 'The AVM value does not meet minimum quality standards, no value is available.',
            });
            this.analysisDataService.updateDraft3(this.idReport, this.idDomaine);
          } else {
            if (this.propertyAnalysis.estimateValue < 500000) {
              this.estimateValue = (Math.round(this.propertyAnalysis.estimateValue / 1000) * 1000);
              this.changeStatus();
            }
            else if (this.propertyAnalysis.estimateValue > 500000 && this.propertyAnalysis.estimateValue < 2000000) {
              this.estimateValue = (Math.round(this.propertyAnalysis.estimateValue / 10000) * 10000);
              this.changeStatus();
            }
            else if (this.propertyAnalysis.estimateValue > 2000001 && this.propertyAnalysis.estimateValue < 10000000) {
              this.estimateValue = (Math.round(this.propertyAnalysis.estimateValue / 100000) * 100000);
              this.changeStatus();
            }
            else if (this.propertyAnalysis.estimateValue > 10000001) {
              this.estimateValue = (Math.round(this.propertyAnalysis.estimateValue / 500000) * 500000);
              this.changeStatus();
            }
            // new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(amount);
            // → '-$3,500.00'
            swal.fire(
              {
                title: 'Important message',
                html: 'According to the data you entered and our model, we are able to provide you with a valuation of your property with the following <br> <br>  Valonow valuation analyses are based on artificial intelligence analysis of several hundred thousand transactions.<br>  <br>' +

                  'The comparables sales are selected based on the comparables that best support the valuation per square foot or per unit.  <br> <br> <b > Comparables sales number found :' + this.propertyAnalysis.comparablesnb + '  </b>    <br>  <br> <b> Confidence Score :  ' + this.estimateScore + ' (' + (100 - this.propertyAnalysis.score).toFixed(1) + '%)'
                  + ' </b> <br> <img src="./assets/Capture.PNG" style="width:350px; height:50px;"> <br> ',
                // +'/4 </b>',
                inputPlaceholder: 'I agree to the Valnow' + '<a href="https://val-now.com/terms-of-service.html" target="_blank"> Terms of Service</a> ' + '<a href="https://val-now.com/privacy-policy.html" target="_blank">and Privacy Policies</a> ',
                input: 'checkbox',
                confirmButtonText: 'PROCEED',
                showCancelButton: true,
                cancelButtonText: 'THIS REPORT DOES NOT HELP AT THIS TIME',
                inputValidator: (result) => {
                  return !result && 'You need to agree with Valnow Terms of Service and Privacy Policies'
                }
              }
            ).then(res => {
              if (res.value) {
                this.analysisDataService.getDomaine(this.idDomaine).subscribe((res: any) => {
                  this.cre = +res.credit;
                })

                if (this.domaine.status === 'SUSPENDED') {
                  swal.fire({
                    title: 'Your account is suspended',
                    text: "In order to reactivate it, please contact us at blee@val-now.com .",
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ok'
                  })
                } else {
                  if (this.domaine.status === 'MANAGED ACCOUNT') {
                    if (this.domaine.credit <= 0) {
                      swal.fire({
                        title: 'Caution',
                        text: "By proceeding, you agree to be charged according to our agreement for this report generation.",
                        icon: 'error',
                        showCancelButton: false,
                        // showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ok'
                        // confirmButtonText: 'Buy Credit'
                      })
                        .then((result) => {
                          console.log("eeeeeeeeeeeeeeeeeeedddddddddddddddddd");

                          console.log(result);
                          this.transaction.date = this.today2;
                          this.transaction.package = this.product.desc;
                          this.transaction.credit = -1;
                          this.transaction.Id = this.firestore.createId();
                          this.transaction.iduser = this.emailuser;
                          // this.transaction.email = this.user.email;
                          this.transaction.type = "Valuation";
                          this.transaction.ide = this.idDomaine;
                          this.transaction.zipCode = this.propertyAnalysis.zipCode;
                          this.transaction.nomDomaine = this.nomDomaine;
                          this.transaction.amount = 0;
                          this.transaction.coupon = "";
                          this.transaction.state = this.propertyAnalysis.state;
                          this.transaction.city = this.propertyAnalysis.city;
                          this.transaction.adress = this.propertyAnalysis.address;

                          this.transactionDataService.creattransactionficras(this.transaction, this.idDomaine, this.today2);

                          this.analysisDataService.updateCreditficras(this.cre - 1, 'Billable', this.idDomaine);
                          this.analysisDataService.updateInvoicingStatusficras(this.propertyAnalysis.ProductType, this.propertyAnalysis.userid, this.idDomaine, 'Billable', this.idReport).then(y => {
                            this.router.navigate(['/auth/report/', this.idReport, this.idDomaine])
                          })


                        })
                    } else {
                      swal.fire({
                        title: 'You are about to generate a report',
                        text: "This action will constitute a charge against your account and billed at the end of the month",
                        icon: 'warning',
                        input: 'radio',
                        inputOptions:
                        {
                          'Market Trends Analysis': 'Market Trends Analysis($ 60)',
                          'Market Data Analysis': 'Market Data Analysis  &nbsp; &nbsp;  ($ 130)',
                          'Valnow Valuation': 'Valnow Valuation  &nbsp; &nbsp;  ($ 310)'
                        },
                        inputValidator: (value) => {
                          if (!value) {
                            return 'You need to choose something!'
                          }
                        },
                        inputValue: this.propertyAnalysis.ProductType,
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'PROCEED'
                      }).then((result) => {
                        if (result.value) {
                          console.log("eeeeeeeeeeeeeeeeeeedddddddddddddddddd");

                          console.log(result);
                          this.transaction.date = this.today2;
                          this.transaction.package = this.product.desc;
                          this.transaction.credit = -1;
                          this.transaction.Id = this.firestore.createId();
                          this.transaction.iduser = this.emailuser;
                          // this.transaction.email = this.user.email;
                          this.transaction.type = "Valuation";
                          this.transaction.ide = this.idDomaine;
                          this.transaction.zipCode = this.propertyAnalysis.zipCode;
                          this.transaction.nomDomaine = this.nomDomaine;
                          this.transaction.amount = 0;
                          this.transaction.coupon = "";
                          this.transaction.state = this.propertyAnalysis.state;
                          this.transaction.city = this.propertyAnalysis.city;
                          this.transaction.adress = this.propertyAnalysis.address;

                          this.transactionDataService.creattransactionficras(this.transaction, this.idDomaine, this.today2);

                          this.analysisDataService.updateCreditficras(this.cre - 1, 'Not to bill', this.idDomaine);
                          this.analysisDataService.updateInvoicingStatusficras(result.value, this.propertyAnalysis.userid, this.idDomaine, 'Not to bill', this.idReport).then(y => {
                            this.router.navigate(['/auth/report/', this.idReport, this.idDomaine])
                          })

                        }
                      })
                    }
                  } else {
                    if (this.domaine.status === 'Unlimited Offer') {
                      swal.fire({
                        title: 'You are about to generate a report',
                        text: "This action will constitute a charge against your account and billed at the end of the month",
                        icon: 'warning',
                        input: 'radio',
                        inputOptions:
                        {
                          'Market Trends Analysis': 'Market Trends Analysis($ 60)',
                          'Market Data Analysis': 'Market Data Analysis  &nbsp; &nbsp;  ($ 130)',
                          'Valnow Valuation': 'Valnow Valuation  &nbsp; &nbsp;  ($ 310)'
                        },
                        inputValidator: (value) => {
                          if (!value) {
                            return 'You need to choose something!'
                          }
                        },
                        inputValue: this.propertyAnalysis.ProductType,
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'PROCEED'
                      }).then((result) => {
                        if (result.value) {
                          console.log("eeeeeeeeeeeeeeeeeeedddddddddddddddddd");

                          console.log(result);
                          this.transaction.date = this.today2;
                          this.transaction.package = this.product.desc;
                          this.transaction.credit = -1;
                          this.transaction.Id = this.firestore.createId();
                          this.transaction.iduser = this.emailuser;
                          // this.transaction.email = this.user.email;
                          this.transaction.type = "Valuation";
                          this.transaction.ide = this.idDomaine;
                          this.transaction.zipCode = this.propertyAnalysis.zipCode;
                          this.transaction.nomDomaine = this.nomDomaine;
                          this.transaction.amount = 0;
                          this.transaction.coupon = "";
                          this.transaction.state = this.propertyAnalysis.state;
                          this.transaction.city = this.propertyAnalysis.city;
                          this.transaction.adress = this.propertyAnalysis.address;

                          this.transactionDataService.creattransactionficras(this.transaction, this.idDomaine, this.today2);

                          this.analysisDataService.updateCreditficras(this.cre - 1, 'Not to bill', this.idDomaine);
                          this.analysisDataService.updateInvoicingStatusficras(result.value, this.propertyAnalysis.userid, this.idDomaine, 'Not to bill', this.idReport).then(y => {
                            this.router.navigate(['/auth/report/', this.idReport, this.idDomaine])
                          })

                        }
                      })
                    }else{
                      if (this.domaine.credit <= 0) {
                        swal.fire({
                          title: 'Unfortunately, you have no more free credit',
                          html: "If you proceed, you will be directed to Stripe checkout service to be charged, then redirected back to your report. You can check our pricing model here " + '<a href="https://val-now.com/pricing.html"> val-now.com </a> ' + ". <br> If you want to be billed separately at month-end or per report, or if you want to ask for a specific agreement, please contact us at blee@val-now.com.",
                          icon: 'error',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Buy'
                        }).then((result) => {
                          if (result.value) {
                            firebase.auth().currentUser.getIdTokenResult().then(res => {
                              console.log(this.propertyAnalysis.ProductType);
                              if (this.propertyAnalysis.ProductType == 'Valnow Valuation') {
                                this.product_id = "prod_M3wLNoNs7RXkZ5"
                                this.price_id = "price_1LLoSqH2nIzq8u28iFWhpR9a"
                              } else {
                                if (this.propertyAnalysis.ProductType == 'Market Data Analysis') {
                                  this.product_id = "prod_M3wLwLEYhYJHBZ"
                                  this.price_id = "price_1LLoSxH2nIzq8u28nz3O1Vce"
                                }
                                else {
                                  if (this.propertyAnalysis.ProductType == 'Market Trends Analysis') {
                                    this.product_id = "prod_M3wL6eu9zyO2HZ"
                                    this.price_id = "price_1LLoT4H2nIzq8u28ysWruhE0"
                                  }
                                }
                              }
                              if (this.product_id) {
                                this.analysisDataService.checkoutstripe(this.product_id, this.price_id, this.idReport, res.token)
                                  .subscribe((res: any) => {
                                    console.log(res);
                                    console.log(res);
                                    this.analysisDataService.updateInvoicingStatusficras(this.propertyAnalysis.ProductType, this.propertyAnalysis.userid, this.idDomaine, 'Invoiced', this.idReport);
  
                                    this.analysisDataService.updateCreditficras(this.cre - 1, 'Invoiced', this.idDomaine).then(y => {
                                      this.router.navigate(['/auth/report/', this.idReport, this.idDomaine])
                                    })
                                    this.sessionId = res.id;
  
                                    console.log(res.toString());
                                    console.log(this.sessionId);
  
                                    this.idSes = this.sessionId;
                                    this.openPay();
                                  })
                              }
  
                            });
                          }
                        })
                      } else {
                        swal.fire({
                          title: 'You are about to generate a report',
                          text: "This action will constitute a charge against your account and billed at the end of the month",
                          icon: 'warning',
                          input: 'radio',
                          inputOptions:
                          {
                            'Market Trends Analysis': 'Market Trends Analysis  ($ 60)',
                            'Market Data Analysis': 'Market Data Analysis  &nbsp; &nbsp;  ($ 130)',
                            'Valnow Valuation': 'Valnow Valuation  &nbsp; &nbsp;  ($ 310)'
                          },
                          inputValidator: (value) => {
                            if (!value) {
                              return 'You need to choose something!'
                            }
                          },
                          inputValue: this.propertyAnalysis.ProductType,
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'PROCEED'
                        }).then((result) => {
                          if (result.value) {
                            console.log("eeeeeeeeeeeeeeeeeeedddddddddddddddddd");
  
                            console.log(result);
                            this.transaction.date = this.today2;
                            this.transaction.package = this.product.desc;
                            this.transaction.credit = -1;
                            this.transaction.Id = this.firestore.createId();
                            this.transaction.iduser = this.emailuser;
                            // this.transaction.email = this.user.email;
                            this.transaction.type = "Valuation";
                            this.transaction.ide = this.idDomaine;
                            this.transaction.zipCode = this.propertyAnalysis.zipCode;
                            this.transaction.nomDomaine = this.nomDomaine;
                            this.transaction.amount = 0;
                            this.transaction.coupon = "";
                            this.transaction.state = this.propertyAnalysis.state;
                            this.transaction.city = this.propertyAnalysis.city;
                            this.transaction.adress = this.propertyAnalysis.address;
                            this.analysisDataService.updateInvoicingStatusficras(result.value, this.propertyAnalysis.userid, this.idDomaine, 'Not to bill', this.idReport);
                            this.transactionDataService.creattransactionficras(this.transaction, this.idDomaine, this.today2);
                            this.analysisDataService.updateCreditficras(this.cre - 1, 'Not to bill', this.idDomaine).then(y => {
                              this.router.navigate(['/auth/report/', this.idReport, this.idDomaine])
                            })
  
                          }
                        })
                      }
                    }
                   
                  }
                }

              }
              else if (res.dismiss === swal.DismissReason.cancel) {
                this.propertyAnalysis.score = 0;
                this.propertyAnalysis.estimateValue = 0;
                this.propertyAnalysis.estimateScore = 0;
                this.propertyAnalysis.draft = 0;
                this.analysisDataService.updateAnalysisjust0(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
              }
            })
          }

        }
      })

    } else {

      swal.fire({
        title: 'Please Wait...',
        text: "This may take one or two minutes to process",
        timer: 50000,
        allowOutsideClick: false,
        timerProgressBar: true,
        onBeforeOpen: () => {
          swal.showLoading();
          this.analysisDataService.getvaluation(this.propertyAnalysis, this.idReport).subscribe(res => {
            if (res) {
              this.idRealTime = res[0].result;
              if (this.idRealTime) {
                if (this.propertyAnalysis.ContractPrice && (this.propertyAnalysis.lowest < +this.propertyAnalysis.ContractPrice && this.propertyAnalysis.highest > +this.propertyAnalysis.ContractPrice)) {
                  if (this.estimateScore === 'A') {
                    this.propertyAnalysis.estimateValue = (this.idRealTime * 0.3) + (this.propertyAnalysis.ContractPrice * 0.66);
                    this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                  } else if (this.estimateScore === 'B') {
                    this.propertyAnalysis.estimateValue = (this.idRealTime * 0.25) + (this.propertyAnalysis.ContractPrice * 0.75);
                    this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                  } else if (this.estimateScore === 'C') {
                    this.propertyAnalysis.estimateValue = (this.idRealTime * 0.20) + (this.propertyAnalysis.ContractPrice * 0.80);
                    this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                  } else if (this.estimateScore === 'D') {
                    this.propertyAnalysis.estimateValue = (this.idRealTime * 0.10) + (this.propertyAnalysis.ContractPrice * 0.90);
                    this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                  }


                }
                else
                  if (((!this.propertyAnalysis.ContractPrice || this.propertyAnalysis.ContractPrice == "") && (this.propertyAnalysis.dateMostRecentSale && this.propertyAnalysis.mostRecentSalePrice) && (this.propertyAnalysis.lowest < +this.propertyAnalysis.mostRecentSalePrice && this.propertyAnalysis.highest > +this.propertyAnalysis.mostRecentSalePrice)) && (this.cmpStringDate(this.propertyAnalysis.dateMostRecentSale, this.propertyAnalysis.valuationDate))) {
                    if (this.estimateScore === 'A') {
                      this.propertyAnalysis.estimateValue = (this.idRealTime * 0.3) + (this.propertyAnalysis.mostRecentSalePrice * 0.66);
                      this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                    } else if (this.estimateScore === 'B') {
                      this.propertyAnalysis.estimateValue = (this.idRealTime * 0.25) + (this.propertyAnalysis.mostRecentSalePrice * 0.75);
                      this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                    } else if (this.estimateScore === 'C') {
                      this.propertyAnalysis.estimateValue = (this.idRealTime * 0.20) + (this.propertyAnalysis.mostRecentSalePrice * 0.80);
                      this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                    } else if (this.estimateScore === 'D') {
                      this.propertyAnalysis.estimateValue = (this.idRealTime * 0.10) + (this.propertyAnalysis.mostRecentSalePrice * 0.90);
                      this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                    }
                  }
                  else
                    if (((!this.propertyAnalysis.ContractPrice || this.propertyAnalysis.ContractPrice == "") && (this.propertyAnalysis.dateRecentAppraisal && this.propertyAnalysis.recentAppraisal) && (this.propertyAnalysis.lowest < +this.propertyAnalysis.recentAppraisal && this.propertyAnalysis.highest > +this.propertyAnalysis.recentAppraisal)) && (this.cmpStringDate(this.propertyAnalysis.dateRecentAppraisal, this.propertyAnalysis.valuationDate))) {
                      if (this.estimateScore === 'A') {
                        this.propertyAnalysis.estimateValue = (this.idRealTime * 0.50) + (this.propertyAnalysis.recentAppraisal * 0.50);
                        this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                      } else if (this.estimateScore === 'B') {
                        this.propertyAnalysis.estimateValue = (this.idRealTime * 0.40) + (this.propertyAnalysis.recentAppraisal * 0.60);
                        this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                      } else if (this.estimateScore === 'C') {
                        this.propertyAnalysis.estimateValue = (this.idRealTime * 0.30) + (this.propertyAnalysis.recentAppraisal * 0.70);
                        this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                      } else if (this.estimateScore === 'D') {
                        this.propertyAnalysis.estimateValue = (this.idRealTime * 0.20) + (this.propertyAnalysis.recentAppraisal * 0.80);
                        this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                      }
                    }
                    else
                      if (((!this.propertyAnalysis.ContractPrice || this.propertyAnalysis.ContractPrice == "") && (this.propertyAnalysis.datelistPrice && this.propertyAnalysis.listPrice) && (this.propertyAnalysis.lowest < +this.propertyAnalysis.listPrice && this.propertyAnalysis.highest > +this.propertyAnalysis.listPrice))) {
                        if (this.estimateScore === 'A') {
                          this.propertyAnalysis.estimateValue = (this.idRealTime * 0.50) + (this.propertyAnalysis.listPrice * 0.50);
                          this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                        } else if (this.estimateScore === 'B') {
                          this.propertyAnalysis.estimateValue = (this.idRealTime * 0.40) + (this.propertyAnalysis.listPrice * 0.60);
                          this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                        } else if (this.estimateScore === 'C') {
                          this.propertyAnalysis.estimateValue = (this.idRealTime * 0.30) + (this.propertyAnalysis.listPrice * 0.70);
                          this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                        } else if (this.estimateScore === 'D') {
                          this.propertyAnalysis.estimateValue = (this.idRealTime * 0.20) + (this.propertyAnalysis.listPrice * 0.80);
                          this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
                        }
                      }
                      else {
                        this.propertyAnalysis.estimateValue = this.idRealTime;
                        this.analysisDataService.updateAnalysisjustestimate(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();

                      }
              }
              this.analysisDataService.Stats_analysis(this.propertyAnalysis, this.propertyAnalysis.estimateValue).subscribe((list0: any) => {
                if (list0) {
                  this.propertyAnalysis.Income_Trends = list0.Income_trend;
                  this.propertyAnalysis.neareast = list0.nearest;
                  let subtypeNb = 0;
                  if (list0.nearest.Address[0] != "") {
                    subtypeNb++;
                  }
                  if (list0.nearest.Address[1] != "") {
                    subtypeNb++;
                  }
                  if (list0.nearest.Address[2] != "") {
                    subtypeNb++;
                  }
                  this.propertyAnalysis.comparablesnb = subtypeNb;
                  this.propertyAnalysis.Market_Value_Trends = list0.Market_value_trends;
                  this.propertyAnalysis.Capitalization_Rate_Analysis = list0.Capitalization_rate;
                  this.analysisDataService.updateAnal3(this.propertyAnalysis, this.idReport, this.idDomaine);
                  this.changeStatus();
                }
              });
            }
          });
        }
      }).then((resul) => {
        this.getAnalysis();
        this.analysisDataService.getDomaine(this.idDomaine).subscribe((res: any) => {
          this.cre = +res.credit;
        })


        if (this.domaine.status === 'SUSPENDED') {
          swal.fire({
            title: 'Your account is suspended',
            text: "In order to reactivate it, please contact us at blee@val-now.com .",
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ok'
          })
        } else {
          if (this.domaine.status === 'MANAGED ACCOUNT') {
            if (this.domaine.credit <= 0) {
              swal.fire({
                title: 'Caution',
                text: "By proceeding, you agree to be charged according to our agreement for this report generation.",
                icon: 'error',
                showCancelButton: false,
                // showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
                // confirmButtonText: 'Buy Credit'
              })
                .then((result) => {
                  console.log("eeeeeeeeeeeeeeeeeeedddddddddddddddddd");

                  console.log(result);
                  this.transaction.date = this.today2;
                  this.transaction.package = this.product.desc;
                  this.transaction.credit = -1;
                  this.transaction.Id = this.firestore.createId();
                  this.transaction.iduser = this.emailuser;
                  // this.transaction.email = this.user.email;
                  this.transaction.type = "Valuation";
                  this.transaction.ide = this.idDomaine;
                  this.transaction.zipCode = this.propertyAnalysis.zipCode;
                  this.transaction.nomDomaine = this.nomDomaine;
                  this.transaction.amount = 0;
                  this.transaction.coupon = "";
                  this.transaction.state = this.propertyAnalysis.state;
                  this.transaction.city = this.propertyAnalysis.city;
                  this.transaction.adress = this.propertyAnalysis.address;

                  this.transactionDataService.creattransactionficras(this.transaction, this.idDomaine, this.today2);

                  this.analysisDataService.updateCreditficras(this.cre - 1, 'Billable', this.idDomaine);
                  this.analysisDataService.updateInvoicingStatusficras(this.propertyAnalysis.ProductType, this.propertyAnalysis.userid, this.idDomaine, 'Billable', this.idReport).then(y => {
                    this.router.navigate(['/auth/report/', this.idReport, this.idDomaine])
                  })


                })
            } else {
              swal.fire({
                title: 'You are about to generate a report',
                text: "This action will constitute a charge against your account and billed at the end of the month",
                icon: 'warning',
                input: 'radio',
                inputOptions:
                {
                  'Market Trends Analysis': 'Market Trends Analysis($ 60)',
                  'Market Data Analysis': 'Market Data Analysis  &nbsp; &nbsp;  ($ 130)',
                  'Valnow Valuation': 'Valnow Valuation  &nbsp; &nbsp;  ($ 310)'
                },
                inputValidator: (value) => {
                  if (!value) {
                    return 'You need to choose something!'
                  }
                },
                inputValue: this.propertyAnalysis.ProductType,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'PROCEED'
              }).then((result) => {
                if (result.value) {
                  console.log("eeeeeeeeeeeeeeeeeeedddddddddddddddddd");

                  console.log(result);
                  this.transaction.date = this.today2;
                  this.transaction.package = this.product.desc;
                  this.transaction.credit = -1;
                  this.transaction.Id = this.firestore.createId();
                  this.transaction.iduser = this.emailuser;
                  // this.transaction.email = this.user.email;
                  this.transaction.type = "Valuation";
                  this.transaction.ide = this.idDomaine;
                  this.transaction.zipCode = this.propertyAnalysis.zipCode;
                  this.transaction.nomDomaine = this.nomDomaine;
                  this.transaction.amount = 0;
                  this.transaction.coupon = "";
                  this.transaction.state = this.propertyAnalysis.state;
                  this.transaction.city = this.propertyAnalysis.city;
                  this.transaction.adress = this.propertyAnalysis.address;

                  this.transactionDataService.creattransactionficras(this.transaction, this.idDomaine, this.today2);

                  this.analysisDataService.updateCreditficras(this.cre - 1, 'Not to bill', this.idDomaine);
                  this.analysisDataService.updateInvoicingStatusficras(result.value, this.propertyAnalysis.userid, this.idDomaine, 'Not to bill', this.idReport).then(y => {
                    this.router.navigate(['/auth/report/', this.idReport, this.idDomaine])
                  })

                }
              })
            }
          } else {
            if (this.domaine.status === 'Unlimited Offer') {
              swal.fire({
                title: 'You are about to generate a report',
                text: "This action will constitute a charge against your account and billed at the end of the month",
                icon: 'warning',
                input: 'radio',
                inputOptions:
                {
                  'Market Trends Analysis': 'Market Trends Analysis($ 60)',
                  'Market Data Analysis': 'Market Data Analysis  &nbsp; &nbsp;  ($ 130)',
                  'Valnow Valuation': 'Valnow Valuation  &nbsp; &nbsp;  ($ 310)'
                },
                inputValidator: (value) => {
                  if (!value) {
                    return 'You need to choose something!'
                  }
                },
                inputValue: this.propertyAnalysis.ProductType,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'PROCEED'
              }).then((result) => {
                if (result.value) {
                  console.log("eeeeeeeeeeeeeeeeeeedddddddddddddddddd");

                  console.log(result);
                  this.transaction.date = this.today2;
                  this.transaction.package = this.product.desc;
                  this.transaction.credit = -1;
                  this.transaction.Id = this.firestore.createId();
                  this.transaction.iduser = this.emailuser;
                  // this.transaction.email = this.user.email;
                  this.transaction.type = "Valuation";
                  this.transaction.ide = this.idDomaine;
                  this.transaction.zipCode = this.propertyAnalysis.zipCode;
                  this.transaction.nomDomaine = this.nomDomaine;
                  this.transaction.amount = 0;
                  this.transaction.coupon = "";
                  this.transaction.state = this.propertyAnalysis.state;
                  this.transaction.city = this.propertyAnalysis.city;
                  this.transaction.adress = this.propertyAnalysis.address;

                  this.transactionDataService.creattransactionficras(this.transaction, this.idDomaine, this.today2);

                  this.analysisDataService.updateCreditficras(this.cre - 1, 'Not to bill', this.idDomaine);
                  this.analysisDataService.updateInvoicingStatusficras(result.value, this.propertyAnalysis.userid, this.idDomaine, 'Not to bill', this.idReport).then(y => {
                    this.router.navigate(['/auth/report/', this.idReport, this.idDomaine])
                  })

                }
              })
            }else{
              if (this.domaine.credit <= 0) {
              swal.fire({
                title: 'Unfortunately, you have no more free credit',
                html: "If you proceed, you will be directed to Stripe checkout service to be charged, then redirected back to your report. You can check our pricing model here " + '<a href="https://val-now.com/pricing.html"> val-now.com </a> ' + ". <br> If you want to be billed separately at month-end or per report, or if you want to ask for a specific agreement, please contact us at blee@val-now.com.",
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Buy'
              }).then((result) => {
                if (result.value) {
                  firebase.auth().currentUser.getIdTokenResult().then(res => {
                    console.log(this.propertyAnalysis.ProductType);
                    if (this.propertyAnalysis.ProductType == 'Valnow Valuation') {
                      this.product_id = "prod_M3wLNoNs7RXkZ5"
                      this.price_id = "price_1LLoSqH2nIzq8u28iFWhpR9a"
                    } else {
                      if (this.propertyAnalysis.ProductType == 'Market Data Analysis') {
                        this.product_id = "prod_M3wLwLEYhYJHBZ"
                        this.price_id = "price_1LLoSxH2nIzq8u28nz3O1Vce"
                      }
                      else {
                        if (this.propertyAnalysis.ProductType == 'Market Trends Analysis') {
                          this.product_id = "prod_M3wL6eu9zyO2HZ"
                          this.price_id = "price_1LLoT4H2nIzq8u28ysWruhE0"
                        }
                      }
                    }
                    if (this.product_id) {
                      this.analysisDataService.checkoutstripe(this.product_id, this.price_id, this.idReport, res.token)
                        .subscribe((res: any) => {
                          console.log(res);
                          console.log(res);
                          this.analysisDataService.updateInvoicingStatusficras(this.propertyAnalysis.ProductType, this.propertyAnalysis.userid, this.idDomaine, 'Invoiced', this.idReport);

                          this.analysisDataService.updateCreditficras(this.cre - 1, 'Invoiced', this.idDomaine).then(y => {
                            this.router.navigate(['/auth/report/', this.idReport, this.idDomaine])
                          })
                          this.sessionId = res.id;

                          console.log(res.toString());
                          console.log(this.sessionId);

                          this.idSes = this.sessionId;
                          this.openPay();
                        })
                    }

                  });
                }
              })
            } else {
              swal.fire({
                title: 'You are about to generate a report',
                text: "This action will constitute a charge against your account and billed at the end of the month",
                icon: 'warning',
                input: 'radio',
                inputOptions:
                {
                  'Market Trends Analysis': 'Market Trends Analysis  ($ 60)',
                  'Market Data Analysis': 'Market Data Analysis  &nbsp; &nbsp;  ($ 130)',
                  'Valnow Valuation': 'Valnow Valuation  &nbsp; &nbsp;  ($ 310)'
                },
                inputValidator: (value) => {
                  if (!value) {
                    return 'You need to choose something!'
                  }
                },
                inputValue: this.propertyAnalysis.ProductType,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'PROCEED'
              }).then((result) => {
                if (result.value) {
                  console.log("eeeeeeeeeeeeeeeeeeedddddddddddddddddd");

                  console.log(result);
                  this.transaction.date = this.today2;
                  this.transaction.package = this.product.desc;
                  this.transaction.credit = -1;
                  this.transaction.Id = this.firestore.createId();
                  this.transaction.iduser = this.emailuser;
                  // this.transaction.email = this.user.email;
                  this.transaction.type = "Valuation";
                  this.transaction.ide = this.idDomaine;
                  this.transaction.zipCode = this.propertyAnalysis.zipCode;
                  this.transaction.nomDomaine = this.nomDomaine;
                  this.transaction.amount = 0;
                  this.transaction.coupon = "";
                  this.transaction.state = this.propertyAnalysis.state;
                  this.transaction.city = this.propertyAnalysis.city;
                  this.transaction.adress = this.propertyAnalysis.address;
                  this.analysisDataService.updateInvoicingStatusficras(result.value, this.propertyAnalysis.userid, this.idDomaine, 'Not to bill', this.idReport);
                  this.transactionDataService.creattransactionficras(this.transaction, this.idDomaine, this.today2);
                  this.analysisDataService.updateCreditficras(this.cre - 1, 'Not to bill', this.idDomaine).then(y => {
                    this.router.navigate(['/auth/report/', this.idReport, this.idDomaine])
                  })

                }
              })
            }
            }
            
          }
        }



      })
    }



    // }
  }

  cre: number;
  sessionId; idSes;
  async openPay() {
    console.log("fiiii id session ");
    console.log(this.idSes);

    const { error } = await (await stripe).redirectToCheckout({ sessionId: this.idSes })

  }
  changeScore(x) {

    // if (x == 0) {
    //   this.estimateScore = 0;
    //   this.propertyAnalysis.estimateScore = 0;
    // }

    if (x < 12 && x > 0) {
      this.estimateScore = 'A';
      this.propertyAnalysis.estimateScore = 4;
      this.analysisDataService.updatescore(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();

    } else if (x < 25 && x >= 12) {
      this.estimateScore = 'B';
      this.propertyAnalysis.estimateScore = 3;
      this.analysisDataService.updatescore(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();

    } else if (x >= 25 && x < 35) {
      this.estimateScore = 'C';
      this.propertyAnalysis.estimateScore = 2;
      this.analysisDataService.updatescore(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();

    }
    // else if (x >= 35 && x < 100) {
    //   this.estimateScore = 'D'
    //   this.propertyAnalysis.estimateScore = 1;
    // } 
    else if (x >= 35) {
      this.propertyAnalysis.estimateScore = 0;
      this.analysisDataService.updatescore(this.propertyAnalysis, this.idReport, this.idDomaine); this.changeStatus();
    }
  }
  estimateScore: string = '';
  estimateValue: number;
  lowest: number;
  highest: number;
  getEstim(boostedS, v) {
    console.log("fiii get estimeeeeeeeeeeeeeeeeee");

    console.log(boostedS);
    console.log(v);

    this.lowest = v - ((v * boostedS) / 100);
    this.highest = v + ((v * boostedS) / 100);
    // if (estimateScore == 0) { } else
    //   if (estimateScore == 1) {
    //     this.lowest = v - ((v * 30) / 100);
    //     this.highest = v + ((v * 30) / 100);

    //   } else if (estimateScore == 2) {
    //     this.lowest = v - ((v * 20) / 100);
    //     this.highest = v + ((v * 20) / 100);

    //   } else if (estimateScore == 3) {
    //     this.lowest = v - ((v * 10) / 100);
    //     this.highest = v + ((v * 10) / 100);
    //   } else if (estimateScore == 4) {
    //     this.lowest = v - ((v * 10) / 100);
    //     this.highest = v + ((v * 10) / 100);
    //   }

    this.analysisDataService.updateEstimateHigh(this.idReport, this.idDomaine, this.highest).then(l => {
      console.log("doneee");

    })
    this.analysisDataService.updateEstimateLow(this.idReport, this.idDomaine, this.lowest).then(l => {
      console.log("doneee 222222222222");

    })


  }
  test() {
    console.log(this.propertyAnalysis.ProductType);

    this.analysisDataService.updateProductType(this.propertyAnalysis.ProductType, this.idDomaine, this.idReport)
  }
}
