
export class MenuConfig {
	
	public defaults: any = {
		aside: {
			self: {},

			items: [
				// {
				// 	title: 'Dashboard',
				// 	root: true,
				// 	icon: 'flaticon2-architecture-and-city',
				// 	page: '/dashboard',
				// 	translate: 'MENU.DASHBOARD',
				// 	bullet: 'dot',
				// },
				{section: 'Analyses'},
				{
					root: true,
					icon: 'flaticon-users-1',
					title: 'My Analyses', 
					bullet: 'dot',
					page: '/list-property-analysis/myList',
				},
				{
					root: true,
					icon: 'flaticon-users-1',
					title: 'Enterprise Analyses',
					bullet: 'dot',
					page: '/list-property-analysis/list',
				},
				// {
				// 	root: true,
				// 	icon: 'flaticon-list',
				// 	title: 'Portfolio',
				// 	bullet: 'dot',
				// 	page: '/userManagment/listPortfolio',
				// },
				{section: 'Admin'},
				{
					title: 'User Management',
					root: true,
					icon: 'flaticon-users-1',
					page: '/userManagment/list',
					bullet: 'dot',
				},
				// {
				// 	title: 'Purchase Credits',
				// 	root: true,
				// 	icon: 'flaticon-settings',
				// 	page: '/payment/checkout',
				// 	bullet: 'dot',
				// },
				// {
				// 	title: 'Credits Usage',
				// 	root: true,
				// 	icon: 'flaticon-list',
				// 	page: '/transaction/list',
				// 	bullet: 'dot',
				// },
				{
					title: 'Account info',
					root: true,
					icon: 'flaticon-information',
					page: '/profile',
					bullet: 'dot',
				}
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}

