import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { MyListModel } from '../_models/MyListModel';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

const API_MyList_URL = 'api/MyLists';

@Injectable()
export class MyListsService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService
		, private firestore: AngularFirestore) { }
	createMyList(MyList: MyListModel): Observable<MyListModel> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<MyListModel>(API_MyList_URL, MyList, { headers: httpHeaders });
	}

	getAllMyLists(): Observable<MyListModel[]> {
		return this.http.get<MyListModel[]>('https://console-back-dot-prod-smarket.appspot.com/cars');
	}

	getMyListById(MyListId: number): Observable<MyListModel> {
		return this.http.get<MyListModel>(API_MyList_URL + `/${MyListId}`);
	}
	eid: string; 

	// const Ref=   this.firestore.collection<UsermanagementModel>("users", ref => ref.where('idDomaine','==', idNameSpace)); 
	// 	return  Ref.valueChanges(); 

	findMyLists(idNameSpace, createdBy): Observable<any> {
		console.log(idNameSpace);
		console.log(createdBy);
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection<MyListModel>("analysis", ref => {
			return ref.where('createdBy', '==', createdBy);
		}
		).valueChanges();
	}
	// findPropertyAnalysiss(idNameSpace): Observable<any> {
	// 	return this.firestore.doc(`/entreprises/${idNameSpace}`).collection<PropertyAnalysisModel>("analysis", ref => {
	// 		return ref.orderBy("valuationDate", "desc");
	// 	}).valueChanges();     
	// }
	updateMyList(MyList: MyListModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_MyList_URL, MyList, { headers: httpHeader });
	}

	updateStatusForMyList(MyLists: MyListModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			MyListsForUpdate: MyLists,
			newStatus: status
		};
		const url = API_MyList_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	deleteMyList(MyListId: string, idNameSpace: string): Promise<void> {
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(MyListId).delete();

	}
	// createUser(User: MyListModel): Observable<any>{

	// }
	deleteMyLists(ids: number[] = []): Observable<any> {
		const url = API_MyList_URL + '/deleteMyLists';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { MyListIdsForDelete: ids };
		return this.http.put<any>(url, body, { headers: httpHeaders });
	}
}
