import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap, map, tap, delay } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryRealtimeParamsModel } from '../../_base/crud';
import { MyListsService } from '../_services';
import { AppState } from '../../reducers';
import {

    MyListActionToggleLoading,
    MyListActionTypes,
    MyListsPageLoaded,
    MyListsPageRequested,
    MyListsPageToggleLoading,
    OneMyListDeleted,
    MyListOnServerCreated,
    MyListCreated

} from '../_actions/MyList.actions';
import { of } from 'rxjs';

@Injectable()
export class MyListEffects {
    showPageLoadingDistpatcher = new MyListsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new MyListActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new MyListActionToggleLoading({ isLoading: false });
    @Effect()
    loadMyListsPage$ = this.actions$.pipe(
        ofType<MyListsPageRequested>(MyListActionTypes.MyListsPageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            return this.MyListsService.findMyLists(payload.list,payload.createdBy);

        }),
        map(response => {
            console.log(response);
            const result: QueryRealtimeParamsModel = response;
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new MyListsPageLoaded({
                MyLists: result
            });
            return pageLoadedDispatch;
        })
    );
    // @Effect()
    // createUser$ = this.actions$
    //     .pipe(
    //         ofType<MyListOnServerCreated>(MyListActionTypes.MyListOnServerCreated),
    //         mergeMap(( { payload } ) => {
    //             this.store.dispatch(this.showActionLoadingDistpatcher);
    //             return this.MyListsService.createUser(payload.MyList).pipe(
    //                 tap(res => {
    //                     this.store.dispatch(new MyListCreated({ MyList: res }));
    //                 })
    //             );
    //         }),
    //         map(() => {
    //             return this.hideActionLoadingDistpatcher;
    //         }),
    //     );
    @Effect()
    deleteCustomer$ = this.actions$
        .pipe(
            ofType<OneMyListDeleted>(MyListActionTypes.OneMyListDeleted),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.MyListsService.deleteMyList(payload.id,payload.idNameSpace);
            }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private MyListsService: MyListsService, private store: Store<AppState>) { }

}
