export class StaticData {

    public static propertyConditions = [
        
        { value: 'Excellent', viewValue: 'Excellent' },
        { value: 'Very Good', viewValue: 'Very Good' },
        { value: 'Good', viewValue: 'Good' },
        { value: 'Above Average', viewValue: 'Above Average' },
        { value: 'Average', viewValue: 'Average' },
        { value: 'Below Average', viewValue: 'Below Average' },
        { value: 'Poor', viewValue: 'Poor' },
        { value: 'Very Poor', viewValue: 'Very Poor' },
        { value: 'New', viewValue: 'New' },

    ];
    public static constructionQualitys = [
        { value: 'Excellent', viewValue: 'Excellent' },
        { value: 'Very Good', viewValue: 'Very Good' },
        { value: 'Good', viewValue: 'Good' },
        { value: 'Above Average', viewValue: 'Above Average' },
        { value: 'Average', viewValue: 'Average' },
        { value: 'Below Average', viewValue: 'Below Average' },
        { value: 'Poor', viewValue: 'Poor' },
        { value: 'Very Poor', viewValue: 'Very Poor' },
        { value: 'New', viewValue: 'New' },
    ];
    public static constructionTypes = [
        { viewValue: 'A – Structural Steel', value: 'Structural Steel' },
        { viewValue: 'B – Fire Resistant, Reinforced Concrete Frame', value: 'Fire Resistant, Reinforced Concrete Frame' },
        { viewValue: 'C – Masonry', value: 'Masonry' },
        { viewValue: 'D – Wood Frame', value: 'wood studs' },
        { viewValue: 'S – Steel Frame And Metal', value: 'Steel Frame And Metal' }
    ];
    public static buildingClasss = [
        { value: 'A - top 10 percent of the market', viewValue: 'A - top 10 percent of the market' },
        { value: 'B - top 10 to 50 percent of the market', viewValue: 'B - top 10 to 50 percent of the market' },
        { value: 'C - bottom 20 to 50 percent of the market', viewValue: 'C - bottom 20 to 50 percent of the market' },
        { value: 'D - bottom 20 percent of the market', viewValue: 'D - bottom 20 percent of the market' }

    ];
    public static parkingTypes = [
        { value: '' },
        { value: 'carport' },
        { value: 'covered structural parking' },
        { value: 'gravel parking' },
        { value: 'no onsite parking' },
        { value: 'parking garages' },
        { value: 'partially paved and partially unpaved parking' },
        { value: 'surface and structural' },
        { value: 'surface parking' },
        { value: 'unstriped parking' },
    ];
    public static industrial = [
        { value: 'Condominium Unit(s)' },
        // { value: 'Industrial; Manufacturing' },
        { value: 'Distribution Warehouse' },
        { value: 'Manufacturing - Light Industrial' },
        { value: 'Office Showroom / Flex Space' },
        { value: 'Office Warehouse' },
        { value: 'Refrigerated Cold Storage' },
        { value: 'Research and Development' },
        { value: 'Storage Warehouse' },
    ];
    public static multiFamily = [
        { value: 'Garden / Low-Rise' },
        { value: 'Mid Rise / High Rise' },
    ];
    public static office = [
        { value: 'Condominium Unit(s)' },
        { value: 'Low Rise' },
        { value: 'Medical Office' },
        { value: 'Medical Office Condominium' },
        { value: 'Mid Rise / High Rise' },
    ];
    public static retail = [
        { value: 'Condominium Unit(s)' },
        { value: 'Convenience Store / Service Station / Gas Station' },
        { value: 'Free Standing Bank Branch' },
        { value: 'Free Standing Retail Building' },
        { value: 'Restaurant - Fast Food / Quick Service Restaurant (QSR) / Limited Service' },
        { value: 'Restaurant - Full Service' },
        { value: 'Retail Center' },
    ];
    public static years = [
        { value: '1991' },
        { value: '1992' },
        { value: '1993' },
        { value: '1994' },
        { value: '1995' },
        { value: '1996' },
        { value: '1997' },
        { value: '1998' },
        { value: '1999' },
        { value: '2000' },
        { value: '2001' },
        { value: '2002' },
        { value: '2003' },
        { value: '2004' },
        { value: '2005' },
        { value: '2006' },
        { value: '2007' },
        { value: '2008' },
        { value: '2009' },
        { value: '2010' },
        { value: '2011' },
        { value: '2012' },
        { value: '2013' },
        { value: '2014' },
        { value: '2015' },
        { value: '2016' },
        { value: '2017' },
        { value: '2018' }
    ];

    public static vouchers = [
        { Coupon_code: 'CO-1234', Partner_name: 'Vaned & Co', reduction: '20' },
        { Coupon_code: 'CO-4567', Partner_name: 'Valbridge Colorado', reduction: '20' },
        { Coupon_code: 'CA-1234', Partner_name: 'California Valbridge', reduction: '20' }
    ];
}