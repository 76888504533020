// Angular
import { ModuleWithProviders, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
// Material
// Translate
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// CRUD
import { InterceptService, TypesUtilsService, LayoutUtilsService, HttpUtilsService } from '../../../core/_base/crud/';
// Module components
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthNoticeComponent } from './auth-notice/auth-notice.component';
// Auth
import { AuthEffects, AuthGuard, authReducer, AuthService, ModuleGuard } from '../../../core/auth';
import { MailConfirmationComponent } from './mail-confirmation/mail-confirmation.component';
import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatFormFieldModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatExpansionModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule,
	MatSlideToggleModule,
	MatDividerModule,
	MatSliderModule
} from '@angular/material';
import { AddEntrepriseComponent } from './add-entreprise/add-entreprise.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { TransactionsService } from '../../../core/property-analysis/_services/transaction.service';
import { AddgeneralComponent } from './addgeneral/addgeneral.component';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { FusionChartsModule } from 'angular-fusioncharts';
import { PartialsModule } from '../../partials/partials.module';
import { GaugeChartModule } from 'angular-gauge-chart';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { RatingModule } from 'ng-starrating';
import {
	PropertyAnalysissReducer,
	PropertyAnalysisEffects,
	PropertyAnalysissService,
  } from '../../../../app/core/property-analysis';
  
  import {
	MyListsReducer,
	MyListEffects,
	MyListsService,
  } from '../../../../app/core/property-analysis';
  import {
	PropertyDetailsReducer,
	PropertyDetailEffects,
  } from '../../../../app/core/property-analysis';
  export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
  import { TransactioRedsService } from '../../../core/transaction';
  import { MomentDateFormatter } from '../../../core/property-analysis/_services/ngbDateCustomParserFormetter';
import { RepportgeneralComponent } from './repportgeneral/repportgeneral.component';
import { ReviewgeneralComponent } from './reviewgeneral/reviewgeneral.component';
import { EditgeneralComponent } from './editgeneral/editgeneral.component';
const routes: Routes = [
	{
		path: '',
		component: AuthComponent,
		children: [
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full'
			},
			{
				path: 'login',
				component: LoginComponent,
				data: { returnUrl: window.location.pathname }
			},
			{
				path: 'addEntreprise',
				component: AddEntrepriseComponent,
				canActivate: [ModuleGuard], 
			},
			{
				path: 'register',
				component: RegisterComponent
			},
			{
				path: 'mail-confirmation',
				component: MailConfirmationComponent
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent,
			},
			{
				path: 'underConstrunctionPage',
				component: UnderConstructionComponent,
			},
			{
				path: 'add',
				component: AddgeneralComponent,
			},
			{
				path: 'edit/:id/:iddomaine',
				component: EditgeneralComponent,
			},
			{
				path: 'report/:id/:iddomaine',
				component: RepportgeneralComponent,
			},
			{
				path: 'review/:id/:iddomaine',
				component: ReviewgeneralComponent,
			}
		]
	}
];


@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		FusionChartsModule,
		PartialsModule,
		RouterModule.forChild(routes),
		GaugeChartModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		NgxMaskModule.forRoot(options),
		TranslateModule.forChild(),
		MatButtonModule,
		AgmCoreModule.forRoot({apiKey: 'AIzaSyD_cRxIW8WpAvihOD_0uefqfL-XBe5CcFg', libraries: ["places"]}),
		MatMenuModule,
		MatSelectModule,
		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		NgxCurrencyModule,
		MatRadioModule,
		MatIconModule,
		// ShortNumberPipe,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatSliderModule,
		RatingModule,
		MatExpansionModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule,
		MatSlideToggleModule,
		MatDividerModule,
		StoreModule.forFeature('PropertyAnalysis', PropertyAnalysissReducer),
		EffectsModule.forFeature([PropertyAnalysisEffects]),
		StoreModule.forFeature('MyList', MyListsReducer),
		EffectsModule.forFeature([MyListEffects]),
		StoreModule.forFeature('PropertyDetails', PropertyDetailsReducer),
		EffectsModule.forFeature([PropertyDetailEffects]),
	  ],
	  providers: [
		InterceptService,
		AuthService,
		PropertyAnalysissService,
		MomentDateFormatter, 
		MyListsService,
	   TransactionsService,
		{
		  provide: HTTP_INTERCEPTORS,
		  useClass: InterceptService,
		  multi: true
		},
		{provide: NgbDateParserFormatter, useClass: MomentDateFormatter},
		{
		  provide: MAT_DIALOG_DEFAULT_OPTIONS,
		  useValue: {
			hasBackdrop: true,
			panelClass: 'kt-mat-dialog-container__wrapper',
			height: 'auto',
			width: '900px'
		  }
		},
		TypesUtilsService,
		LayoutUtilsService,
		HttpUtilsService,
		TransactioRedsService,
		LayoutUtilsService,
		InterceptService,
		TransactionsService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true
		},
	],
	
	exports: [AuthComponent,FusionChartsModule],
	declarations: [
		AuthComponent,
		LoginComponent,
		RegisterComponent,
		ForgotPasswordComponent,
		AuthNoticeComponent,
		MailConfirmationComponent,
		AddEntrepriseComponent,
		UnderConstructionComponent,
		AddgeneralComponent,
		RepportgeneralComponent,
		ReviewgeneralComponent,
		EditgeneralComponent
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	  ],
})

export class AuthModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: AuthModule,
			providers: [
				AuthService,
				AuthGuard,
				ModuleGuard
			]
		};
	}
}
