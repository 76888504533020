import { Component, OnInit, ChangeDetectorRef, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { PropertyAnalysisModel, PropertyAnalysissService } from '../../../../core/property-analysis';
import { Subject, throwError } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { Pipe, PipeTransform } from '@angular/core';
import * as $ from 'jquery';
import swal from 'sweetalert2';
import { EvaluationComponent } from '../../property-analysis/property-analysis-report/evaluation/evaluation.component';

import { MatDialog } from '@angular/material';
import { TransactioRedsService } from '../../../../core/transaction';
import { QuestionaireComponent } from '../../property-analysis/property-analysis-report/questionaire/questionaire.component';
import FusionCharts from 'fusioncharts';
// import { Domaine } from 'src/app/core/auth/_models/Domaine.model';
import { Domaine } from '../../../../core/auth/_models/Domaine.model';
// import * as jsPDF from 'jspdf'
// import html2canvas from 'html2canvas';
import * as firebase from 'firebase';
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';

@Pipe({
  name: "phone"
})
export class PhonePipe {
  transform(rawNum) {
    rawNum = rawNum.charAt(0) != 0 ? "0" + rawNum : "" + rawNum;

    let newStr = "";
    let i = 0;

    for (; i < Math.floor(rawNum.length / 2) - 1; i++) {
      newStr = newStr + rawNum.substr(i * 2, 2) + "-";
    }

    return newStr + rawNum.substr(i * 2);
  }
}
@Pipe({
  name: 'shortNumber'
})
@Component({
  selector: 'kt-repportgeneral',
  templateUrl: './repportgeneral.component.html',
  styleUrls: ['./repportgeneral.component.scss']
})
export class RepportgeneralComponent implements OnInit {


  invoice: any;
  idReport: string;
  idDomaine: string;
  propertyAnalysis = new PropertyAnalysisModel;
  val: number;
  // Private
  private _unsubscribeAll: Subject<any>;
  value: number;
  score: number;
  estimateScore: number = 0;
  estimateValue: number = -1;
  dataSource: any;

  today: number = Date.now();
  public canvasWidth = 150
  public needleValue;
  satellite;
  heading = 90;
  tilt = 45;
  public centralLabel = ''
  public name = 'Confidence score'
  public bottomLabel = '' + this.estimateScore;
  displayName: any;
  loading: boolean;
  disable$: any;
  idtraitementReport: string;
  blob: Blob;
  latitude: number = 0;
  longitude: number = 0;
  zoom: number;
  zoom2: number;

  // options = {
  //   hasNeedle: true,
  //   // needleColor: 'black',
  //   // needleUpdateSpeed: 7000,
  //   needleColor: 'black',
  //   needleUpdateSpeed: 7000,
  //   arcColors: ["red", "orange", "green"],
  //   arcDelimiters: [25, 75],
  //   rangeLabel: ['1', '4'],
  //   needleStartValue: 0,
  // }
  /**
   * Constructor
   *
   * @param {InvoiceService} _invoiceService
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private analysisDataService: PropertyAnalysissService,
    private authService: AuthService,
    // @Inject('Window') private window: Window,
    private _cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private transactioRedsService: TransactioRedsService,


  ) {
    this.zoom = 12;
    this.zoom2 = 16;
    this.satellite = 'satellite'
    FusionCharts.options.creditLabel = false;
    // this.authService.user$.pipe().subscribe((res => {
    //   this.idDomaine = res.idDomaine;
    //   this.displayName = res.displayName;
    //   this.authService.getBusinessName(this.idDomaine).subscribe(
    //     (list: Domaine) => {
    //       if (list) {
    //         this.domaine = list;
    //       }
    //     }
    //   );
    this.route.params.subscribe(
      (params: Params) => {
        this.idReport = params['id'];
        this.idDomaine = params['iddomaine'];
        if (this.idReport && this.idDomaine) {
          this.getAnalysis();
          if(this.idReport){
            this.analysisDataService.updateDraft(this.idReport, this.idDomaine);
          }
        }

        // this.analysisDataService.updateScore(this.idReport,this.idDomaine,this.estimateScore);
      });


    // })
    // )
  }
  ngOnDestroy() {
    // this.changeStatus2();

  }
  pad(num, size) {
		num = num.toString();
		while (num.length < size) num = "0" + num;
		return num;
	}
  // changeStatus2(): void {
  //   let cmp = 0;
  //   let cmp2 = 0;
  //   setTimeout(() => {
  //     this.transactioRedsService.getTrasaction(this.idDomaine).subscribe(res => {
  //       if (res.length === 1) {
  //         cmp++;

  //         if (cmp == 1) {
  //           const dialogRef = this.dialog.open(EvaluationComponent);
  //           dialogRef.afterClosed().subscribe(res => {
  //             // this.get();
  //             if (!res) {
  //               return;
  //             }
  //           });
  //         }

  //       } else if (res.length === 2) {
  //         cmp2++;

  //         if (cmp2 == 1) {
  //           const dialogRef = this.dialog.open(QuestionaireComponent);
  //           dialogRef.afterClosed().subscribe(res => {
  //             if (!res) {
  //               return;
  //             }

  //           });
  //         }
  //       }
  //     })

  //   }, 1000);
  // }
  nameR: string;
  async download() {
      this.loading = true;
      const Toast = swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 7500,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener('mouseenter', swal.stopTimer)
          toast.addEventListener('mouseleave', swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'success',
        title: 'Generating report ...'
      })
      if (this.propertyAnalysis.propertyName && (this.propertyAnalysis.propertyName != "")) {
        this.nameR = this.propertyAnalysis.propertyName + '_' + this.propertyAnalysis.valuationDate + 'analysis';
      } else {
        this.nameR = this.propertyAnalysis.zipCode + '_' + this.propertyAnalysis.state + '_' + this.propertyAnalysis.valuationDate + 'analysis';
      }
      this.analysisDataService.getreport(this.idReport).subscribe((data: any) => {

        if (data) { 
          this.blob = new Blob([data], { type: 'application/pdf' });

          var downloadURL = window.URL.createObjectURL(data);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = this.nameR + '.pdf';
          link.click();

        }
      });
    
  }

  @ViewChild('htmlData', { static: true }) htmlData: ElementRef;
  //   download() {
  //     console.log(this.htmlData);
  //     console.log(this.htmlData.nativeElement);

  //   var data = document.getElementById('htmlData');
  //   html2canvas(data).then(canvas => {

  //     var margin = 4;

  //     var imgWidth = 205 - 2*margin; 
  //     var pageHeight = 295;
  //     var imgHeight = canvas.height * imgWidth / canvas.width;
  //     var heightLeft = imgHeight;


  //     var doc = new jsPDF('p', 'mm');
  //     var position = 0;
  //     const contentDataURL = canvas.toDataURL('image/png')

  //     doc.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;

  //     while (heightLeft >= 0) {
  //     position = heightLeft - imgHeight;
  //     doc.addPage();
  //     doc.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;
  //     }
  //     doc.save( 'Repport.pdf'); 

  //   });

  // }

  highest: any = -1;
  lowest: any = -1;
  ngOnInit() {
    this.getEstim(this.estimateScore);


  }
  domaine: Domaine;

  goback() {
    this.router.navigate(['/']);
  }


  changeStatus(): void {

    setTimeout(() => {

      this._cdr.detectChanges()
      this._cdr.markForCheck()

    }, 500);
  }

  getToNewA() {
    // [routerLink]="['/auth/add']"
   
      this.router.navigate(['/auth/add']);
  }
  neareast;
  Market_Value_Trends;
  Income_Trends;
  Capitalization_Rate_Analysis = [];
  array = [];
  getAnalysis() {
    return this.analysisDataService.getAnalysis(this.idReport, this.idDomaine).subscribe(
      (res: PropertyAnalysisModel) => {
        if (res) {
          this.Capitalization_Rate_Analysis = [];
          this.propertyAnalysis = res;
          this.latitude = this.propertyAnalysis.latitude;
          this.longitude = this.propertyAnalysis.longitude;
          if (this.propertyAnalysis.Capitalization_Rate_Analysis) {
            for (var i in this.propertyAnalysis.Capitalization_Rate_Analysis) { this.Capitalization_Rate_Analysis.push(this.propertyAnalysis.Capitalization_Rate_Analysis[i]); }
          } else {
            this.Capitalization_Rate_Analysis = null;
          }
          if (res.estimateValue < 500000) {
            this.estimateValue = (Math.round(res.estimateValue / 1000) * 1000);
            this.changeStatus();
          }
          else if (res.estimateValue > 500000 && res.estimateValue < 2000000) {
            this.estimateValue = (Math.round(res.estimateValue / 10000) * 10000);
            this.changeStatus();
          }
          else if (res.estimateValue > 2000001 && res.estimateValue < 10000000) {
            this.estimateValue = (Math.round(res.estimateValue / 100000) * 100000);
            this.changeStatus();
          }
          else if (res.estimateValue > 10000001) {
            this.estimateValue = (Math.round(res.estimateValue / 500000) * 500000);
            this.changeStatus();
          }

          if (res.highest < 500000) {
            this.highest = (Math.round(res.highest / 1000) * 1000);
            this.changeStatus();
          }
          else if (res.highest > 500000 && res.highest < 2000000) {
            this.highest = (Math.round(res.highest / 10000) * 10000);
            this.changeStatus();
          }
          else if (res.highest > 2000001 && res.highest < 10000000) {
            this.highest = (Math.round(res.highest / 100000) * 100000);
            this.changeStatus();
          }
          else if (res.highest > 10000001) {
            this.highest = (Math.round(res.highest / 500000) * 500000);
            this.changeStatus();
          }



          
          if (res.lowest < 500000) {
            this.lowest = (Math.round(res.lowest / 1000) * 1000);
            this.changeStatus();
          }
          else if (res.lowest > 500000 && res.lowest < 2000000) {
            this.lowest = (Math.round(res.lowest / 10000) * 10000);
            this.changeStatus();
          }
          else if (res.lowest > 2000001 && res.lowest < 10000000) {
            this.lowest = (Math.round(res.lowest / 100000) * 100000);
            this.changeStatus();
          }
          else if (res.lowest > 10000001) {
            this.lowest = (Math.round(res.lowest / 500000) * 500000);
            this.changeStatus();
          }
          this.estimateScore = res.score;

          const dataSource = {

            chart: {
              caption: "Confidence score",
              // subcaption: "food.hsm.com",
              // lowerLimit: "70",
              // showTickMarks: "0",
              // showTickValues: "0",
              majorTMNumber: "9",
              upperLimit: "100",
              editMode: "1",
              reverseScale: "1",
              numberSuffix: "%",
              // valueAbovePointer: "0",
              // showShadow: "70",
              gaugeFillMix: "{light}",
              theme: "fusion",
              // chartBottomMargin: "40",
              // valueFontSize: "11",
              // valueFontBold: "0"        
            },
            colorRange: {
              color: [
                {
                  minValue: "65",
                  maxValue: "75",
                  label: "C",
                  code: "#7ED5EA",
                },
                {
                  minValue: "75",
                  maxValue: "88",
                  label: "B",
                  code: "#63BCE5"
                },

                {
                  minValue: "88",
                  maxValue: "100",
                  label: "A",
                  code: "#4B9FE1"
                }
              ]
            },
            pointers: {
              pointer: [
                {
                  value: (100 - this.estimateScore).toFixed(1)
                }
              ]
            },
          };
          this.dataSource = dataSource;

          this.getEstim(this.estimateScore);

          this.changeStatus();
        }

      },
      // (error)=> this.router.navigate(['/auth/underConstrunctionPage']),
    ),
      (complete) => console.log(this.propertyAnalysis)


  }

  getEstim(estimateScore) {
    if (this.estimateScore == 0) {
      this.needleValue = 0;
    } else if (this.estimateScore == 1) {


      this.needleValue = 25;
    } else if (this.estimateScore == 2) {

      this.needleValue = 50;
    } else if (this.estimateScore == 3) {

      this.needleValue = 75;
    } else if (this.estimateScore == 4) {
      this.needleValue = 100;
    }
    this.bottomLabel = estimateScore;

  }
}
