import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { PropertyAnalysisActions, PropertyAnalysisActionTypes } from '../_actions/PropertyAnalysis.actions';
import { PropertyAnalysisModel } from '../_models/PropertyAnalysisModel';
import { QueryParamsModel } from '../../_base/crud';

export interface PropertyAnalysisState extends EntityState<PropertyAnalysisModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedPropertyAnalysisId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<PropertyAnalysisModel> = createEntityAdapter<PropertyAnalysisModel>({
    selectId: PropertyAnalysisConfigModel => PropertyAnalysisConfigModel.id
  });
  
export const initialPropertyAnalysisState: PropertyAnalysisState = adapter.getInitialState({
    PropertyAnalysisForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedPropertyAnalysisId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function PropertyAnalysissReducer(state = initialPropertyAnalysisState, action: PropertyAnalysisActions): PropertyAnalysisState {
    switch  (action.type) {
        case PropertyAnalysisActionTypes.PropertyAnalysissPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedPropertyAnalysisId: undefined
            };
        }    

        case PropertyAnalysisActionTypes.PropertyAnalysisActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }

        case PropertyAnalysisActionTypes.PropertyAnalysisOnServerCreated: return {
            ...state
        };

        case PropertyAnalysisActionTypes.PropertyAnalysisUpdated: return adapter.updateOne(action.payload.partialPropertyAnalysis, state);

        // case PropertyAnalysisActionTypes.PropertyAnalysissStatusUpdated: {
        //     const _partialPropertyAnalysis: Update<PropertyAnalysisModel>[] = [];
        //     for (let i = 0; i < action.payload.PropertyAnalysiss.length; i++) {
        //         _partialPropertyAnalysis.push({
		// 		    id: action.payload.PropertyAnalysiss[i].id,
		// 		    changes: {
        //                 status: action.payload.
        //             }
		// 	    });
        //     }
        //     return adapter.updateMany(_partialPropertyAnalysis, state);
        // }

        case PropertyAnalysisActionTypes.OnePropertyAnalysisDeleted: return adapter.removeOne(action.payload.id, state);

        case PropertyAnalysisActionTypes.PropertyAnalysissPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }

        case PropertyAnalysisActionTypes.PropertyAnalysissPageLoaded: {
            return adapter.addMany(action.payload.PropertyAnalysiss, {
                ...initialPropertyAnalysisState,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}



export const getPropertyAnalysisState = createFeatureSelector<PropertyAnalysisModel>('PropertyAnalysis');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
