// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { MyListModel } from '../_models/MyListModel';

export enum MyListActionTypes {
    MyListOnServerCreated = '[Edit MyList Dialog] MyList On Server Created',
    MyListCreated = '[Edit MyList Dialog] MyList Created',
    MyListUpdated = '[Edit MyList Dialog] MyList Updated',
    MyListsStatusUpdated = '[MyLists List Page] MyLists Status Updated',
    OneMyListDeleted = '[MyLists List Page] One MyList Deleted',

    MyListsPageRequested = '[MyLists List Page] MyLists Page Requested',
    MyListsPageLoaded = '[MyLists API] MyLists Page Loaded',


    MyListsPageCancelled = '[MyLists API] MyLists Page Cancelled',
    MyListsPageToggleLoading = '[MyLists] MyLists Page Toggle Loading',
    MyListActionToggleLoading = '[MyLists] MyLists Action Toggle Loading'
}

export class MyListOnServerCreated implements Action {
    readonly type = MyListActionTypes.MyListOnServerCreated;
    constructor(public payload: { MyList: MyListModel }) { }
}

export class MyListCreated implements Action {
    readonly type = MyListActionTypes.MyListCreated;
    constructor(public payload: { MyList: MyListModel }) { }
}

export class MyListUpdated implements Action {
    readonly type = MyListActionTypes.MyListUpdated;
    constructor(public payload: {
        partialMyList: Update<MyListModel>, 
        MyList: MyListModel 
    }) { }
}

export class MyListsStatusUpdated implements Action {
    readonly type = MyListActionTypes.MyListsStatusUpdated;
    constructor(public  payload: {
        MyLists: MyListModel[],
        status: number
    }) { }
}

export class OneMyListDeleted implements Action {
    readonly type = MyListActionTypes.OneMyListDeleted;
    constructor(public payload: { id: string ,idNameSpace:string}) {}
}


export class MyListsPageRequested implements Action {
    readonly type = MyListActionTypes.MyListsPageRequested;
    constructor(public payload: { list: any,createdBy:string }) { }
}

export class MyListsPageLoaded implements Action {
    readonly type = MyListActionTypes.MyListsPageLoaded;
    constructor(public payload: any) { }
}

export class MyListsPageCancelled implements Action {
    readonly type = MyListActionTypes.MyListsPageCancelled;
}

export class MyListsPageToggleLoading implements Action {
    readonly type = MyListActionTypes.MyListsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class MyListActionToggleLoading implements Action {
    readonly type = MyListActionTypes.MyListActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}




export type MyListActions = MyListOnServerCreated
| MyListCreated
| MyListUpdated
| MyListsStatusUpdated
| OneMyListDeleted
| MyListsPageRequested
| MyListsPageLoaded
| MyListsPageCancelled
| MyListsPageToggleLoading
| MyListActionToggleLoading;
