import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, NgZone, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { PropertyAnalysisModel, PropertyAnalysissDataSource, PropertyAnalysissPageRequested, OnePropertyAnalysisDeleted, PropertyAnalysisOnServerCreated, PropertyAnalysissService, PropertyDetailsDataSource, PropertyDetailsPageRequested } from '../../../../core/property-analysis';
import { StaticData } from '../../../../core/property-analysis/_models/StaticData';
import { MapsAPILoader } from '@agm/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { FormControl } from '@angular/forms';
import { google } from "google-maps";
import { AppState } from '../../../../core/reducers';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { MomentDateFormatter } from '../../../../core/property-analysis/_services/ngbDateCustomParserFormetter';
import { DatePipe } from '@angular/common';
import { Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, skip, take, delay } from 'rxjs/operators';
import swal from 'sweetalert2';
import moment from 'moment';
@Component({
  selector: 'kt-editgeneral',
  templateUrl: './editgeneral.component.html',
  styleUrls: ['./editgeneral.component.scss']
})
export class EditgeneralComponent implements OnInit {
  options = {
    startStep: 1,
    clickableSteps: false
  };
  yearfinace: number = 1;
  @ViewChild('wizard', { static: true }) el: ElementRef;
  @ViewChild('wizard2', { static: false }) e2: ElementRef;
  @ViewChild('wizard3', { static: false }) e3: ElementRef;
  @ViewChild('wizard4', { static: false }) e4: ElementRef;
  addressOk: boolean = true;
  ngAfterViewInit(): void {
    // Initialize form wizard
    const wizard = new KTWizard(this.el.nativeElement, this.options);
    wizard.on('beforeNext', function (wizardObj) {

    });

    // Change event
    wizard.on('change', function (wizard) {
      var options = {
        startStep: 1,
        clickableSteps: false
      };
      setTimeout(function () {
        KTUtil.scrollTop();
      }, 500);
    });
    const wizard2 = new KTWizard2(this.e2.nativeElement, {
      startStep: 1
    });
    wizard2.on('beforeNext', function (wizardObj) {
    });
    wizard2.on('change', function (wizard) {
      var options = {
        clickableSteps: false
      };
      setTimeout(function () {
        KTUtil.scrollTop();
      }, 500);
    });

    const wizard3 = new KTWizard2(this.e3.nativeElement, {
      startStep: 1
    });
    wizard3.on('beforeNext', function (wizardObj) {
    });
    wizard3.on('change', function (wizard) {
      var options = {
        startStep: 1,
        clickableSteps: false
      };
      setTimeout(function () {
        KTUtil.scrollTop();
      }, 500);
    });

    const wizard4 = new KTWizard2(this.e4.nativeElement, {
      startStep: 1
    });

    wizard4.on('beforeNext', function (wizardObj) {
    });

    wizard4.on('change', function (wizard) {
      setTimeout(function () {
        KTUtil.scrollTop();
      }, 500);
    });

  }
  propertyConditions = StaticData.propertyConditions;
  constructionQualitys = StaticData.constructionQualitys;
  constructionTypes = StaticData.constructionTypes;
  buildingClasss = StaticData.buildingClasss;
  parkingTypes = StaticData.parkingTypes;
  industrial = StaticData.industrial;
  multiFamily = StaticData.multiFamily;
  office = StaticData.office;
  retail = StaticData.retail;
  years = StaticData.years;
  //google map option
  latitude: number;
  longitude: number;
  zoom: number;
  marker: any[];
  mapAddress: any;
  mapAllo: any;
  form: FormGroup;
  form2: FormGroup;
  form3: FormGroup;
  form4: FormGroup;
  form5: FormGroup;
  form6: FormGroup;

  ok: boolean;
  okName: boolean;
  okType: boolean;
  okZipCode: boolean;
  zipcodes: [] = [];
  allZipCodes: [] = [];
  nums: number[] = []
  private subscriptions: Subscription[] = [];

  idAnalysis: string;
  allowEdit: boolean = false;
  private _matDialog: MatDialog;

  propertyAnalysis = new PropertyAnalysisModel();
  @ViewChild('f', { static: true }) analyisForm: NgForm;

  @ViewChild("search", { static: true })
  public searchElementRef: ElementRef;

  dataSourcetag: PropertyDetailsDataSource;


  analyis: Observable<PropertyAnalysisModel>
  test: any[];
  idDomaine: string;
  today: number = Date.now();
  today2: any;
  id: any;
  sender: any = " ";
  receiver: any = " ";
  date: any;
  next: boolean;
  selectedIndex: number;

  AutoTotalOperatingExpensesYearOne: boolean = false;
  AutoTotalOperatingExpensesYearOneGroupe: boolean = true;
  clickedYearOne: boolean = true;
  AutoTotalOperatingExpensesYearOneSwitch: boolean = true;
  YearOneOnCmp: number = 0;
  onOrOffCmp2YearOne: any;
  yearOneAuto2: boolean = true;


  AutoTotalOperatingExpensesYearTwo: boolean = false;
  AutoTotalOperatingExpensesYearTwoGroupe: boolean = true;
  clickedYearTwo: boolean = true;
  AutoTotalOperatingExpensesYearTwoSwitch: boolean = true;
  YearTwoOnCmp: number = 0;
  onOrOffCmp2YearTwo: any;
  yearTwoAuto2: boolean = true;
  dataSource: PropertyDetailsDataSource;

  AutoTotalOperatingExpensesYearThree: boolean = false;
  AutoTotalOperatingExpensesYearThreeGroupe: boolean = true;
  clickedYearThree: boolean = true;
  AutoTotalOperatingExpensesYearThreeSwitch: boolean = true;
  YearThreeOnCmp: number = 0;
  onOrOffCmp2YearThree: any;
  datenow: any;
  product_Typedomaine;
  yearThreeAuto2: boolean = true;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private analysisDataService: PropertyAnalysissService,
    // private dialogService: DialogService,
    private mapsAPILoader: MapsAPILoader,
    private _cdr: ChangeDetectorRef,
    private ref: ChangeDetectorRef,
    private ngZone: NgZone,
    public zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private MomentDateFormatter: MomentDateFormatter,
    private store: Store<AppState>,
    public snackBar: MatSnackBar,
    private firestore: AngularFirestore
  ) {
    this.today2 = datePipe.transform(this.today, 'yyyy-MM-dd');
    
    this.route.queryParams.subscribe(
      (queryParams: Params) => {
        this.allowEdit = queryParams['allowEdit'] === '1' ? true : false;
      }
    );
    this.route.params.subscribe(
      (params: Params) => {
        this.idAnalysis = params['id'];
        this.idDomaine = params['iddomaine'];
        this.authService.getBusinessName(this.idDomaine).subscribe(
          (list: any) => {
            if (list) {
              this.product_Typedomaine = list.product_Type;
              this.changeStatus();
              this.onGetAnalysis(this.idAnalysis);
            }
          }
        );
      });


    
    this.datenow = (new Date()).getFullYear();

    this.form4 = this._formBuilder.group({
      RenoYr: ['', Validators.compose([Validators.max(this.datenow), Validators.min(1950)])]
    }
    );
    this.form = this._formBuilder.group({
      year: ['', Validators.compose([Validators.max(this.datenow), Validators.min(1900)])]
    }
    );
    this.form2 = this._formBuilder.group({
      numBuild: ['', Validators.compose([Validators.max(100), Validators.min(1)])]
    });
    this.form3 = this._formBuilder.group({
      occupancyRate: ['', Validators.compose([Validators.max(100), Validators.min(0)])]
    });
    this.form5 = this._formBuilder.group({
      percentAC: ['', Validators.compose([Validators.max(100), Validators.min(0)])]
    });
    this.form6 = this._formBuilder.group({
      VaccancyStabOne: ['', Validators.compose([Validators.max(99), Validators.min(0)])]
    });
  }

  address = ""
  ngOnInit() {
    this.zoom = 10;
    this.address = this.propertyAnalysis.address;

    this.setCurrentPosition();
    this.mapsAPILoader.load().then(res => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: [],
      });

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.propertyAnalysis.address = place;
          this.mapAddress = place.formatted_address;
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.propertyAnalysis.longitude = this.longitude;
          this.propertyAnalysis.latitude = this.latitude;
          this.zoom = 12;
          if (this.propertyAnalysis.latitude == 0 && this.propertyAnalysis.longitude == 0) {
            this.addressOk = false;
          } else {
            this.addressOk = true;
          }
          for (var i = 0; i < place.address_components.length; i++) {
            for (var j = 0; j < place.address_components[i].types.length; j++) {

              if (place.address_components[i].types[j] == "locality") {
                this.propertyAnalysis.city = place.address_components[i].long_name;
              }
              if (place.address_components[i].types[j] == "administrative_area_level_1") {
                this.propertyAnalysis.state = place.address_components[i].long_name;
              }
              if (place.address_components[i].types[j] == "postal_code") {
                this.getZips('');
                this.propertyAnalysis.zipCode = this.pad(parseInt(place.address_components[i].long_name),5)
              }
            }
            this.changeStatus2();

          }
        });
      });
    });

    this.id = (this._activatedRoute.snapshot.queryParams['id'] || null);

  }
  changeStatus2(): void {

    setTimeout(() => {

      this._cdr.detectChanges()
      this._cdr.markForCheck()

    }, 500);
  }
  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;

      });
    }
  }

  onDuplicate() {
    if (this.verifData(this.propertyAnalysis)) {
      if (this.valdate != this.propertyAnalysis.valuationDate) {
        const date = this.MomentDateFormatter.format(this.propertyAnalysis.valuationDate);
        this.propertyAnalysis.valuationDate = date;

        const d = new Date(this.propertyAnalysis.valuationDate);
        this.propertyAnalysis.valuationDate2 = d.getTime();

      }
      if (this.DateMost != this.propertyAnalysis.dateMostRecentSale) {
        const date2 = this.MomentDateFormatter.format(this.propertyAnalysis.dateMostRecentSale);
        this.propertyAnalysis.dateMostRecentSale = date2;
      }
      if (this.dateRecentA != this.propertyAnalysis.dateRecentAppraisal) {
        const date3 = this.MomentDateFormatter.format(this.propertyAnalysis.dateRecentAppraisal);
        this.propertyAnalysis.dateRecentAppraisal = date3;
      }
      // if(this.datelistP != this.propertyAnalysis.datelistPrice){
      //   const date4 = this.MomentDateFormatter.format(this.propertyAnalysis.datelistPrice);
      //   this.propertyAnalysis.datelistPrice = date4;
      // }
      // const datet = this.propertyAnalysis.valuationDate;
      this.analysisDataService.creatAnalysis(this.propertyAnalysis, this.idDomaine, this.id).then(res => this.analyisForm.reset());
      // this.router.navigate(['/list-property-analysis/myList']);

    }
    else {
      this.next = false
    }
  }
  list: any;
  zipcode: any;
  good = false;

  verifData(propertyAnalysis: PropertyAnalysisModel) {

    var okName = false;
    var okName2 = false;
    var okType = false;
    var oktype = false;
    var okZipCode = false;
    if (!propertyAnalysis.propertyType) {
      okType = false;
      swal.fire({
        icon: 'error',
        title: 'Property type is mandatory',
        text: '',
      });
    } else {
      okType = true;
    }
    if (!propertyAnalysis.ProductType ) {
      oktype = false;
      swal.fire({
        icon: 'error',
        title: 'property  report Type is required',
        text: '',
      });
      
    } else {

      oktype = true;
    }
    if (!propertyAnalysis.propertySubType) {
      okName = false;
      swal.fire({
        icon: 'error',
        title: 'property SubType is required',
        text: '',
      });
    } else {

      okName = true;
    }
    if (propertyAnalysis.valuationDate === "") {
      okName2 = false;
      swal.fire({
        icon: 'error',
        title: 'Valuation Date is required',
        text: '',
      });
    } else {

      okName2 = true;
    }    
    if (!this.propertyAnalysis.zipCode || this.pad(this.propertyAnalysis.zipCode,5).toString().length != 5 || this.propertyAnalysis.zipCode == 0 || this.propertyAnalysis.zipCode == undefined) {
      okZipCode = false;
      swal.fire({
        icon: 'error',
        title: 'Zip code format not valid!',
        text: '',
      });
    } else {
      okZipCode = true;

    }


    return okName && okType && okZipCode && oktype && okName2
  }
  retur(){
    const wizard = new KTWizard(this.el.nativeElement, {
      startStep: 2
    });
    wizard.goLast();

  }
  verifConditionData() {
    var verifPropertyCond = false;
    if (this.propertyAnalysis.propertyCondition) {
      verifPropertyCond = true;
      swal.fire({
        icon: 'error',
        title: 'Property Condition Required',
        text: '',
      });
    } else {
      verifPropertyCond = false;
    }
    return verifPropertyCond;
  }

  // verifSprinkFireData() {
  //   var verifSprinkFire = false;
  //   if (this.propertyAnalysis.fireSprinkler) {
  //     verifSprinkFire = true;
  //     this.snackBar.open('Property Fire Sprinkler Required', '', {
  //       verticalPosition: 'top',
  //       duration: 4000,
  //       panelClass: ['error-toaster']
  //     });
  //   } else {
  //     verifSprinkFire = false;
  //   }
  //   return verifSprinkFire;
  // }

  // verifConstructionQualityData() {
  //   var verifConstructionQuality = false;
  //   if (this.propertyAnalysis.constructionQuality) {
  //     verifConstructionQuality = true;
  //     this.snackBar.open('Property Fire Construction Quality Required', '', {
  //       verticalPosition: 'top',
  //       duration: 4000,
  //       panelClass: ['error-toaster']
  //     });
  //   } else {
  //     verifConstructionQuality = false;
  //   }
  //   return verifConstructionQuality;
  // }

  verifNetRentableArea() {
    var verifnetRentableArea = false;
    if (this.propertyAnalysis.netRentableArea) {
      verifnetRentableArea = true;
      swal.fire({
        icon: 'error',
        title: 'Net Rentable Area Required',
        text: '',
      });
    } else {
      verifnetRentableArea = false;
    }
    return verifnetRentableArea;
  }

  getGEIValeus() {
    var yearOneGI: number = 0;
    var yearTwoGI: number = 0;
    var yearThreeGI: number = 0;
    var cmpt = 0;
    if (this.propertyAnalysis.effectiveGrossIncomeYearOne) {
      yearOneGI = this.propertyAnalysis.effectiveGrossIncomeYearOne;
      cmpt = cmpt + 1;

    }

    if (this.propertyAnalysis.effectiveGrossIncomeYearTwo) {
      yearTwoGI = this.propertyAnalysis.effectiveGrossIncomeYearTwo;
      cmpt = cmpt + 1;
    }

    if (this.propertyAnalysis.effectiveGrossIncomeYearThree) {
      yearThreeGI = this.propertyAnalysis.effectiveGrossIncomeYearThree;
      cmpt = cmpt + 1;
    }

    if ((yearOneGI != 0) || (yearTwoGI != 0) || (yearThreeGI != 0)) {
      if (cmpt >= 2) {
        return (yearOneGI * 10) / 100 + (yearTwoGI * 20) / 100 + (yearThreeGI * 70) / 100
      }
      else {
        return yearOneGI + yearTwoGI + yearThreeGI;
      }
    } else {
      return "";
    }
  }
  getVacuValeus() {
    var yearOneGI: number = 0;
    var yearTwoGI: number = 0;
    var yearThreeGI: number = 0;
    var cmpt = 0;
    // if (this.propertyAnalysis.VaccancyStabOne) {
    //   yearOneGI = this.propertyAnalysis.VaccancyStabOne;
    //   cmpt = cmpt + 1;

    // }

    if (this.propertyAnalysis.VaccancyStabTow) {
      yearTwoGI = this.propertyAnalysis.VaccancyStabTow;
      cmpt = cmpt + 1;
    }

    if (this.propertyAnalysis.VaccancyStabThree) {
      yearThreeGI = this.propertyAnalysis.VaccancyStabThree;
      cmpt = cmpt + 1;
    }

    if ((yearOneGI != 0) || (yearTwoGI != 0) || (yearThreeGI != 0)) {
      if (cmpt >= 2) {
        return (yearOneGI * 10) / 100 + (yearTwoGI * 20) / 100 + (yearThreeGI * 70) / 100
      }
      else {
        return yearOneGI + yearTwoGI + yearThreeGI;
      }
    } else {
      return "";
    }
  }
  getTEValeus() {
    var yearOneTE: number = 0;
    var yearTwoTE: number = 0;
    var yearThreeTE: number = 0;
    var cmpt = 0;

    if (this.propertyAnalysis.yearOne) {
      if (this.propertyAnalysis.totalExpensesUserEnteredYearOne) {
        yearOneTE = this.propertyAnalysis.totalExpensesUserEnteredYearOne;
        cmpt = cmpt + 1;
      }
    }
    if (this.propertyAnalysis.yearTwo) {
      if (this.propertyAnalysis.totalExpensesUserEnteredYearTwo) {
        yearTwoTE = this.propertyAnalysis.totalExpensesUserEnteredYearTwo;
        cmpt = cmpt + 1;
      }
    }
    if (this.propertyAnalysis.yearThree) {
      if (this.propertyAnalysis.totalExpensesUserEnteredYearThree) {
        yearThreeTE = this.propertyAnalysis.totalExpensesUserEnteredYearThree;
        cmpt = cmpt + 1;
      }
    }

    if ((yearOneTE != 0) || (yearTwoTE != 0) || (yearThreeTE != 0)) {
      if (cmpt >= 2) {
        return (yearOneTE * 10) / 100 + (yearTwoTE * 20) / 100 + (yearThreeTE * 70) / 100
      }
      else {
        return yearOneTE + yearTwoTE + yearThreeTE;
      }
    } else {
      return "";
    }
  }

  getNOIValeus() {
    var yearOneNOI: number = 0;
    var yearTwoNOI: number = 0;
    var yearThreeNOI: number = 0;
    var cmpt = 0;

    if (this.propertyAnalysis.netOperatingIncomeYearOne) {
      yearOneNOI = this.propertyAnalysis.netOperatingIncomeYearOne;
    }

    if (this.propertyAnalysis.netOperatingIncomeYearTwo) {
      yearTwoNOI = this.propertyAnalysis.netOperatingIncomeYearTwo;
    }

    if (this.propertyAnalysis.netOperatingIncomeYearThree) {
      yearThreeNOI = +this.propertyAnalysis.netOperatingIncomeYearThree;
    }

    if ((yearOneNOI != 0) || (yearTwoNOI != 0) || (yearThreeNOI != 0)) {
      if (cmpt >= 2) {
        return (yearOneNOI * 10) / 100 + (yearTwoNOI * 20) / 100 + (yearThreeNOI * 70) / 100
      }
      else {
        return yearOneNOI + yearTwoNOI + yearThreeNOI;
      }
    }

    if ((yearOneNOI != 0) || (yearTwoNOI != 0) || (yearThreeNOI != 0)) {
      return (yearOneNOI * 10) / 100 + (yearTwoNOI * 20) / 100 + (yearThreeNOI * 70) / 100
    } else {
      return "";
    }
  }

  financial(x) {
    swal.fire({
      title: 'Providing operating data can significantly improve the accuracy of the valuation',
      icon: 'warning',
      showCancelButton:true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Got it !'
    })
  }

  onSave() {
    console.log(this.propertyAnalysis.zipCode);

    if (this.verifData(this.propertyAnalysis) && this.verifAdress2()) {
      if (this.valdate != this.propertyAnalysis.valuationDate) {
        const date = this.MomentDateFormatter.format(this.propertyAnalysis.valuationDate);
        this.propertyAnalysis.valuationDate = date;
        const d = new Date(this.propertyAnalysis.valuationDate);
        this.propertyAnalysis.valuationDate2 = d.getTime();
      }
      if (this.DateMost != this.propertyAnalysis.dateMostRecentSale) {
        const date2 = this.MomentDateFormatter.format(this.propertyAnalysis.dateMostRecentSale);
        this.propertyAnalysis.dateMostRecentSale = date2;
      }
      if (this.dateRecentA != this.propertyAnalysis.dateRecentAppraisal) {
        const date3 = this.MomentDateFormatter.format(this.propertyAnalysis.dateRecentAppraisal);
        this.propertyAnalysis.dateRecentAppraisal = date3;
      }
      // if(this.datelistP != this.propertyAnalysis.datelistPrice){
      //   const date4 = this.MomentDateFormatter.format(this.propertyAnalysis.datelistPrice);
      //   this.propertyAnalysis.datelistPrice = date4;
      // }
      if (this.propertyAnalysis.draft < 2) {
        this.propertyAnalysis.draft = 1;
      }
      this.propertyAnalysis.netOperatingIncome = this.getNOIValeus();
      this.propertyAnalysis.totalExpensesUserEntered = this.getTEValeus();
      this.propertyAnalysis.effectiveGrossIncome = this.getGEIValeus();
      this.propertyAnalysis.VaccancyStab = this.getVacuValeus();
      this.propertyAnalysis.address = this.mapAddress;
      // this.propertyAnalysis.createdBy = this.authService.authUser.email;
      // this.propertyAnalysis.createdByName = this.authService.authUser.displayName;
      this.analysisDataService.updateAnalysis(this.propertyAnalysis, this.idAnalysis, this.idDomaine);
      // this.router.navigate(['/list-property-analysis/myList']);
      this.analyisForm.reset();
    }
    else {
      this.next = false
    }
  }
  // getZips(zip) {
  //   // console.log("hoiniziizizizi");



  // }
  verifZipCode() {
    if (this.good === true) {
      return true
      // }
    } else {
      swal.fire({
        icon: 'error',
        title: 'The zip code is out of our scope of data used for the analysis. We cannot provide you with an automated valuation at this stage.',
        text: '',
      })
      return false;
    }

  }
  getZips(zip) {
    this.good = false;
  }
  onNext() {
    const wizard = new KTWizard(this.el.nativeElement, {
      startStep: 1
    });
    if (this.good != true) {
      if (!this.propertyAnalysis.propertyType) {
        swal.fire({
          icon: 'error',
          title: 'Property type is mandatory',
          text: '',
        });
        this.good = false;
        this.next = false;
        wizard.stop();
      } else {
        if (this.verifData(this.propertyAnalysis) && this.verifAdress2()) {
          this.good = null;
          wizard.stop();
          swal.fire({
            text: 'Checking to determine if there is sufficient relevant data for the analysis',
            title: 'Please wait....',
            timer: 2000,
            allowOutsideClick: false, 
            timerProgressBar: true,
            onBeforeOpen: () => {
              swal.showLoading();
              this.analysisDataService.getCheck_existing_properties(this.propertyAnalysis.zipCode.toString(), this.propertyAnalysis.propertyType).subscribe(list => {
                this.changeStatus2();
                if (list === 'E') {
                  this.good = false;
                  this.next = false;
                  swal.fire({
                    icon: 'error',
                    title: 'Unfortunately, we don’t have enough relevant data in the MSA related to the zip code you specified. We cannot provide you with an automated valuation at this stage.',
                    text: '',
                  });
                  wizard.stop();
                } else {
                  this.good = true;
                  if (this.valdate != this.propertyAnalysis.valuationDate) {
                    const date = this.MomentDateFormatter.format(this.propertyAnalysis.valuationDate);
                    this.propertyAnalysis.valuationDate = date;
                    const d = new Date(this.propertyAnalysis.valuationDate);
                    this.propertyAnalysis.valuationDate2 = d.getTime();
                  }
                  if (this.DateMost != this.propertyAnalysis.dateMostRecentSale) {
                    const date2 = this.MomentDateFormatter.format(this.propertyAnalysis.dateMostRecentSale);
                    this.propertyAnalysis.dateMostRecentSale = date2;
                  }
                  if (this.dateRecentA != this.propertyAnalysis.dateRecentAppraisal) {
                    const date3 = this.MomentDateFormatter.format(this.propertyAnalysis.dateRecentAppraisal);
                    this.propertyAnalysis.dateRecentAppraisal = date3;
                  }
                  if (this.propertyAnalysis.draft < 2) {
                    this.propertyAnalysis.draft = 1;
                  }
                  this.propertyAnalysis.netOperatingIncome = this.getNOIValeus();
                  this.propertyAnalysis.totalExpensesUserEntered = this.getTEValeus();
                  this.propertyAnalysis.effectiveGrossIncome = this.getGEIValeus();
                  this.propertyAnalysis.VaccancyStab = this.getVacuValeus();
                  this.propertyAnalysis.address = this.mapAddress;
                  
                  // this.propertyAnalysis.createdBy = this.authService.authUser.email;
                  // this.propertyAnalysis.createdByName = this.authService.authUser.displayName;
                  this.analysisDataService.updateAnalysis(this.propertyAnalysis, this.idAnalysis, this.idDomaine);
                  wizard.goNext();
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  });
                  // }
                  // else {
                  //   this.next = false;
                  // }
                }

              })
            }
          })
        } else {
          this.good = false;
          this.next = false;
          wizard.stop();
        }
      }
    } else {
            wizard.goNext();
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
    }
  }
  pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}
  review() {
    if (this.propertyAnalysis.review === 2) {
      swal.fire({
        title: 'Providing operating data can significantly improve the accuracy of the valuation',
        icon: 'warning',
        showCancelButton:true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Got it !'
      }).then((result) => {
       if(result.value){
        if (this.verifData(this.propertyAnalysis)) {
          const datet = this.propertyAnalysis.valuationDate;
          if (this.valdate != this.propertyAnalysis.valuationDate) {
            const date = this.MomentDateFormatter.format(this.propertyAnalysis.valuationDate);
            this.propertyAnalysis.valuationDate = date;
            const d = new Date(this.propertyAnalysis.valuationDate);
            this.propertyAnalysis.valuationDate2 = d.getTime();
          }
          if (this.DateMost != this.propertyAnalysis.dateMostRecentSale) {
            const date2 = this.MomentDateFormatter.format(this.propertyAnalysis.dateMostRecentSale);
            this.propertyAnalysis.dateMostRecentSale = date2;
          }
          if (this.dateRecentA != this.propertyAnalysis.dateRecentAppraisal) {
            const date3 = this.MomentDateFormatter.format(this.propertyAnalysis.dateRecentAppraisal);
            this.propertyAnalysis.dateRecentAppraisal = date3;
          }
          // if(this.datelistP != this.propertyAnalysis.datelistPrice){
          //   const date4 = this.MomentDateFormatter.format(this.propertyAnalysis.datelistPrice);
          //   this.propertyAnalysis.datelistPrice = date4;
          // }
          if (this.propertyAnalysis.draft < 2) {
            this.propertyAnalysis.draft = 1;
          }
          this.propertyAnalysis.netOperatingIncome = this.getNOIValeus();
          this.propertyAnalysis.totalExpensesUserEntered = this.getTEValeus();
          this.propertyAnalysis.effectiveGrossIncome = this.getGEIValeus();
          this.propertyAnalysis.VaccancyStab = this.getVacuValeus();

          this.propertyAnalysis.address = this.mapAddress;
          // this.propertyAnalysis.createdBy = this.authService.authUser.email;
          // this.propertyAnalysis.createdByName = this.authService.authUser.displayName;
          if (!this.propertyAnalysis.createdOn) { this.propertyAnalysis.createdOn = Date.now(); }
          this.analysisDataService.updateAnalysis(this.propertyAnalysis, this.idAnalysis, this.idDomaine);
          this.router.navigate(['/auth/review', this.idAnalysis, this.idDomaine], { queryParams: { valid: !this.analyisForm.invalid } });
          this.analyisForm.reset(); 
        }
        else {
          this.next = false
        }
       }

      })
    } else {
      if (this.verifData(this.propertyAnalysis)) {
        const datet = this.propertyAnalysis.valuationDate;
        if (this.valdate != this.propertyAnalysis.valuationDate) {
          const date = this.MomentDateFormatter.format(this.propertyAnalysis.valuationDate);
          this.propertyAnalysis.valuationDate = date;
          const d = new Date(this.propertyAnalysis.valuationDate);
          this.propertyAnalysis.valuationDate2 = d.getTime();
        }
        if (this.DateMost != this.propertyAnalysis.dateMostRecentSale) {
          const date2 = this.MomentDateFormatter.format(this.propertyAnalysis.dateMostRecentSale);
          this.propertyAnalysis.dateMostRecentSale = date2;
        }
        if (this.dateRecentA != this.propertyAnalysis.dateRecentAppraisal) {
          const date3 = this.MomentDateFormatter.format(this.propertyAnalysis.dateRecentAppraisal);
          this.propertyAnalysis.dateRecentAppraisal = date3;
        }
        // if(this.datelistP != this.propertyAnalysis.datelistPrice){
        //   const date4 = this.MomentDateFormatter.format(this.propertyAnalysis.datelistPrice);
        //   this.propertyAnalysis.datelistPrice = date4;
        // }
        if (this.propertyAnalysis.draft < 2) {
          this.propertyAnalysis.draft = 1;
        }
        this.propertyAnalysis.netOperatingIncome = this.getNOIValeus();
        this.propertyAnalysis.totalExpensesUserEntered = this.getTEValeus();
        this.propertyAnalysis.effectiveGrossIncome = this.getGEIValeus();
        this.propertyAnalysis.VaccancyStab = this.getVacuValeus();

        this.propertyAnalysis.address = this.mapAddress;
        // this.propertyAnalysis.createdBy = this.authService.authUser.email;
        // this.propertyAnalysis.createdByName = this.authService.authUser.displayName;
        if (!this.propertyAnalysis.createdOn) { this.propertyAnalysis.createdOn = Date.now(); }
        this.analysisDataService.updateAnalysis(this.propertyAnalysis, this.idAnalysis, this.idDomaine);
        this.router.navigate(['/auth/review', this.idAnalysis, this.idDomaine], { queryParams: { valid: !this.analyisForm.invalid } });
        this.analyisForm.reset();
      }
      else {
        this.next = false
      }

    }
  }


  onDelete() {
    // this.dialogService.openConfirmDialog('Are you sure to delete this record ?')
    // .afterClosed().subscribe(
    //   (res) =>{if(res){
    //     this.analysisDataService.deleteAnalysis(this.idAnalysis,this.idDomaine ); 
    //     this.router.navigate(['/list-property-analysis']);
    //     this.onClose();
    //                       };
    //    (error)=> this.router.navigate(['/error500'])
    // });

  }

  openDialog() {
    // let dialogRef = this._matDialog.open(NotificationDialogComponent , {
    //   panelClass:"scrumboard-card-dialog",
    //   width: '600px', 
    // });
    // dialogRef.afterClosed().subscribe(res =>{

    // })

  }

  changeStatus(): void {

    setTimeout(() => {

      this._cdr.detectChanges()
      this._cdr.markForCheck()

    }, 1500);
  }
  valdate: string;
  DateMost: any;
  dateRecentA: any;
  datelistP: any;
  ngOnDestroy() {
    this.serviceSubscription.unsubscribe();
    this.EnableFromGroupInputs(false);
  }
  serviceSubscription: any
  onGetAnalysis(id) {
    this.serviceSubscription = this.analysisDataService.getAnalysis(id, this.idDomaine).subscribe((res: PropertyAnalysisModel) => {
      if (res) {
        this.valdate = res.valuationDate;
        this.DateMost = res.dateMostRecentSale;
        this.dateRecentA = res.dateRecentAppraisal;
        this.datelistP = res.datelistPrice;
        this.propertyAnalysis = res;
        this.address = this.propertyAnalysis.address;
        this.latitude = this.propertyAnalysis.latitude;
        this.longitude = this.propertyAnalysis.longitude;
        this.propertyAnalysis.valuationDate = {
          "year": parseInt(moment(new Date()).format('YYYY')),
          "month": parseInt(moment(new Date()).format('MM')),
          "day": parseInt(moment(new Date()).format('DD'))
        }
        console.log(this.propertyAnalysis);
        // this.getZips(res.zipCode.toString());
        this.changeStatus();
      }
    }
    );


  }
  calculeYearOne() {
    if (!this.AutoTotalOperatingExpensesYearOneSwitch) {
      this.propertyAnalysis.totalExpensesUserEnteredYearOne =
        this.propertyAnalysis.payrollYearOne +
        this.propertyAnalysis.administrationYearOne +
        this.propertyAnalysis.utilitiesYearOne +
        this.propertyAnalysis.repairsMaintenanceYearOne +
        this.propertyAnalysis.cleaningJanitorialYearOne +
        this.propertyAnalysis.camYearOne +
        this.propertyAnalysis.realEstateTaxesYearOne +
        this.propertyAnalysis.insuranceYearOne +
        this.propertyAnalysis.replacementReservesYearOne
    }
    this.propertyAnalysis.netOperatingIncomeYearOne = this.propertyAnalysis.effectiveGrossIncomeYearOne - this.propertyAnalysis.totalExpensesUserEnteredYearOne
  }
  calculeTotal() {
    if (!this.propertyAnalysis.autoNET) {
      if (this.propertyAnalysis.total === false || !this.propertyAnalysis.total) {
        this.propertyAnalysis.netOperatingIncomeYearOne = this.propertyAnalysis.effectiveGrossIncomeYearOne - this.propertyAnalysis.totalExpensesUserEnteredYearOne
      } else if (this.propertyAnalysis.total === true) {
        this.propertyAnalysis.netOperatingIncomeYearOne = this.propertyAnalysis.effectiveGrossIncomeYearOne - (this.propertyAnalysis.payrollYearOne +
          this.propertyAnalysis.administrationYearOne +
          this.propertyAnalysis.utilitiesYearOne +
          this.propertyAnalysis.repairsMaintenanceYearOne +
          this.propertyAnalysis.cleaningJanitorialYearOne +
          this.propertyAnalysis.camYearOne +
          this.propertyAnalysis.realEstateTaxesYearOne +
          this.propertyAnalysis.insuranceYearOne +
          this.propertyAnalysis.replacementReservesYearOne)
      }
    }
  }
  calculeYearTwo() {
    if (this.propertyAnalysis.effectiveGrossIncomeYearTwo && this.propertyAnalysis.totalExpensesUserEnteredYearTwo) {
      this.propertyAnalysis.netOperatingIncomeYearTwo = this.propertyAnalysis.effectiveGrossIncomeYearTwo - this.propertyAnalysis.totalExpensesUserEnteredYearTwo
    } else {
      this.propertyAnalysis.totalExpensesUserEnteredYearTwo =
        this.propertyAnalysis.payrollYearTwo +
        this.propertyAnalysis.administrationYearTwo +
        this.propertyAnalysis.utilitiesYearTwo +
        this.propertyAnalysis.repairsMaintenanceYearTwo +
        this.propertyAnalysis.cleaningJanitorialYearTwo +
        this.propertyAnalysis.camYearTwo +
        this.propertyAnalysis.realEstateTaxesYearTwo +
        this.propertyAnalysis.insuranceYearTwo +
        this.propertyAnalysis.replacementReservesYearTwo
    }
  }

  calculeYearThree() {
    if (this.propertyAnalysis.effectiveGrossIncomeYearThree && this.propertyAnalysis.totalExpensesUserEnteredYearThree) {
      this.propertyAnalysis.netOperatingIncomeYearThree = this.propertyAnalysis.effectiveGrossIncomeYearThree - this.propertyAnalysis.totalExpensesUserEnteredYearThree
    } else {
      this.propertyAnalysis.totalExpensesUserEnteredYearThree =
        this.propertyAnalysis.payrollYearThree +
        this.propertyAnalysis.administrationYearThree +
        this.propertyAnalysis.utilitiesYearThree +
        this.propertyAnalysis.repairsMaintenanceYearThree +
        this.propertyAnalysis.cleaningJanitorialYearThree +
        this.propertyAnalysis.camYearThree +
        this.propertyAnalysis.realEstateTaxesYearThree +
        this.propertyAnalysis.insuranceYearThree +
        this.propertyAnalysis.replacementReservesYearThree
    }
  }





  EnableFromGroupInputs(value: boolean) {
    const year = this.form.get('year');
    const numBuild = this.form2.get('numBuild');
    const occupancyRate = this.form3.get('occupancyRate');
    const RenoYr = this.form4.get('RenoYr');
    if (value == true) {
      year.enable();
      numBuild.enable();
      occupancyRate.enable();
      RenoYr.enable();

    } else {
      year.disable();
      numBuild.disable();
      occupancyRate.disable();
      RenoYr.disable();

    }

  }

  onLeft() {
    if (this.yearfinace == 1) {
      this.yearfinace = 3;
    } else if (this.yearfinace == 3) {
      this.yearfinace = 2;
    } else
      if (this.yearfinace = 2) {
        this.yearfinace = 1;
      }
  }
  onRight() {
    if (this.yearfinace == 1) {
      this.yearfinace = 2;
    } else
      if (this.yearfinace == 2) {
        this.yearfinace = 3;
      } else
        if (this.yearfinace == 3) {
          this.yearfinace = 1;
        }


  }


  yearOneAutoCalculationTotalOperatingExpensAllExpensesOrTotalOperatingExpensesOnly() {
    this.AutoTotalOperatingExpensesYearOneGroupe = !this.AutoTotalOperatingExpensesYearOneGroupe;
    this.AutoTotalOperatingExpensesYearOne = this.AutoTotalOperatingExpensesYearOneGroupe;
    this.AutoTotalOperatingExpensesYearOneSwitch = this.AutoTotalOperatingExpensesYearOne;

  }
  Onlycalcul() {
    this.propertyAnalysis.autoEffective = !this.propertyAnalysis.autoEffective
    this.calculEffective()
  }
  Onlycalcul2() {
    this.propertyAnalysis.autoNET = !this.propertyAnalysis.autoNET
    this.calculeTotal()
  }
  YearOneChoosed() {
    this.YearOneOnCmp = this.YearOneOnCmp + 1;
    if (this.YearOneOnCmp % 2 == 0) {
      this.AutoTotalOperatingExpensesYearOne = !this.AutoTotalOperatingExpensesYearOneGroupe;
      this.AutoTotalOperatingExpensesYearOneGroupe = true;
      this.AutoTotalOperatingExpensesYearOneSwitch = true;
    } else {
      this.AutoTotalOperatingExpensesYearOneGroupe = false;
      this.AutoTotalOperatingExpensesYearOne = false;
      this.AutoTotalOperatingExpensesYearOneSwitch = false;
    }
  }

  YearOneAuto2() {
    this.onOrOffCmp2YearOne = this.onOrOffCmp2YearOne + 1;
    if (this.onOrOffCmp2YearOne % 2 == 0) {
      this.yearOneAuto2 = false;
    } else {
      this.yearOneAuto2 = true;
    }
    this.calculeYearOne();
  }

  calculEffective() {
    if ((this.propertyAnalysis.potentialGrossIncome || this.propertyAnalysis.VaccancyStabOne) && !this.propertyAnalysis.autoEffective) {
      this.propertyAnalysis.effectiveGrossIncomeYearOne = ((1 - (this.propertyAnalysis.VaccancyStabOne / 100)) * this.propertyAnalysis.potentialGrossIncome)
    }
  }



  yearTwoAutoCalculationTotalOperatingExpensAllExpensesOrTotalOperatingExpensesOnly() {
    this.AutoTotalOperatingExpensesYearTwoGroupe = !this.AutoTotalOperatingExpensesYearTwoGroupe;
    this.AutoTotalOperatingExpensesYearTwo = this.AutoTotalOperatingExpensesYearTwoGroupe;
    this.AutoTotalOperatingExpensesYearTwoSwitch = this.AutoTotalOperatingExpensesYearTwo;

  }

  YearTwoChoosed() {
    this.YearTwoOnCmp = this.YearTwoOnCmp + 1;
    if (this.YearTwoOnCmp % 2 == 0) {
      this.AutoTotalOperatingExpensesYearTwo = !this.AutoTotalOperatingExpensesYearTwoGroupe;
      this.AutoTotalOperatingExpensesYearTwoGroupe = true;
      this.AutoTotalOperatingExpensesYearTwoSwitch = true;
    } else {
      this.AutoTotalOperatingExpensesYearTwoGroupe = false;
      this.AutoTotalOperatingExpensesYearTwo = false;
      this.AutoTotalOperatingExpensesYearTwoSwitch = false;
    }
  }

  YearTwoAuto2() {
    this.onOrOffCmp2YearTwo = this.onOrOffCmp2YearTwo + 1;
    if (this.onOrOffCmp2YearTwo % 2 == 0) {
      this.yearTwoAuto2 = false;
    } else {
      this.yearTwoAuto2 = true;
    }
    this.calculeYearTwo();
  }

  yearThreeAutoCalculationTotalOperatingExpensAllExpensesOrTotalOperatingExpensesOnly() {
    this.AutoTotalOperatingExpensesYearThreeGroupe = !this.AutoTotalOperatingExpensesYearThreeGroupe;
    this.AutoTotalOperatingExpensesYearThree = this.AutoTotalOperatingExpensesYearThreeGroupe;
    this.AutoTotalOperatingExpensesYearThreeSwitch = this.AutoTotalOperatingExpensesYearThree;

  }

  YearThreeChoosed() {
    this.YearThreeOnCmp = this.YearThreeOnCmp + 1;
    if (this.YearThreeOnCmp % 2 == 0) {
      this.AutoTotalOperatingExpensesYearThree = !this.AutoTotalOperatingExpensesYearThreeGroupe;
      this.AutoTotalOperatingExpensesYearThreeGroupe = true;
      this.AutoTotalOperatingExpensesYearThreeSwitch = true;
    } else {
      this.AutoTotalOperatingExpensesYearThreeGroupe = false;
      this.AutoTotalOperatingExpensesYearThree = false;
      this.AutoTotalOperatingExpensesYearThreeSwitch = false;
    }
  }

  YearThreeAuto2() {
    this.onOrOffCmp2YearThree = this.onOrOffCmp2YearThree + 1;
    if (this.onOrOffCmp2YearThree % 2 == 0) {
      this.yearThreeAuto2 = false;
    } else {
      this.yearThreeAuto2 = true;
    }
    this.calculeYearThree();
  }

  verifAdress() {
    console.log(this.propertyAnalysis.latitude);
    console.log(this.propertyAnalysis.longitude);
    console.log(this.latitude);
    console.log(this.longitude);
    console.log(this.mapAddress);

    this.setCurrentPosition();
this.changeStatus();
    console.log(this.propertyAnalysis.latitude);
    console.log(this.propertyAnalysis.longitude);
    console.log(this.latitude);
    console.log(this.longitude);
    console.log(this.mapAddress);

    if (this.propertyAnalysis.latitude == 0 || this.propertyAnalysis.longitude == 0 || !this.mapAddress) {
      this.addressOk = false;
    } else {
      this.addressOk = true;
    }
    return this.addressOk;
  }

  verifAdress2() {
    if (this.propertyAnalysis.latitude == 0 && this.propertyAnalysis.longitude == 0) {
      this.addressOk = false;
    } else {
      this.addressOk = true;
    }
    return this.addressOk;
  }
}
