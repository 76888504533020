import { AbstractControl } from '@angular/forms';

export class ConfirmPasswordValidator {
	/**
	 * Check matching password with confirm password
	 * @param control AbstractControl
	 */
	static MatchPassword(control: AbstractControl) {
		const password = control.get('password').value;

		const confirmPassword = control.get('confirmPassword').value;

		const email = control.get('email').value;

		const confirmemail = control.get('confirmemail').value;
		if (email !== confirmemail) {
			control.get('confirmemail').setErrors({ConfirmEmail: true});
		} else {
			return null;
		}
		if (password !== confirmPassword) {
			control.get('confirmPassword').setErrors({ConfirmPassword: true});
		} else {
			return null;
		}

		
	}
	/**
	 * Check matching password with confirm password
	 * @param control AbstractControl
	 */
	static MatchPassword2(control: AbstractControl) {
	

		
	}
}
