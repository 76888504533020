// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';
import {ErrorPageComponent} from './views/theme/content/error-page/error-page.component';
// Auth
import {AuthGuard} from './core/auth';
import { ErrorComponent } from './views/partials/content/general/error/error.component';
import { UnderConstructionComponent } from './views/pages/auth/under-construction/under-construction.component';

const routes: Routes = [
	{path: 'auth', loadChildren: () => import('../app/views/pages/auth/auth.module').then(m => m.AuthModule)},

	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'userManagment',
				loadChildren: () => import('../app/views/pages/user-management/user-management.module').then(u => u.UserManagementModule),
			},
			{
				path: 'list-property-analysis',
				loadChildren: () => import('../app/views/pages/property-analysis/property-analysis.module').then(p => p.PropertyAnalysisModule),
			},
			{
				path: 'profile',
				loadChildren: () => import('../app/views/pages/profile/profile.module').then(pf => pf.ProfileModule),
			},
			{
				path: 'payment',
				loadChildren: () => import('../app/views/pages/property-analysis/payment-settings/payment-settings.module').then(pf => pf.PaymentSettingsModule),
			},
			{
				path: 'transaction',
				loadChildren: () => import('../app/views/pages/property-analysis/transaction/transaction.module').then(pf => pf.TransactionModule),
			},
			// {
			// 	path: 'auth/underConstrunctionPage',
			// 	component: UnderConstructionComponent,
			// },
			{path: '', redirectTo: 'list-property-analysis/list', pathMatch: 'full'},
			{path: '**', redirectTo: 'auth/underConstrunctionPage', pathMatch: 'full'},
		],
	},

	{path: '**', redirectTo: 'auth/underConstrunctionPage', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
