// Angular
import {Component} from '@angular/core';
// Layout
import {LayoutConfigService, OffcanvasOptions} from '../../../../core/_base/layout';
import { MatDialog } from '@angular/material';
import { FeedbackComponent } from './feedback/feedback.component';

@Component({
	selector: 'kt-sticky-toolbar',
	templateUrl: './sticky-toolbar.component.html',
	styleUrls: ['./sticky-toolbar.component.scss'],
})
export class StickyToolbarComponent {
	// Public properties
	demoPanelOptions: OffcanvasOptions = {
		overlay: true,
		baseClass: 'kt-demo-panel',
		closeBy: 'kt_demo_panel_close',
		toggleBy: 'kt_demo_panel_toggle',
	};

	baseHref: string;

	constructor(private layoutConfigService: LayoutConfigService, public dialog: MatDialog,) {
		this.baseHref = 'https://keenthemes.com/metronic/preview/angular/';
	}
	feedback(){
		let dialogRef = this.dialog.open(FeedbackComponent, {
			width: '40%',
			height:'39%'
		});

		dialogRef.afterClosed().subscribe(result => {
		});
	}
	isActiveDemo(demo) {
		return demo === this.layoutConfigService.getConfig('demo');
	}
}
