import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { TransactioRedModel } from '../_models/TransactioRedModel';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { AuthService } from '../../auth';

const API_TransactioRed_URL = 'api/TransactioReds';

@Injectable()
export class TransactioRedsService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService
		, private firestore: AngularFirestore, private authService: AuthService, ) { }
	createTransactioRed(TransactioRed: TransactioRedModel): Observable<TransactioRedModel> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<TransactioRedModel>(API_TransactioRed_URL, TransactioRed, { headers: httpHeaders });
	}

	getAnalysis($key, idNameSpace): Observable<any> {
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection<TransactioRedModel>("analysis").doc($key).valueChanges();;
	}

	updateScore(id, idNameSpace, scoreParam) {
		const data = {
			estimateScore: scoreParam
		}
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true })

	}
	updateDraft(id, idNameSpace) {
		const data = {
			draft: 4
		}   
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true })

	}

	creatQuestionaire(rep, rep3, rep4, rep5,createdBy,date) {
		const questionnaire = {
			Ques1: 'Was the application easy to navigate',
			Ques2: 'What is your connection to the commercial real estate industry?',
			Ques3: 'What is the likelyhood of using Valnow in the future ',
			Ques4: 'Do you have any recommendations to improve the application or the reporting?',
			email:createdBy,
			date:date,
			Rep1: rep,
			Rep2: rep3,
			Rep3: rep4,
			Rep4: rep5,
			Type: 'evaluation',
		}
		return new Promise<any>((resolve, reject) => { 
			this.firestore.collection("questionnaire").add(JSON.parse(JSON.stringify(questionnaire)));
		});
	}
	creatQuestionaire2(rep, rep2, rep3,createdBy,date) {
		const questionnaire = {
			Ques2: '1. What is the likelyhood of using Valnow in the future',
			Ques1: '2. Do you consider the pricing structure for an instant valuation listed below to be too low, about right, too high?',
			Ques3: '3. What is needed for your organtization to incorporate Valnow into you regular business operations?',
			Ques4: '',
			Ques5: '', 
			email:createdBy,
			date:date,
			Rep: rep,
			Rep2: rep2,
			Rep3: rep3,
			Rep4: '',
			Rep5: '',
			Type: 'questionaire',
		}
		return new Promise<any>((resolve, reject) => {
			this.firestore.collection("questionnaire").add(JSON.parse(JSON.stringify(questionnaire)));
		});
	}
	getValuationPredCap(analysis: TransactioRedModel) {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post("https://prod-valorisation-valbridge.appspot.com/predcap",
			{
				"ExpensesDetails": analysis.ExpensesDetails,
				"LoadFactor": analysis.LoadFactor,
				"actualValue": analysis.actualValue,
				"city": analysis.city,
				"clearHeight": analysis.clearHeight,
				"constructionQuality": "",
				"createdBy": analysis.createdBy,
				"createdByName": analysis.createdByName,
				"dateMostRecentSale": analysis.dateMostRecentSale,
				"draft": analysis.draft,
				"effectiveGrossIncome": analysis.effectiveGrossIncome,
				"effectiveGrossIncomeYearOne": analysis.effectiveGrossIncomeYearOne,
				"flagAutoCalculeNetOperatingIncomeYearOne": analysis.flagAutoCalculeNetOperatingIncomeYearOne,
				"flagAutoCalculeNetOperatingIncomeYearThree": analysis.flagAutoCalculeNetOperatingIncomeYearThree,
				"flagAutoCalculeNetOperatingIncomeYearTwo": analysis.flagAutoCalculeNetOperatingIncomeYearTwo,
				"grossBuildingArea": analysis.grossBuildingArea,
				"landAreaSF": analysis.landAreaSF,
				"latitude": analysis.latitude,
				"longitude": analysis.longitude,
				"netOperatingIncome": analysis.netOperatingIncome,
				"netRentableArea": analysis.netRentableArea,
				"numberBuildings": analysis.numberBuildings,
				"numberElevators": analysis.numberElevators,
				"numberOnSiteParkingSpaces": analysis.numberOnSiteParkingSpaces,
				"numberStories": analysis.numberStories,
				"numberUnits": analysis.numberUnits,
				"occupancyRate": analysis.occupancyRate,
				"percentAC": analysis.percentAC,
				"propertyCondition": analysis.propertyCondition,
				"propertyName": analysis.propertyName,
				"propertySubType": analysis.propertySubType,
				"propertyType": analysis.propertyType,
				"totalExpensesUserEntered": analysis.totalExpensesUserEntered,
				"totalNumberOverheadDoors": analysis.totalNumberOverheadDoors,
				"typeConstruction": analysis.typeConstruction,
				"valuationDate": analysis.valuationDate,
				"warehouseSquareFootage": analysis.warehouseSquareFootage,
				"yearConstruction": analysis.yearConstruction,
				"MostRecentRenovationDate": analysis.MostRecentRenovationDate,
				"fireSprinkler": "",
				"zipCode": analysis.zipCode
			}, { headers: headers })
	}
	getTransactioRedById(TransactioRedId: number): Observable<TransactioRedModel> {
		return this.http.get<TransactioRedModel>(API_TransactioRed_URL + `/${TransactioRedId}`);
	}
	eid: string;
	findTransactioReds(idNameSpace): Observable<any> {
		console.log(idNameSpace);



		// getEvents(chi) {       

		// 	return  this.firestore.collection('enterprises').doc(chi).collection("all_events", ref => {
		// 	  return ref.orderBy("date_hours", "desc");
		//   } ).valueChanges();
		//    } 

		// .doc(`/entreprises/${idNameSpace}`)
		// return this.firestore.collection<any>('zipcode', ref => ref.where('zipcode', '==', +x))
		// .valueChanges()
		return this.firestore.collection<TransactioRedModel>("transaction", ref => {
			return ref.where('ide', '==', idNameSpace);
			// .orderBy("date", "desc")
		}).valueChanges();
	}

	getTrasaction(idNameSpace): Observable<any> {
		return this.firestore.collection<TransactioRedModel>("transaction", ref => {
			return ref.where('ide', '==', idNameSpace).where("credit", "==", -1);
		}).valueChanges();
	}

	updateTransactioRed(TransactioRed: TransactioRedModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_TransactioRed_URL, TransactioRed, { headers: httpHeader });
	}
	updateAnalysis(analysis: TransactioRedModel, id, idNameSpace) {
		return new Promise<any>((resolve, reject) => {

			return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(analysis)), { merge: true });
		});
	}
	updateCredit(transaction, id) {
		const data = {
			credit: transaction
		}
		return this.firestore.collection("Domaine").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
	}
	grossBuildingArea: any;
	landAreaSF: any;
	effectiveGrossIncome: any; totalExpensesUserEntered: any;
	netOperatingIncome: any;
	VaccancyStab: any;
	getvaluation(analysis: TransactioRedModel): Observable<any> {
		if (!analysis.grossBuildingArea) {
			this.grossBuildingArea = "";
		} else {
			this.grossBuildingArea = +analysis.grossBuildingArea;
		}
		if (!analysis.landAreaSF) {
			this.landAreaSF = "";
		} else {
			this.landAreaSF = +analysis.landAreaSF;
		}
		if (!analysis.effectiveGrossIncomeYearOne) {
			this.effectiveGrossIncome = "";
		} else {
			this.effectiveGrossIncome = +analysis.effectiveGrossIncomeYearOne;
		}
		if (!analysis.totalExpensesUserEnteredYearOne) {
			this.totalExpensesUserEntered = "";
		} else {
			this.totalExpensesUserEntered = +analysis.totalExpensesUserEnteredYearOne;
		}
		if (!analysis.netOperatingIncomeYearOne) {
			this.netOperatingIncome = "";
		} else {
			this.netOperatingIncome = +analysis.netOperatingIncomeYearOne;
		}
		if (!analysis.VaccancyStabOne) {
			this.VaccancyStab = "";
		} else {
			this.VaccancyStab = +analysis.VaccancyStabOne;
		}
		console.log({
			"valuationDate": analysis.valuationDate,
			"PropertyType": analysis.propertyType,
			"Subtype": analysis.propertySubType,
			"us_zipcode": +analysis.zipCode,
			"city": analysis.city,
			"state_id": analysis.state,
			"Longitude_y": analysis.longitude,
			"Latitude_y": analysis.latitude,

			"Gross Building Area (GBA)": this.grossBuildingArea,
			"Rentable Area - NRA": +analysis.netRentableArea,
			"Land Area (SF)": this.landAreaSF,
			"Buildings": analysis.numberBuildings,
			"Stories": +analysis.numberStories,
			"BuildingCondition": analysis.propertyCondition,
			"Total Number of Parking Spaces": analysis.numberOnSiteParkingSpaces,
			"Occupancy Rate as of Effective Date of Value": analysis.occupancyRate,
			"No of Elevators": analysis.numberElevators,
			"Fire Sprinkler": "",
			"EGIStab": this.effectiveGrossIncome,
			"ExpStab": this.totalExpensesUserEntered,
			"NOIStab": this.netOperatingIncome,
			"VacancyStab": this.VaccancyStab,
			"YOC": analysis.yearConstruction,
			"RenoYr": analysis.RenoYr
		});
		// https://vbridge-ml-prod-dot-prod-valorisation-valbridge.appspot.com/predict
		return this.http.post("https://valnow-ml20-ko5and6o3q-uc.a.run.app/predict/v2/",
			{
				"valuationDate": analysis.valuationDate,
				"PropertyType": analysis.propertyType,
				"Subtype": analysis.propertySubType,
				"us_zipcode": +analysis.zipCode,
				"city": analysis.city,
				"state_id": analysis.state,
				"Longitude_y": analysis.longitude,
				"Latitude_y": analysis.latitude,

				"Gross Building Area (GBA)": this.grossBuildingArea,
				"Rentable Area - NRA": +analysis.netRentableArea,
				"Land Area (SF)": this.landAreaSF,
				"Buildings": analysis.numberBuildings,
				"Stories": +analysis.numberStories,
				"BuildingCondition": analysis.propertyCondition,
				"Total Number of Parking Spaces": analysis.numberOnSiteParkingSpaces,
				"Occupancy Rate as of Effective Date of Value": analysis.occupancyRate,
				"No of Elevators": analysis.numberElevators,
				"Fire Sprinkler": "",
				"EGIStab": this.effectiveGrossIncome,
				"ExpStab": this.totalExpensesUserEntered,
				"NOIStab": this.netOperatingIncome,
				"VacancyStab": this.VaccancyStab,
				"YOC": analysis.yearConstruction,
				"RenoYr": analysis.RenoYr
			})
	}
	updateStatusForTransactioRed(TransactioReds: TransactioRedModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			TransactioRedsForUpdate: TransactioReds,
			newStatus: status
		};
		const url = API_TransactioRed_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}
	updateEstimateHigh(id, idNameSpace, estimatValueParam) {
		const data = {
			highest: estimatValueParam
		}
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true })

	}
	updateEstimateLow(id, idNameSpace, estimatValueParam) {
		const data = {
			lowest: estimatValueParam
		}
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true })

	}
	deleteTransactioRed(MyListId: string, idNameSpace: string): Promise<void> {
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(MyListId).delete();

	}
	getZipCodeVerif(x) {
		// return this.firestore.collection<any>("zipcode").valueChanges();
		return this.firestore.collection<any>('zipcode', ref => ref.where('zipcode', '==', x))
			.valueChanges()
	}
	creatAnalysis(analysis: TransactioRedModel, idNameSpace, id) {
		var prom = new Promise<any>((resolve, reject) => {
			analysis.iduser = id;
			resolve(id);
			analysis.uid = this.authService.userId;
			this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(analysis)), { merge: true })
		});
		return prom;

	}
	deleteTransactioReds(ids: number[] = []): Observable<any> {
		const url = API_TransactioRed_URL + '/deleteTransactioReds';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { TransactioRedIdsForDelete: ids };
		return this.http.put<any>(url, body, { headers: httpHeaders });
	}
}
