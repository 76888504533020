import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap, map, tap, delay } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryRealtimeParamsModel } from '../../_base/crud';
import { PropertyAnalysissService } from '../_services';
import { AppState } from '../../reducers';
import {

    PropertyAnalysisActionToggleLoading,
    PropertyAnalysisActionTypes,
    PropertyAnalysissPageLoaded,
    PropertyAnalysissPageRequested,
    PropertyAnalysissPageToggleLoading,
    OnePropertyAnalysisDeleted,
    PropertyAnalysisOnServerCreated,
    PropertyAnalysisCreated

} from '../_actions/PropertyAnalysis.actions';
import { of } from 'rxjs';

@Injectable()
export class PropertyAnalysisEffects {
    showPageLoadingDistpatcher = new PropertyAnalysissPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new PropertyAnalysisActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new PropertyAnalysisActionToggleLoading({ isLoading: false });
    @Effect()
    loadPropertyAnalysissPage$ = this.actions$.pipe(
        ofType<PropertyAnalysissPageRequested>(PropertyAnalysisActionTypes.PropertyAnalysissPageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            return this.PropertyAnalysissService.findPropertyAnalysiss(payload.list);

        }),
        map(response => {
            const result: QueryRealtimeParamsModel = response;
            const lastQuery: QueryParamsModel = response[1];
            const pageLoadedDispatch = new PropertyAnalysissPageLoaded({
                PropertyAnalysiss: result
            });
            return pageLoadedDispatch;
        })
    );
    @Effect()
    createUser$ = this.actions$
        .pipe(
            ofType<PropertyAnalysisOnServerCreated>(PropertyAnalysisActionTypes.PropertyAnalysisOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.PropertyAnalysissService.creatAnalysis(payload.PropertyAnalysis,payload.id,payload.id).then(
                    tap(res => {
                        this.store.dispatch(new PropertyAnalysisCreated({ PropertyAnalysis: res }));
                    })
                );
            }), 
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );
    @Effect()
    deleteCustomer$ = this.actions$
        .pipe(
            ofType<OnePropertyAnalysisDeleted>(PropertyAnalysisActionTypes.OnePropertyAnalysisDeleted),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.PropertyAnalysissService.deletePropertyAnalysis(payload.id,payload.idNameSpace);
            }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private PropertyAnalysissService: PropertyAnalysissService, private store: Store<AppState>) { }

}
