import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { PropertyDetailActions, PropertyDetailActionTypes } from '../_actions/PropertyDetail.actions';
import { QueryParamsModel } from '../../_base/crud';
import { PropertyAnalysisModel } from '../_models/PropertyAnalysisModel';

export interface PropertyDetailState extends EntityState<PropertyAnalysisModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedPropertyDetailId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<PropertyAnalysisModel> = createEntityAdapter<PropertyAnalysisModel>();

export const initialPropertyDetailState: PropertyDetailState = adapter.getInitialState({
    PropertyDetailForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedPropertyDetailId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function PropertyDetailsReducer(state = initialPropertyDetailState, action: PropertyDetailActions): PropertyDetailState {
    switch  (action.type) {
        case PropertyDetailActionTypes.PropertyDetailsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedPropertyDetailId: undefined
            };
        }    

        case PropertyDetailActionTypes.PropertyDetailActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }

        case PropertyDetailActionTypes.PropertyDetailOnServerCreated: return {
            ...state
        };

        case PropertyDetailActionTypes.PropertyDetailUpdated: return adapter.updateOne(action.payload.partialPropertyDetail, state);

        case PropertyDetailActionTypes.OnePropertyDetailDeleted: return adapter.removeOne(action.payload.id, state);

        case PropertyDetailActionTypes.PropertyDetailsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }

        case PropertyDetailActionTypes.PropertyDetailsPageLoaded: {
            return adapter.addOne(action.payload.PropertyDetails, {
                ...initialPropertyDetailState,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}



export const getPropertyDetailState = createFeatureSelector<PropertyAnalysisModel>('PropertyDetails');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
