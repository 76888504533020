// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Module reducers and selectors
import { AppState} from '../../../core/reducers/';
import { currentUserPermissions } from '../_selectors/auth.selectors';
import { Permission } from '../_models/permission.model';
import { find } from 'lodash';
import { AuthService } from '../_services/auth.service';

@Injectable()
export class ModuleGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router ) {}

    canActivate(next, state): Observable<boolean> {
      return this.auth.user$.pipe(
        take(1),
        map(user => !!(user && user.emailVerified === true)), // <-- map to boolean
        tap(loggedIn => { 
            if (!loggedIn) {  
                 this.router.navigate(['/auth/login']); 
                 console.log("access denied")
             }else {    
            console.log("welcome to app")
              }
       })
      );
    }
}
