import { Component, OnInit } from '@angular/core';
import { AuthNoticeService, AuthService, Login } from '../../../../core/auth';

@Component({
  selector: 'kt-mail-confirmation',
  templateUrl: './mail-confirmation.component.html',
  styleUrls: ['./mail-confirmation.component.scss']
})
export class MailConfirmationComponent implements OnInit {

  constructor(private auth: AuthService,
    ) { }

  ngOnInit() {
  }

}
