import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../../../core/auth/_services/auth.service';
import { Domaine } from '../../../../../core/auth/_models/Domaine.model';

@Component({
  selector: 'kt-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  email: string;
  msg: string;
  DomaineCurrent: Domaine;
  name: any;

  constructor(public dialogRef: MatDialogRef<FeedbackComponent>, private http: HttpClient, private _cdr: ChangeDetectorRef, private authService: AuthService,
    public snackBar: MatSnackBar

  ) { }
  x: string;
  ngOnInit() {
    this.authService.user$.subscribe((res) => {

      if (res) {
        this.authService.getBusinessName(res.idDomaine).subscribe(list => {
          if (list) {
            this.DomaineCurrent = list;
          }
          // this.changeStatus();
        });
      }
    });
    this.authService.user$.subscribe(res => {
      if (res) {
        this.name = res.displayName;
        this.email = res.email;
      }

    });

  }
  changeStatus(): void {

    setTimeout(() => {

      this._cdr.detectChanges()
      this._cdr.markForCheck()

    }, 1500);
  }
  close() {
    if (!this.x) {
      this.snackBar.open('Please type your feedback', '', {
        verticalPosition: 'top',
        duration: 4000,
        panelClass: ['error-toaster']
      });
      this.msg = "Please type your feedback";
    } else {
      this.http.post("https://us-central1-prodvalbridge.cloudfunctions.net/send_feedback",
        {
          email: this.email,
          name: this.email,
          Cname: this.DomaineCurrent.businessName,
          Caddress: this.DomaineCurrent.address,
          phone: this.DomaineCurrent.contactPhone,
          feed: this.x
        }).subscribe(res => {
          // console.log(res);
        })
      this.dialogRef.close();
    }
  }

  submit() {

  }
}
