import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { QueryRealtimeParamsModel, HttpExtenstionsModel } from '../../_base/crud';
import { PropertyAnalysisModel } from '../_models/PropertyAnalysisModel';
import { PropertyAnalysisState } from '../_reducers/PropertyAnalysis.reducers';

export const selectPropertyAnalysissState = createFeatureSelector<PropertyAnalysisState>('PropertyAnalysis');

export const selectPropertyAnalysisById = (PropertyAnalysisId: number) => createSelector(
    selectPropertyAnalysissState,
    PropertyAnalysisState => PropertyAnalysisState.entities[PropertyAnalysisId]
);

export const selectPropertyAnalysissPageLoading = createSelector(
    selectPropertyAnalysissState,
    PropertyAnalysisState => PropertyAnalysisState.listLoading
);

export const selectPropertyAnalysissActionLoading = createSelector(
    selectPropertyAnalysissState,
    PropertyAnalysisState => PropertyAnalysisState.actionsloading
);

export const selectLastCreatedPropertyAnalysisId = createSelector(
    selectPropertyAnalysissState,
    PropertyAnalysisState => PropertyAnalysisState.lastCreatedPropertyAnalysisId
);

export const selectPropertyAnalysissShowInitWaitingMessage = createSelector(
    selectPropertyAnalysissState,
    PropertyAnalysisState => PropertyAnalysisState.showInitWaitingMessage
);

export const selectPropertyAnalysissInStore = createSelector(
    selectPropertyAnalysissState,
    PropertyAnalysisState => {
        const items: PropertyAnalysisModel[] = [];
        each(PropertyAnalysisState.entities, element => {
            items.push(element);
        });        
        const httpExtension = new HttpExtenstionsModel();
        const result: PropertyAnalysisModel[] = httpExtension.sortArray(items, PropertyAnalysisState.lastQuery.sortField, PropertyAnalysisState.lastQuery.sortOrder);
        return new QueryRealtimeParamsModel(result);
    }
);

