import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { catchError, map, switchMap } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material';
import { User2 } from '../_models/User2.model';
import { Domaine } from '../_models/Domaine.model';
import { UsermanagementModel } from '../../user-management/_models/UsermanagementModel';
import * as firebase from 'firebase';

@Injectable()
export class AuthService {
  typeLogin: string = "";
  user$: Observable<any>;
  pop: number = 0;
  data: { firstpop: number; };
  constructor(private http: HttpClient,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public ngZone: NgZone,
    private router: Router,
    private snackBar: MatSnackBar) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }
  // Authentication/Authorization
  async login(email: string, password: string) {
    this.typeLogin = "emailPass";
    return await this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }
  async signOut() {
    await this.afAuth.auth.signOut();
    return this.router.navigate(['/auth/login']);
  }
  ifexist(idNameSpace, email): Observable<any> {
    const Ref = this.afs.collection<UsermanagementModel>("users", ref => ref.where('idDomaine', '==', idNameSpace).where('email', '==', email));
    return Ref.valueChanges();
  }
  getBusinessName(idDomaine) {
    if (idDomaine) {
      return this.afs.collection<Domaine>('Domaine').doc(idDomaine).valueChanges()

    } else {
      return of(null)
    }

  }
  register(user: User): Promise<any> {
    return this.afAuth.auth.createUserWithEmailAndPassword(user.email, user.password)
  }
  async googleSignin() {
    const provider = new auth.GoogleAuthProvider();
    const credential = await this.afAuth.auth.signInWithPopup(provider);
    this.typeLogin = "google";
    await this.updateUserData(credential.user, true);
    this.plus1(credential.user);
    return this.router.navigate(['/']);
  }
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
      .then(() => {
        this.router.navigate(['/auth/mail-confirmation']);
      })
  }
  emailVerified: boolean;
  async updateUserData(user, verif) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User2> = this.afs.doc(`/users/${user.uid}`);
    //for facebook if one day needed
    // let verif: boolean; 
    // (this.typeLogin == "facebook") ? verif = true : verif = user.emailVerified;
    // const membreRef: AngularFirestoreDocument<User> = this.afs.doc(`/entreprises/${this.nameSpace}`)
    // .collection("membres").doc(user.uid);  
    (user.notification == null) ? user.notification = false : user.notification = user.notification;
    // if (this.typeLogin === 'google') {
    //   this.emailVerified = true;
    // }else{
    //   this.emailVerified = false;

    // }
    const data = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: verif,
      type: this.typeLogin,
      // firstpop:+1,
      notification: user.notification
    };
    if (user.idDomaine) {
      await this.updateMembreData(data);
    }
    
    return userRef.set(data, { merge: true });

  }
  // getuser(user) { return this.afs.doc<User>(`users/${user.uid}`).valueChanges(); }
  plus1(user) {
    const userRef = this.afs.collection('users').doc(user.uid);
    const increaseBy = firebase.firestore.FieldValue.increment(+1);
console.log(increaseBy);

    return userRef.update({ firstpop: increaseBy });
    // return firebase.database()
    // .ref('users')
    // .child(user.uid)
    // .child('firstpop')
    // .set(firebase.firestore.FieldValue.increment(+1))
    // this.getuser(user).pipe((res: any) => {
    //   console.log(res.firstpop);

    //   this.data = {
    //     firstpop:res.firstpop+1
    //   }
    //   console.log(this.data);

    //   return this.afs.doc(`/users/${user.uid}`).set(JSON.parse(JSON.stringify(this.data)), { merge: true })
    // })
  }
  updateMembreData(data) {
    const membreRef: AngularFirestoreDocument<User> = this.afs.doc(`/entreprises/${data.idDomaine}`)
      .collection("members").doc(data.uid);
    return membreRef.set(data, { merge: true });
  }
  get Logintype(): string {
    return this.typeLogin;
  }
  //register login pass ok
  /*
   * Submit forgot password request
   *
   * @param {string} email
   * @returns {Observable<any>}
   */
  public requestPassword(email: string): Promise<void> {
    return this.afAuth.auth.sendPasswordResetEmail(email)
  }
  ForgotPassword(mail) {
    return this.afAuth.auth.sendPasswordResetEmail(mail)
      .then(() => {
        this.snackBar.open("Password reset email sent, check your inbox.", "check your email", {
          duration: 4000,
        });

      }).catch((error) => {
        window.alert(error)
      })
  }
  get authUser() {
    let user: any;
    user = {
      uid: this.afAuth.auth.currentUser.uid, email: this.afAuth.auth.currentUser.email
      , displayName: this.afAuth.auth.currentUser.displayName,
    };
    return user;
  }
  getVoucher(idDom) {
    return this.afs.doc(`/Domaine/${idDom}`).valueChanges();
  }
  async creatDomaine(domaine, id, date) {

    return await new Promise<any>(async (resolve, reject) => {
      // const id = this.afs.createId();
      const data = {
        idDomaine: id,
        uidOwner: this.userId,
        businessName: domaine.businessName,
        address: domaine.address,
        state: domaine.state,
        zipCode: domaine.zipCode,
        city: domaine.city,
        contactEmail: domaine.contactEmail,
        contactPhone: domaine.contactPhone,
        // businessSize  : domaine.businessSize, 
        businessType: domaine.businessType,
        credit: 1,
        voucher: "",
        date: date,
        status:'ACTIVE',
        product_Type :[
          'Market Trends Analysis',
          'Market Data Analysis','Valnow Valuation'
        ]
      }
      const dataEntreprise = {
        idDomaine: id,
        uidOwner: this.userId,
      }

      const dataUser = {
        idDomaine: id,
        uidOwner: this.userId,
        validated: true,
        role: "admin"
      }
      console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
      console.log(data);

      await this.afs.collection("Domaine").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
      await this.afs.collection("users").doc(this.userId).set(JSON.parse(JSON.stringify(dataUser)), { merge: true });
      await this.afs.collection("entreprises").doc(id).collection("members").doc(this.userId).set(Object.assign({}, dataEntreprise), { merge: true });
      await this.router.navigate(["/"]);
    });

  }
  updateDomaine(domaine, idDom,uid) {
    const data = {
      businessName: domaine.businessName,
      address: domaine.address,
      state: domaine.state,
      zipCode: domaine.zipCode,
      city: domaine.city,
      contactEmail: domaine.contactEmail,
      contactPhone: domaine.contactPhone,
      businessType: domaine.businessType,
      product_Type: domaine.product_Type,
    }
    const x ={
      displayName:domaine.displayName
    }
      this.afs.collection("users").doc(uid).set(JSON.parse(JSON.stringify(x)), { merge: true })
    return this.afs.collection("Domaine").doc(idDom).set(JSON.parse(JSON.stringify(data)), { merge: true });

  }
  async creatDomaine2(domaine, id, date, user) {

    return await new Promise<any>(async (resolve, reject) => {
      const data = {
        idDomaine: id,
        uidOwner: this.userId,
        businessName: "",
        address: "",
        state: "",
        zipCode: "",
        city: "",
        contactEmail: user,
        contactPhone: "",
        // businessSize  : domaine.businessSize, 
        businessType: "",
        credit: 1,
        voucher: "",
        status:'ACTIVE',
        date: date,
        product_Type :[
          'Market Trends Analysis',
          'Market Data Analysis','Valnow Valuation'
        ]
      }
      const dataEntreprise = {
        idDomaine: id,
        uidOwner: this.userId,
      }

      const dataUser = {
        idDomaine: id,
        uidOwner: this.userId,
        validated: true,
        role: "admin"
      }

      console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
      console.log(data);
      await this.afs.collection("Domaine").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
      await this.afs.collection("users").doc(this.userId).set(JSON.parse(JSON.stringify(dataUser)), { merge: true });
      await this.afs.collection("entreprises").doc(id).collection("members").doc(this.userId).set(Object.assign({}, dataEntreprise), { merge: true });
      await this.router.navigate(["/"]);
    });

  }
  updateFranchisee(id, Franchisee) {
    const data = {
      Franchisee: Franchisee
    }
    return this.afs.collection("Domaine").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
  }

  get userId(): string {
    return this.afAuth.auth.currentUser.uid;
  }


  /*
   * Handle Http operation that failed.
   * Let the app continue.
   *
 * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
