// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { PropertyAnalysisModel } from '../_models/PropertyAnalysisModel';

export enum PropertyAnalysisActionTypes {
    PropertyAnalysisOnServerCreated = '[Edit PropertyAnalysis Dialog] PropertyAnalysis On Server Created',
    PropertyAnalysisCreated = '[Edit PropertyAnalysis Dialog] PropertyAnalysis Created',
    PropertyAnalysisUpdated = '[Edit PropertyAnalysis Dialog] PropertyAnalysis Updated',
    PropertyAnalysissStatusUpdated = '[PropertyAnalysiss List Page] PropertyAnalysiss Status Updated',
    OnePropertyAnalysisDeleted = '[PropertyAnalysiss List Page] One PropertyAnalysis Deleted',

    PropertyAnalysissPageRequested = '[PropertyAnalysiss List Page] PropertyAnalysiss Page Requested',
    PropertyAnalysissPageLoaded = '[PropertyAnalysiss API] PropertyAnalysiss Page Loaded',


    PropertyAnalysissPageCancelled = '[PropertyAnalysiss API] PropertyAnalysiss Page Cancelled',
    PropertyAnalysissPageToggleLoading = '[PropertyAnalysiss] PropertyAnalysiss Page Toggle Loading',
    PropertyAnalysisActionToggleLoading = '[PropertyAnalysiss] PropertyAnalysiss Action Toggle Loading'
}

export class PropertyAnalysisOnServerCreated implements Action {
    readonly type = PropertyAnalysisActionTypes.PropertyAnalysisOnServerCreated;
    constructor(public payload: { PropertyAnalysis: PropertyAnalysisModel ,id:string}) { }
}

export class PropertyAnalysisCreated implements Action {
    readonly type = PropertyAnalysisActionTypes.PropertyAnalysisCreated;
    constructor(public payload: { PropertyAnalysis: any }) { }
}

export class PropertyAnalysisUpdated implements Action {
    readonly type = PropertyAnalysisActionTypes.PropertyAnalysisUpdated;
    constructor(public payload: {
        partialPropertyAnalysis: Update<PropertyAnalysisModel>, 
        PropertyAnalysis: PropertyAnalysisModel 
    }) { }
}

export class PropertyAnalysissStatusUpdated implements Action {
    readonly type = PropertyAnalysisActionTypes.PropertyAnalysissStatusUpdated;
    constructor(public  payload: {
        PropertyAnalysiss: PropertyAnalysisModel[],
        status: number
    }) { }
}

export class  OnePropertyAnalysisDeleted implements Action {
    readonly type = PropertyAnalysisActionTypes.OnePropertyAnalysisDeleted;
    constructor(public payload: { id: string ,idNameSpace:string}) {}
}


export class PropertyAnalysissPageRequested implements Action {
    readonly type = PropertyAnalysisActionTypes.PropertyAnalysissPageRequested;
    constructor(public payload: { list: any }) { }
}

export class PropertyAnalysissPageLoaded implements Action {
    readonly type = PropertyAnalysisActionTypes.PropertyAnalysissPageLoaded;
    constructor(public payload: any) { }
}

export class PropertyAnalysissPageCancelled implements Action {
    readonly type = PropertyAnalysisActionTypes.PropertyAnalysissPageCancelled;
}

export class PropertyAnalysissPageToggleLoading implements Action {
    readonly type = PropertyAnalysisActionTypes.PropertyAnalysissPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class PropertyAnalysisActionToggleLoading implements Action {
    readonly type = PropertyAnalysisActionTypes.PropertyAnalysisActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}




export type PropertyAnalysisActions = PropertyAnalysisOnServerCreated
| PropertyAnalysisCreated
| PropertyAnalysisUpdated
| PropertyAnalysissStatusUpdated
| OnePropertyAnalysisDeleted
| PropertyAnalysissPageRequested
| PropertyAnalysissPageLoaded
| PropertyAnalysissPageCancelled
| PropertyAnalysissPageToggleLoading
| PropertyAnalysisActionToggleLoading;
