import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { CouponModel } from '../_models/CouponsModel';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { AuthService } from '../../auth';

const API_PropertyAnalysis_URL = 'api/PropertyAnalysiss';

@Injectable()
export class CouponsService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService
		, private firestore: AngularFirestore, private authService: AuthService, ) { }



	getCouponVerif() {
        this.firestore.collection("coupons").valueChanges().subscribe(res =>{
            console.log(res);
            
        })
        
		// return this.firestore.collection<any>("zipcode").valueChanges();
        return this.firestore.collection("coupons").valueChanges();
        
	}

	updateCredit(id) {
		console.log(id);
		
		const data = {
			used: true
		}
		return this.firestore.collection("coupons").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
	}
	getCoupons() {
		return this.firestore.collection("coupons").valueChanges();}
}
