// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout, User, AuthService } from '../../../../../core/auth';
import { Domaine } from '../../../../../core/auth/_models/Domaine.model';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user$: Observable<User>;

	@Input() avatar = true;
	@Input() greeting = true;
	@Input() badge: boolean;
	@Input() icon: boolean;
	businessName : string;
    idDomaine : string;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private store: Store<AppState>,		private auth: AuthService,
		) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
	// 	this.auth.user$.subscribe(res => { 
	// 		if (res) {
	// 	  this.idDomaine = res.idDomaine ;
	// 	  this.getBusinessName(this.idDomaine);
	// 		}  
	//   });
	}
    // getBusinessName(idDomaine) {
	// 	this.auth.getBusinessName(idDomaine).subscribe((res: Domaine) =>
	// 	{
	// 	if (res) {this.businessName = res.businessName}
	// 	},
	// 	erro => console.log (erro)
	// 	) 
	//    }
	/**
	 * Log out
	 */
	logout() {
		this.auth.signOut();
	}
}
