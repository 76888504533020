import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { PropertyAnalysisModel } from '../_models/PropertyAnalysisModel';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { AuthService } from '../../auth';
import { Zipcode } from '../_models/Zipcode';
import { Http } from '@angular/http';
import { DatePipe } from '@angular/common';
const API_PropertyAnalysis_URL = 'api/PropertyAnalysiss';
import { catchError, map, finalize } from 'rxjs/operators';

@Injectable()
export class PropertyAnalysissService {
	numberBuildings;
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private httpR: Http
		, private firestore: AngularFirestore, private authService: AuthService, public datePipe: DatePipe,
	) { }
	createPropertyAnalysis(PropertyAnalysis: PropertyAnalysisModel): Observable<PropertyAnalysisModel> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<PropertyAnalysisModel>(API_PropertyAnalysis_URL, PropertyAnalysis, { headers: httpHeaders });
	}
	findPortfolio(idNameSpace): Observable<any> {


		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("Portfolio").valueChanges();
	}
	getAnalysis($key, idNameSpace): Observable<any> {
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection<PropertyAnalysisModel>("analysis").doc($key).valueChanges();;
	}
	getMarket_Value_Trends(analysis): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post("https://prod-valorisation-valbridge.appspot.com/Market_Value_Trends",
			{
				// "state":"TX",
				// "propertyTypeLabel":"Office",
				// "zipcode":77340
				"zipCode": analysis.zipCode
			}, { headers: headers })
	}
	updateScore(id, idNameSpace, scoreParam) {
		const data = {
			estimateScore: scoreParam
		}
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true })

	}
	updateDraft(id, idNameSpace) {
		const data = {
			draft: 4
		}  
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true })

	}
	getValuationPredCap(analysis: PropertyAnalysisModel) {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		return this.http.post("https://prod-valorisation-valbridge.appspot.com/predcap",
			{
				"ExpensesDetails": analysis.ExpensesDetails,
				"LoadFactor": analysis.LoadFactor,
				"actualValue": analysis.actualValue,
				"city": analysis.city,
				"clearHeight": analysis.clearHeight,
				"constructionQuality": "",
				"createdBy": analysis.createdBy,
				"createdByName": analysis.createdByName,
				"dateMostRecentSale": analysis.dateMostRecentSale,
				"draft": analysis.draft,
				"effectiveGrossIncome": analysis.effectiveGrossIncome,
				"effectiveGrossIncomeYearOne": analysis.effectiveGrossIncomeYearOne,
				"flagAutoCalculeNetOperatingIncomeYearOne": analysis.flagAutoCalculeNetOperatingIncomeYearOne,
				"flagAutoCalculeNetOperatingIncomeYearThree": analysis.flagAutoCalculeNetOperatingIncomeYearThree,
				"flagAutoCalculeNetOperatingIncomeYearTwo": analysis.flagAutoCalculeNetOperatingIncomeYearTwo,
				"grossBuildingArea": analysis.grossBuildingArea,
				"landAreaSF": analysis.landAreaSF,
				"latitude": analysis.latitude,
				"longitude": analysis.longitude,
				"netOperatingIncome": analysis.netOperatingIncome,
				"netRentableArea": analysis.netRentableArea,
				"numberBuildings": analysis.numberBuildings,
				"numberElevators": analysis.numberElevators,
				"numberOnSiteParkingSpaces": analysis.numberOnSiteParkingSpaces,
				"numberStories": analysis.numberStories,
				"numberUnits": analysis.numberUnits,
				"occupancyRate": analysis.occupancyRate,
				"percentAC": analysis.percentAC,
				"propertyCondition": analysis.propertyCondition,
				"propertyName": analysis.propertyName,
				"propertySubType": analysis.propertySubType,
				"propertyType": analysis.propertyType,
				"totalExpensesUserEntered": analysis.totalExpensesUserEntered,
				"totalNumberOverheadDoors": analysis.totalNumberOverheadDoors,
				"typeConstruction": analysis.typeConstruction,
				"valuationDate": analysis.valuationDate,
				"warehouseSquareFootage": analysis.warehouseSquareFootage,
				"yearConstruction": analysis.yearConstruction,
				"MostRecentRenovationDate": analysis.MostRecentRenovationDate,
				"fireSprinkler": "",
				"zipCode": analysis.zipCode
			}, { headers: headers })
	}

	createTransaction(email: string, coupon: string, prod: string, price: string, credit, today2, idDomaine, emailuser, nomDomaine, desc, token) {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		headers = headers.set('Authorization', token);

		return this.http.post("https://valnow-pricing-dot-prodvalbridge.appspot.com/create-session",
			{
				"product_id": prod,
				"price_id": price,
				"email": email,
				"coupon": coupon,
				"transaction_data": {

					"adress": " ",
					"city": " ",
					"credit": credit,
					"date": today2,
					"email": email,
					"ide": idDomaine,
					"iduser": emailuser,
					"nomDomaine": nomDomaine,
					"package": desc,
					"state": " ",
					"type": "Credit purchase",
					"zipCode": " "
				}

			}, { headers: headers })
	}
	checkoutstripe(product_id: string, price_id: string, id: string, token) {
		console.log(product_id);
		console.log(price_id);		
		console.log('https://prodvalbridge.firebaseapp.com/list-property-analysis/report/'+id);
		
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		headers = headers.set('Authorization', token);

		return this.http.post("https://valnow-pricing2-dot-prod-valorisation-valbridge.uc.r.appspot.com/create-session",
			{
				"product_id": product_id,
				"price_id": price_id,
				"coupon": "",
				"url": 'https://prodvalbridge.firebaseapp.com/list-property-analysis/report/'+id,

			}, { headers: headers })
	}
	getPropertyAnalysisById(PropertyAnalysisId: number): Observable<PropertyAnalysisModel> {
		return this.http.get<PropertyAnalysisModel>(API_PropertyAnalysis_URL + `/${PropertyAnalysisId}`);
	}
	eid: string;
	findPropertyAnalysiss(idNameSpace): Observable<any> {
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection<PropertyAnalysisModel>("analysis").valueChanges();
	}  
	updatePropertyAnalysis(PropertyAnalysis: PropertyAnalysisModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_PropertyAnalysis_URL, PropertyAnalysis, { headers: httpHeader });
	}
	updateAnalysis(analysis: PropertyAnalysisModel, id, idNameSpace) {
		return new Promise<any>((resolve, reject) => {

			return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(analysis)), { merge: true });
		});
	}
	updateAnalysisjustestimate(analysis: PropertyAnalysisModel, id, idNameSpace) {
		const data = {
			estimateValue: analysis.estimateValue,
		}
		return new Promise<any>((resolve, reject) => {

			return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
		});
	}
	updatescore(analysis: PropertyAnalysisModel, id, idNameSpace) {
		const data = {
			estimateScore: analysis.estimateScore,
		}
		return new Promise<any>((resolve, reject) => {

			return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
		});
	}
	updateAnalysisjust(analysis: PropertyAnalysisModel, id, idNameSpace) {
		const data = {
			score: analysis.score,
			draft: analysis.draft,
			estimateValue: analysis.estimateValue,

		}
		return new Promise<any>((resolve, reject) => {

			return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
		});
	}

	updateAnal3(analysis: PropertyAnalysisModel, id, idNameSpace) {
		const data = {
			Market_Value_Trends: analysis.Market_Value_Trends,
			Income_Trends: analysis.Income_Trends,
			neareast: analysis.neareast,
			Capitalization_Rate_Analysis: analysis.Capitalization_Rate_Analysis,
			comparablesnb :analysis.comparablesnb,
		}
		return new Promise<any>((resolve, reject) => {

			return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
		});
	}
	updateAnalysisjust0(analysis: PropertyAnalysisModel, id, idNameSpace) {
		const data = {
			score: analysis.score,
			draft: analysis.draft,
			estimateValue: analysis.estimateValue,
			estimateScore: analysis.estimateScore,
			Market_Value_Trends: null,
			Capitalization_Rate_Analysis: null,
			neareast: null,
			Income_Trends: null,
		}
		return new Promise<any>((resolve, reject) => {

			return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
		});
	}
	updateporfo(analysis: PropertyAnalysisModel, id, idNameSpace) {
		const data = {
			listPortfolio: analysis.listPortfolio
		}
		return new Promise<any>((resolve, reject) => {

			return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
		});
	}
	updateInvoicingStatus(idNameSpace,InvoicingStatus, id) {
		const data = {
			InvoicingStatus:InvoicingStatus
		}
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
	}
	updateInvoicingStatusficras(ProductType,email,idNameSpace,InvoicingStatus, id) {
		console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
		console.log(ProductType);
		
		let data; 
		if(email === 'ARD.BELLA@COX.NET'){
			data = {
				InvoicingStatus:"Not to bill",
				ProductType:ProductType
			}
		}else{
			data = {
				InvoicingStatus:InvoicingStatus,
				ProductType:ProductType
			}
		}
		
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
	}

	updateProductType(ProductType,idNameSpace, id) {
		console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
		console.log(ProductType);
		
		let data = {
			ProductType:ProductType
		}
		
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
	}
	updateCredit(transaction,InvoicingStatus, id) {
		const data = {
			credit: transaction,
			InvoicingStatus:InvoicingStatus,
		}
		return this.firestore.collection("Domaine").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
	}
	updateCreditficras(transaction,InvoicingStatus, id) {
		
		const data = {
			credit: transaction,
			InvoicingStatus:InvoicingStatus,
		}
		return this.firestore.collection("Domaine").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
	}
	tok: string;
	today: number = Date.now();
	today2;
	updatepotfolio(list, total, id, portfolio) {
		this.today2 = this.datePipe.transform(this.today, 'MM/dd/yyyy');
		const data = {
			'list': list,
			'total': total,
			'date': this.today2
		}
		portfolio.listtransaction.push(data);
		return this.firestore.doc(`/entreprises/${id}`).collection("Portfolio").doc(portfolio.id).set(JSON.parse(JSON.stringify(portfolio)), { merge: true });

		// return this.firestore.collection("Domaine").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true });
	}
	getreport(id) {

		// this.authKey = localStorage.getItem('jwt_token');

		const httpOptions = {
			responseType: 'blob' as 'json'
		};
 
		return this.http.get("https://report-generator-dot-prodvalbridge.appspot.com/generate_report/"
		+id+'?source=external&api-key=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlaWQiOiJHWXYxNFcwYjc2NzZtTGdOOVphOCJ9.17mD0qKDuOMbl7zblcvAGOYYCoTmnW0n6QQ4MgJ29RE', httpOptions);

		// let headers = new HttpHeaders();
		// headers = headers.append("Content-Type", "application/json");
		// headers = headers.set('Authorization', tok);
		// console.log();

		// return this.http.get("https://report-generator-dot-prodvalbridge.appspot.com/generate_report/" + id, { headers: headers });
	}
	getreportnormal(id, tok) {

		// this.authKey = localStorage.getItem('jwt_token');

		const httpOptions = {
			responseType: 'blob' as 'json',
			headers: new HttpHeaders({
				'Authorization': tok,
			})
		};
 
		return this.http.get("https://report-generator-dot-prodvalbridge.appspot.com/generate_report/"+id, httpOptions);

		// let headers = new HttpHeaders();
		// headers = headers.append("Content-Type", "application/json");
		// headers = headers.set('Authorization', tok);
		// console.log();

		// return this.http.get("https://report-generator-dot-prodvalbridge.appspot.com/generate_report/" + id, { headers: headers });
	}
	updateDraft1(id, idNameSpace) {
		return new Promise<any>((resolve, reject) => {

			return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify({
				'draft': 1
			})), { merge: true });
		});
	}
	updateDraft2(id, idNameSpace) {
		return new Promise<any>((resolve, reject) => {

			return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify({
				'draft': 2
			})), { merge: true });
		});
	}
	updateDrafvalid(id, idNameSpace) {
		return new Promise<any>((resolve, reject) => {

			return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify({
				'draft': 2,
				'canReviewed': true
			})), { merge: true });
		});
	}
	updateDraft3(id, idNameSpace) {
		return new Promise<any>((resolve, reject) => {

			return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify({
				'draft': 3
			})), { merge: true });
		});
	}
	grossBuildingArea: any;
	landAreaSF: any;
	effectiveGrossIncome: any; totalExpensesUserEntered: any;
	netOperatingIncome: any;
	VaccancyStab: any;
	AverageUnitSize: any;
	percentAC: any;
	numberUnits: any;
	totalNumberOverheadDoors: any;
	clearHeight: any;
	officeareaSF: any;
	RenoYr: any;
	numberOnSiteParkingSpaces: any;
	numberElevators: any;
	yearConstruction: any;
	gettoken(token) {
		return this.http.get('https://valnow-external-dot-prodvalbridge.appspot.com/validate_token?api_key='+token)
		.pipe(
			catchError(err => {
			  // this._errorMessage.next(err);
			  console.error('UPDATE ITEM', err);
			  return of(null);
			}),
			finalize(() => {})
		  );
	}
	getvaluation(analysis: PropertyAnalysisModel, id: string): Observable<any> {


		if (!analysis.grossBuildingArea) {
			this.grossBuildingArea = "";
		} else {
			this.grossBuildingArea = +analysis.grossBuildingArea;
		}
		if (!analysis.landAreaSF) {
			this.landAreaSF = "";
		} else {
			this.landAreaSF = +analysis.landAreaSF;
		}
		if (!analysis.effectiveGrossIncomeYearOne) {
			this.effectiveGrossIncome = "";
		} else {
			this.effectiveGrossIncome = +analysis.effectiveGrossIncomeYearOne;
		}
		if (!analysis.totalExpensesUserEnteredYearOne) {
			this.totalExpensesUserEntered = "";
		} else {
			this.totalExpensesUserEntered = +analysis.totalExpensesUserEnteredYearOne;
		}
		if (!analysis.netOperatingIncomeYearOne) {
			this.netOperatingIncome = "";
		} else {
			this.netOperatingIncome = +analysis.netOperatingIncomeYearOne;
		}
		if (!analysis.VaccancyStabOne) {
			this.VaccancyStab = "";
		} else {
			this.VaccancyStab = +analysis.VaccancyStabOne;
		} if (!analysis.AverageUnitSize) {
			this.AverageUnitSize = "";
		} else {
			this.AverageUnitSize = +analysis.AverageUnitSize;
		}
		if (!analysis.percentAC) {
			this.percentAC = "";
		} else {
			this.percentAC = +analysis.percentAC;
		}
		if (!analysis.numberUnits) {
			this.numberUnits = "";
		} else {
			this.numberUnits = +analysis.numberUnits;
		}
		if (!analysis.clearHeight) {
			this.clearHeight = "";
		} else {
			this.clearHeight = +analysis.clearHeight;
		}
		if (!analysis.totalNumberOverheadDoors) {
			this.totalNumberOverheadDoors = "";
		} else {
			this.totalNumberOverheadDoors = +analysis.totalNumberOverheadDoors;
		}
		if (!analysis.officeareaSF) {
			this.officeareaSF = "";
		} else {
			this.officeareaSF = +analysis.officeareaSF;
		}
		if (!analysis.RenoYr) {
			this.RenoYr = "";
		} else {
			this.RenoYr = +analysis.RenoYr;
		}
		if (!analysis.numberElevators) {
			this.numberElevators = "";
		} else {
			this.numberElevators = +analysis.numberElevators;
		}
		if (!analysis.numberOnSiteParkingSpaces) {
			this.numberOnSiteParkingSpaces = "";
		} else {
			this.numberOnSiteParkingSpaces = +analysis.numberOnSiteParkingSpaces;
		}
		if (!analysis.numberBuildings) {
			this.numberBuildings = "";
		} else {
			this.numberBuildings = +analysis.numberBuildings;
		}
		if (+analysis.yearConstruction < 1990) {
			this.yearConstruction = 1990
		} else {
			this.yearConstruction = analysis.yearConstruction;
		}
		if (analysis.review === 1) {
			this.variable = {
				"id": id,
				"valuationDate": analysis.valuationDate,
				"PropertyType": analysis.propertyType,
				"Subtype": analysis.propertySubType,
				"us_zipcode": +analysis.zipCode,
				"city": analysis.city,
				"state_id": analysis.state,
				"Longitude_y": analysis.longitude,
				"Latitude_y": analysis.latitude,
				"Gross Building Area (GBA)": this.grossBuildingArea,
				"Rentable Area - NRA": +analysis.netRentableArea,
				"Land Area (SF)": this.landAreaSF,
				"Buildings": this.numberBuildings,
				"Stories": +analysis.numberStories,
				"BuildingCondition": analysis.propertyCondition,
				"Total Number of Parking Spaces": this.numberOnSiteParkingSpaces,
				"Occupancy Rate as of Effective Date of Value": analysis.occupancyRate,
				"No of Elevators": this.numberElevators,
				"Fire Sprinkler": "",
				"YOC": this.yearConstruction,
				"RenoYr": this.RenoYr,
				"MFAvgProjectUnitAreaSf": this.AverageUnitSize,
				"PercentAC": this.percentAC,
				"Total_Number_of_Units": this.numberUnits,
				"ClearHeight": this.clearHeight,
				"Total_Number_of_Overhead_Doors": this.totalNumberOverheadDoors,
				"officeareaSF": this.officeareaSF,
				"EGIStab": this.effectiveGrossIncome,
				"ExpStab": this.totalExpensesUserEntered,
				"NOIStab": this.netOperatingIncome,
				"VacancyStab": this.VaccancyStab,
			}
		} else {
			this.variable = {
				"id": id,
				"valuationDate": analysis.valuationDate,
				"PropertyType": analysis.propertyType,
				"Subtype": analysis.propertySubType,
				"us_zipcode": +analysis.zipCode,
				"city": analysis.city,
				"state_id": analysis.state,
				"Longitude_y": analysis.longitude,
				"Latitude_y": analysis.latitude,
				"Gross Building Area (GBA)": this.grossBuildingArea,
				"Rentable Area - NRA": +analysis.netRentableArea,
				"Land Area (SF)": this.landAreaSF,
				"Buildings": this.numberBuildings,
				"Stories": +analysis.numberStories,
				"BuildingCondition": analysis.propertyCondition,
				"Total Number of Parking Spaces": this.numberOnSiteParkingSpaces,
				"Occupancy Rate as of Effective Date of Value": analysis.occupancyRate,
				"No of Elevators": this.numberElevators,
				"Fire Sprinkler": "",
				"YOC": this.yearConstruction,
				"RenoYr": this.RenoYr,
				"MFAvgProjectUnitAreaSf": this.AverageUnitSize,
				"PercentAC": this.percentAC,
				"Total_Number_of_Units": this.numberUnits,
				"ClearHeight": this.clearHeight,
				"Total_Number_of_Overhead_Doors": this.totalNumberOverheadDoors,
				"officeareaSF": this.officeareaSF,
				"EGIStab": "",
				"ExpStab": "",
				"NOIStab": "",
				"VacancyStab": "",
			}
		}

		return this.http.post("https://valnow-ml20-ko5and6o3q-uc.a.run.app/predict/v2/"
		,
			this.variable)
	} 
	// https://vbridge-ml-prod-dot-prod-valorisation-valbridge.appspot.com/predict
	Stats_analysis(res,x) {
		
		let headers = new HttpHeaders();
		// https://vbridge-ml-prod-dot-prod-valorisation-valbridge.appspot.com/Stats_analysis
		return this.http.post("https://valnow-ml20-ko5and6o3q-uc.a.run.app/Stats_analysis/v2/", {
			"state": res.state,
			"propertyTypeLabel": res.propertyType,
			"zipcode": +res.zipCode,
			"SubtypeLabel": res.propertySubType,
			"yoc": +res.yearConstruction,
			"nra": +res.netRentableArea,
			'sqt': +x/res.netRentableArea,
			"lng": +res.longitude,
			"lat": +res.latitude,
		}, { headers: headers })
	}
	getvaluation2(id: string): Observable<any> {
		return this.httpR.get("https://prodvalbridge.firebaseio.com/" + id + ".json")
			.map(response => response.json());
	}
	variable: any;
	getDomaine(id) {
		return this.firestore.collection("Domaine").doc(id).valueChanges();
	}

	updateStatusForPropertyAnalysis(PropertyAnalysiss: PropertyAnalysisModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			PropertyAnalysissForUpdate: PropertyAnalysiss,
			newStatus: status
		};
		const url = API_PropertyAnalysis_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}
	updateEstimateHigh(id, idNameSpace, estimatValueParam) {
		console.log("fiii service");
		console.log(estimatValueParam);
		console.log(id);
		console.log(idNameSpace);

		const data = {
			highest: estimatValueParam
		}
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true })

	}
	updateEstimateLow(id, idNameSpace, estimatValueParam) {
		const data = {
			lowest: estimatValueParam
		}
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(data)), { merge: true })

	}
	deletePropertyAnalysis(MyListId: string, idNameSpace: string): Promise<void> {
		return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(MyListId).delete();

	}
	getZipCodeVerif(x) {
		// return this.firestore.collection<any>("zipcode").valueChanges();
		return this.firestore.collection<any>('zipcode', ref => ref.where('zipcode', '==', +x))
			.valueChanges()
	}
	pad(num, size) {
		num = num.toString();
		while (num.length < size) num = "0" + num;
		return num;
	}
	getCheck_existing_properties(zip, prop): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");
		// https://vbridge-ml-prod-dot-prod-valorisation-valbridge.appspot.com/Check_existing_properties
		return this.http.post("https://valnow-ml20-ko5and6o3q-uc.a.run.app/Check_existing_properties/v2/test1/",
			{
				"propertyTypeLabel": prop,
				"zipcode": parseInt(this.pad(zip,5))
			}, { headers: headers }).pipe(
				map((res) => {
					// console.log(res);
					return res;
				}),
				catchError(err => {
					return 'E';
				})
			);
	}
	creatAnalysis(analysis: PropertyAnalysisModel, idNameSpace, id) {
		var prom = new Promise<any>((resolve, reject) => {
			analysis.id = id;
			resolve(id);
			analysis.uid = this.authService.userId;
			this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(analysis)), { merge: true })
		});
		return prom;

	}
	creatAnalysisall(analysis: PropertyAnalysisModel, idNameSpace, id) {
		var prom = new Promise<any>((resolve, reject) => {
			analysis.id = id;
			resolve(id);
			analysis.uid = "";
			this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(analysis)), { merge: true })
		});
		return prom;

	}
	creatAnalysis2(analysis: PropertyAnalysisModel, idNameSpace, id) {
		var prom = new Promise<any>((resolve, reject) => {
			analysis.id = id;
			resolve(id);
			// analysis.uid = this.authService.userId;
			this.firestore.doc(`/entreprises/${idNameSpace}`).collection("analysis").doc(id).set(JSON.parse(JSON.stringify(analysis)), { merge: true })
		});
		return prom;

	}
	deletePropertyAnalysiss(ids: number[] = []): Observable<any> {
		const url = API_PropertyAnalysis_URL + '/deletePropertyAnalysiss';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { PropertyAnalysisIdsForDelete: ids };
		return this.http.put<any>(url, body, { headers: httpHeaders });
	}

	stripeTokenHandler(chargeDetails) {

		//  console.log(chargeDetails);
		console.log("hehiiiiiiiiiiiiiiiiiii");


		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", "application/json");

		return this.http.post("https://us-central1-prodvalbridge.cloudfunctions.net/helloWorld",
			{
				"amount": 3000,
				"currency": 'usd',
				"description": "mfkmekrmer",
				"source": chargeDetails.source,
			}, { headers: headers });



		// const paymentData = {token: token.id};


		// const response = await fetch('/charge', {
		//   method: 'POST',
		//   headers: {
		// 	'Content-Type': 'application/json'
		//   },
		//   body: JSON.stringify(paymentData),
		// });

		// Return and display the result of the charge.
		// return response.json();
	}
}
