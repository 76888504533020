import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { QueryRealtimeParamsModel, HttpExtenstionsModel } from '../../_base/crud';
import { PropertyDetailState } from '../_reducers/PropertyDetail.reducers';
import { PropertyAnalysisModel } from '../_models/PropertyAnalysisModel';

export const selectPropertyDetailsState = createFeatureSelector<PropertyDetailState>('PropertyDetails');

export const selectPropertyDetailById = (PropertyDetailId: number) => createSelector(
    selectPropertyDetailsState,
    PropertyDetailState => PropertyDetailState.entities[PropertyDetailId]
);

export const selectPropertyDetailsPageLoading = createSelector(
    selectPropertyDetailsState,
    PropertyDetailState => PropertyDetailState.listLoading
);

export const selectPropertyDetailsActionLoading = createSelector(
    selectPropertyDetailsState,
    PropertyDetailState => PropertyDetailState.actionsloading
);

export const selectLastCreatedPropertyDetailId = createSelector(
    selectPropertyDetailsState,
    PropertyDetailState => PropertyDetailState.lastCreatedPropertyDetailId
);

export const selectPropertyDetailsShowInitWaitingMessage = createSelector(
    selectPropertyDetailsState,
    PropertyDetailState => PropertyDetailState.showInitWaitingMessage
);

export const selectPropertyDetailsInStore = createSelector(
    selectPropertyDetailsState,
    PropertyDetailState => {
        const items: PropertyAnalysisModel[] = [];
        each(PropertyDetailState.entities, element => {
            items.push(element);
        });        
        const httpExtension = new HttpExtenstionsModel();
        const result: PropertyAnalysisModel[] = httpExtension.sortArray(items, PropertyDetailState.lastQuery.sortField, PropertyDetailState.lastQuery.sortOrder);
        return new QueryRealtimeParamsModel(result);
    }
);

