import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap, map, tap, delay } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryRealtimeParamsModel } from '../../_base/crud';
import { TransactioRedsService } from '../_services';
import { AppState } from '../../reducers';
import {

    TransactioRedActionToggleLoading,
    TransactioRedActionTypes,
    TransactioRedsPageLoaded,
    TransactioRedsPageRequested,
    TransactioRedsPageToggleLoading,


} from '../_actions/TransactioRed.actions';
import { of } from 'rxjs';

@Injectable()
export class TransactioRedEffects {
    showPageLoadingDistpatcher = new TransactioRedsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new TransactioRedActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new TransactioRedActionToggleLoading({ isLoading: false });
    @Effect()
    loadTransactioRedsPage$ = this.actions$.pipe(
        ofType<TransactioRedsPageRequested>(TransactioRedActionTypes.TransactioRedsPageRequested),
        mergeMap(({ payload }) => {
            console.log("hehehehehehehhe");
            
            this.store.dispatch(this.showPageLoadingDistpatcher);
            return this.TransactioRedsService.findTransactioReds(payload.list);

        }),
        map(response => {            
            const result: QueryRealtimeParamsModel = response;
            const lastQuery: QueryParamsModel = response[1];
            console.log(result);
            
            const pageLoadedDispatch = new TransactioRedsPageLoaded({
                TransactioReds: result
            });
            return pageLoadedDispatch;
        })
    );
  

    constructor(private actions$: Actions, private TransactioRedsService: TransactioRedsService, private store: Store<AppState>) { }

}
