// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { PropertyAnalysisModel } from '../_models/PropertyAnalysisModel';

export enum PropertyDetailActionTypes {
    PropertyDetailOnServerCreated = '[Edit PropertyDetail Dialog] PropertyDetail On Server Created',
    PropertyDetailCreated = '[Edit PropertyDetail Dialog] PropertyDetail Created',
    PropertyDetailUpdated = '[Edit PropertyDetail Dialog] PropertyDetail Updated',
    PropertyDetailsStatusUpdated = '[PropertyDetails List Page] PropertyDetails Status Updated',
    OnePropertyDetailDeleted = '[PropertyDetails List Page] One PropertyDetail Deleted',

    PropertyDetailsPageRequested = '[PropertyDetails List Page] PropertyDetails Page Requested',
    PropertyDetailsPageLoaded = '[PropertyDetails API] PropertyDetails Page Loaded',


    PropertyDetailsPageCancelled = '[PropertyDetails API] PropertyDetails Page Cancelled',
    PropertyDetailsPageToggleLoading = '[PropertyDetails] PropertyDetails Page Toggle Loading',
    PropertyDetailActionToggleLoading = '[PropertyDetails] PropertyDetails Action Toggle Loading'
}

export class PropertyDetailOnServerCreated implements Action {
    readonly type = PropertyDetailActionTypes.PropertyDetailOnServerCreated;
    constructor(public payload: { PropertyDetail: PropertyAnalysisModel }) { }
}

export class PropertyDetailCreated implements Action {
    readonly type = PropertyDetailActionTypes.PropertyDetailCreated;
    constructor(public payload: { PropertyDetail: PropertyAnalysisModel }) { }
}

export class PropertyDetailUpdated implements Action {
    readonly type = PropertyDetailActionTypes.PropertyDetailUpdated;
    constructor(public payload: {
        partialPropertyDetail: Update<PropertyAnalysisModel>, 
        PropertyDetail: PropertyAnalysisModel 
    }) { }
}

export class PropertyDetailsStatusUpdated implements Action {
    readonly type = PropertyDetailActionTypes.PropertyDetailsStatusUpdated;
    constructor(public  payload: {
        PropertyDetails: PropertyAnalysisModel[],
        status: number
    }) { }
}

export class OnePropertyDetailDeleted implements Action {
    readonly type = PropertyDetailActionTypes.OnePropertyDetailDeleted;
    constructor(public payload: { id: string ,idNameSpace:string}) {}
}


export class PropertyDetailsPageRequested implements Action {
    readonly type = PropertyDetailActionTypes.PropertyDetailsPageRequested;
    constructor(public payload: { list: string,createdBy:string }) { }
}

export class PropertyDetailsPageLoaded implements Action {
    readonly type = PropertyDetailActionTypes.PropertyDetailsPageLoaded;
    constructor(public payload: any) { }
}

export class PropertyDetailsPageCancelled implements Action {
    readonly type = PropertyDetailActionTypes.PropertyDetailsPageCancelled;
}

export class PropertyDetailsPageToggleLoading implements Action {
    readonly type = PropertyDetailActionTypes.PropertyDetailsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class PropertyDetailActionToggleLoading implements Action {
    readonly type = PropertyDetailActionTypes.PropertyDetailActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}




export type PropertyDetailActions = PropertyDetailOnServerCreated
| PropertyDetailCreated
| PropertyDetailUpdated
| PropertyDetailsStatusUpdated
| OnePropertyDetailDeleted
| PropertyDetailsPageRequested
| PropertyDetailsPageLoaded
| PropertyDetailsPageCancelled
| PropertyDetailsPageToggleLoading
| PropertyDetailActionToggleLoading;
