// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from '../../../core/reducers/';
import { isLoggedIn } from '../_selectors/auth.selectors';
import { AuthService } from '../_services/auth.service';
import { MatSnackBar } from '@angular/material';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { take, map, tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router, private snackBar: MatSnackBar) { }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        // return this.auth.user$.pipe(
        //     take(1),
        //     map(user => !!(user && user.emailVerified == true && user.idDomaine)), // <-- map to boolean
        //     tap(loggedIn => {
        //         console.log("access");
        //         this.router.navigate(['/auth/addEntreprise']);

        //     })
        // );
        return this.auth.user$
        //    .take(1)
           .map(user => !!(user && user.emailVerified === true  && user.idDomaine))
           .do(validated => {
             if (!validated) {
                 console.log(validated);
             this.router.navigate(['/auth/addEntreprise']); 
             console.log("bbbbbbbbbbbbbbbbbb");
            //  this.snackBar.open('Verif your email', '', {
            //     verticalPosition: 'top',
            //     duration: 4000,
            //     panelClass: ['error-toaster']
            //   });
             } 
         }  )
    }
}
