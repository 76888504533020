import { BaseModel } from '../../_base/crud';
import { DatePipe } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class PropertyAnalysisModel extends BaseModel {
    id: string;
    uid: string = "";
    Income_Trends;
    projectid:string;
    createdBy: string;
    comparablesnb:number;
    neareast;
    firstreport:any
    Capitalization_Rate_Analysis;
    Market_Value_Trends;
    listtransaction;
    listPortfolio;
    
    name: any;
    surname: any;
    userid: any;
    bankid: any;

    createdByName: string;
    valuationDate: any;  // kima hiyaaa
    createdOn: number;
    draft: number = 1;
    status: string;
    propertyName: string; // noo
    propertyType: string;   // string PropertyType
    propertySubType: string; // string Subtype 
    ProductType: string; // string Subtype
    VaccancyStabOne: number
    VaccancyStabTow: number
    VaccancyStabThree: number
    address2: string;

    address: any = ""; //nooooooo
    zipCode: number;   // us_zipcode
    city: string = "";  // city
    state: string = "";  //  state_id
    longitude: number = 0;  //Longitude_y 
    latitude: number =0;  //Latitude_y
    //all property types
    actualValue: string = "";
    ContractPrice: any; // noooooooooooo
    dateMostRecentSale: any; // nooooooooooooooo
    PriorDate: any; // nooooooooooooooo
    PriorValue;
    mostRecentSalePrice: number; //nooooooooooooooo
    dateRecentAppraisal: any; // nooooooooooooooo
    listPrice: number; //nooooooooooooooo
    datelistPrice: any; // nooooooooooooooo
    recentAppraisal: number; //nooooooooooooooo
    //physical characteristics  
    grossBuildingArea: string; // posiiiiiiiiitf Gross Building Area (GBA)
    potentialGrossIncome:number;
    netRentableArea: string; // positiif lezem  Rentable Area - NRA  ****************************************
    autoEffective:boolean=true;
    autoNET:boolean=true;
    yearConstruction: string = "";
    RenoYr: string = "";
    officeareaSF:string;
    landAreaSF: string = ""; //Land Area (SF) number
    numberBuildings: string; // possssssssssssituuuuuuuuuuuuf Buildings 
    numberStories: string; // Stories posiiiiiiiiiiiiitf
    propertyCondition: string; // BuildingCondition
    constructionQuality: string; // nooooooo
    typeConstruction: string = ""; // nooooooooooo 
    totalNumberOverheadDoors: string;
    clearHeight: string = "";
    warehouseSquareFootage: string = "";
    percentAC: string = ""; // PercentAC
    numberTenantSpaces: string;
    MostRecentRenovationDate: string = "";
    numberOnSiteParkingSpaces: string = "";  // Total Number of Parking Spaces
    numberUnits: string;
    tenantPaysGas: string; // nooooooooooooooooooooooooo
    tenantPaysElectric: string; // nooooooooooooooooooooooooo
    tenantPaysWater: string;  // nooooooooooooooooooooooooo
    AverageUnitSize: string;  // nooooooooooooooooooooooooo
    occupancyRate = "";      // numberrrrrrrrrr Occupancy Rate as of Effective Date of Value
    numberElevators: string = "";  // No of Elevators posiiiiiiiiiiiiiiiiiiiiitf
    fireSprinkler: boolean;      // Fire Sprinkler

    //check this
    ExpensesDetails: boolean = false;
    //year one 
    yearOne: boolean = false;
    effectiveGrossIncomeYearOne: any;

    realEstateTaxesYearOne: number ;
    administrationYearOne: number ;
    insuranceYearOne: number;
    repairsMaintenanceYearOne: number;
    utilitiesYearOne: number ;
    replacementReservesYearOne: number ;
    cleaningJanitorialYearOne: number ;
    payrollYearOne: number;
    camYearOne: number ;
    netOperatingIncomeYearOne: number;

    //aeffacerr et a check avavnt  & voir les autre annes 
    securityYearOne: number;
    managementFeesYearOne: number;
    roadsGroundsYearOne: number;
    otherExpensesYearOne: number;
    //une des deux a effacer
    totalExpensesCalculatedYearOne: number;
    totalExpensesUserEnteredYearOne: number;


    //year Two 
    yearTwo: boolean = false;
    effectiveGrossIncomeYearTwo: number;
    realEstateTaxesYearTwo: number = 0;
    administrationYearTwo: number = 0;
    insuranceYearTwo: number = 0;
    repairsMaintenanceYearTwo: number = 0;
    utilitiesYearTwo: number = 0;
    managementFeesYearTwo: number = 0;
    replacementReservesYearTwo: number = 0;
    cleaningJanitorialYearTwo: number = 0;
    payrollYearTwo: number = 0;
    camYearTwo: number = 0;

    securityYearTwo: number = 0;
    roadsGroundsYearTwo: number = 0;
    otherExpensesYearTwo: number = 0;
    totalExpensesCalculatedYearTwo: number;
    totalExpensesUserEnteredYearTwo: number;

    netOperatingIncomeYearTwo: number;

    //year Three 
    yearThree: boolean = false;
    effectiveGrossIncomeYearThree;
    realEstateTaxesYearThree: number = 0;
    administrationYearThree: number = 0;
    insuranceYearThree: number = 0;
    repairsMaintenanceYearThree: number = 0;
    utilitiesYearThree: number = 0;
    managementFeesYearThree: number = 0;
    replacementReservesYearThree: number = 0;
    cleaningJanitorialYearThree: number = 0;
    payrollYearThree: number = 0;
    camYearThree: number = 0;
    securityYearThree: number = 0;
    roadsGroundsYearThree: number = 0;
    otherExpensesYearThree: number = 0;
    totalExpensesCalculatedYearThree: number;
    totalExpensesUserEnteredYearThree;
    netOperatingIncomeYearThree;
    year:boolean=false;
    total:boolean=false;
    InvoicingStatus:string;
    estimateValue: number = -1;
    estimateScore: number = 0;
    highest: number = 0;
    lowest: number = 0;
    review: number = 0;
    score: number = 0;
    flagAutoCalculeNetOperatingIncomeYearOne: boolean = true;
    flagAutoCalculeNetOperatingIncomeYearTwo: boolean = true;
    flagAutoCalculeNetOperatingIncomeYearThree: boolean = true;
    flagAutoCalculeTotalExpensesYearOne: boolean = false;
    flagAutoCalculeTotalExpensesYearTwo: boolean = false;
    flagAutoCalculeTotalExpensesYearThree: boolean = false;

    LoadFactor: number = 10.45;
    effectiveGrossIncome; // hethy blech year one ou year ....    EGIStab
    totalExpensesUserEntered; // mehouchhh yetehsebbb ExpStab
    netOperatingIncome; // calcule la year la chay NOIStab
    VaccancyStab; // kimaaa lokhrina
  valuationDate2: any;
    clear() {
        this.id = '';

    }
}

