
// @Injectable({
//   providedIn: 'root'
// })
// export class TransactionDataService {
//   keyDomaine : string;
//   domaine$ : Observable<any>;
//   listtransaction: AngularFirestoreCollection<Transaction>
//   items: Observable<Transaction[]>; 

//   idDomaine : string;
//     constructor(
//         private authService:AuthService,
//         private firestore: AngularFirestore
//         ) { 
 
//     }
    

    // gettransaction($key,idNameSpace) {   
    //    return this.firestore.doc(`/entreprises/${idNameSpace}`).collection<Transaction>("transaction").doc($key).valueChanges(); ;
    // }  
  
    //  gettransactionList(idNameSpace) {  
    //      return this.firestore.doc(`/entreprises/${idNameSpace}`).collection<Transaction>("transaction").valueChanges();
    
    // }  

    // updatetransaction(transaction,idNameSpace) {
    //   return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("transaction").doc(transaction.$key).set(JSON.parse(JSON.stringify(transaction)), { merge: true });  
    // } 
//     deletetransaction($key: string,idNameSpace) {
//       return this.firestore.doc(`/entreprises/${idNameSpace}`).collection("transaction").doc($key).delete();
//     }   

// }



// 
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { Transaction } from '../_models/TransactionModel';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { AuthService } from '../../auth';

const API_PropertyAnalysis_URL = 'api/PropertyAnalysiss';

@Injectable()
export class TransactionsService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService
		, private firestore: AngularFirestore, private authService: AuthService, ) { }



	// getCouponVerif() {
    //     this.firestore.collection("coupons").valueChanges().subscribe(res =>{
    //         console.log(res);
            
    //     })
        
	// 	// return this.firestore.collection<any>("zipcode").valueChanges();
    //     return this.firestore.collection("coupons").valueChanges();
        
    // }
    creattransaction(transaction : Transaction, idNameSpace,date) {
        return new Promise<any>((resolve, reject) => { 
          transaction.iduser = this.authService.userId; 
          transaction.date=date;
          this.firestore.collection("transaction").doc(transaction.Id).set(JSON.parse(JSON.stringify(transaction)));  
        //   doc(`/entreprises/${idNameSpace}`)
          });
      } 
      creattransactionficras(transaction : Transaction, idNameSpace,date) {
        return new Promise<any>((resolve, reject) => { 
        //   transaction.iduser = this.authService.userId; 
          transaction.date=date;
          this.firestore.collection("transaction").doc(transaction.Id).set(JSON.parse(JSON.stringify(transaction)));  
        //   doc(`/entreprises/${idNameSpace}`)
          });
      } 
}
