import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { MyListActions, MyListActionTypes } from '../_actions/MyList.actions';
import { MyListModel } from '../_models/MyListModel';
import { QueryParamsModel } from '../../_base/crud';

export interface MyListState extends EntityState<MyListModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedMyListId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<MyListModel> = createEntityAdapter<MyListModel>({
    selectId: MyListConfigModel => MyListConfigModel.id
  });
  
export const initialMyListState: MyListState = adapter.getInitialState({
    MyListForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedMyListId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function MyListsReducer(state = initialMyListState, action: MyListActions): MyListState {
    switch  (action.type) {
        case MyListActionTypes.MyListsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedMyListId: undefined
            };
        }    

        case MyListActionTypes.MyListActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }

        case MyListActionTypes.MyListOnServerCreated: return {
            ...state
        };

        case MyListActionTypes.MyListUpdated: return adapter.updateOne(action.payload.partialMyList, state);

        case MyListActionTypes.MyListsStatusUpdated: {
            const _partialMyList: Update<MyListModel>[] = [];
            for (let i = 0; i < action.payload.MyLists.length; i++) {
                _partialMyList.push({
				    id: action.payload.MyLists[i].id,
				    changes: {
                        status: action.payload.status
                    }
			    });
            }
            return adapter.updateMany(_partialMyList, state);
        }

        case MyListActionTypes.OneMyListDeleted: return adapter.removeOne(action.payload.id, state);

        case MyListActionTypes.MyListsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }

        case MyListActionTypes.MyListsPageLoaded: {
            return adapter.addMany(action.payload.MyLists, {
                ...initialMyListState,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}



export const getMyListState = createFeatureSelector<MyListModel>('MyList');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
