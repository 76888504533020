// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, Login } from '../../../../core/auth';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import * as firebase from 'firebase';
import { StaticData } from '../../../../core/property-analysis/_models/StaticData';
import { MatSnackBar } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { Transaction, TransactionsService } from '../../../../core/property-analysis';
import { DatePipe } from '@angular/common';

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
	businessName: '',
	PASSWORD: ''
};

@Component({
	selector: 'kt-add-entreprise',
	templateUrl: './add-entreprise.component.html',
	styleUrls: ['./add-entreprise.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AddEntrepriseComponent implements OnInit, OnDestroy {
	// Public params
	x: boolean = true;
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	vouchers = StaticData.vouchers;
	idDomaine: string;
	account: number;
	voucherValideted: boolean;
	withVoucher: boolean;
	private unsubscribe: Subject<any>;

	private returnUrl: any;
	nomDomaine: any;
	user: any;

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		public authservice: AuthService,
		private afs: AngularFirestore,
		private cdr: ChangeDetectorRef,
		private snackBar: MatSnackBar,
		private route: ActivatedRoute, public datePipe: DatePipe,

		private transactionDataService: TransactionsService,
	) {
		
		this.unsubscribe = new Subject();
		this.today2 = this.today;
		this.authservice.user$.subscribe(res => {
			this.user = res;
		})
	}
	today2: any;
	today: number = Date.now();
	mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";  
	ngOnInit(): void {
		this.initLoginForm();
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params['returnUrl'] || '/';
		});
	}

	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}
	public isValidFlg:boolean = true;

	validatePhoneNo(field) {
	  var phoneNumDigits = field.value.replace(/\D/g, '');
	
	  this.isValidFlg = (phoneNumDigits.length==0 || phoneNumDigits.length == 10);
	
	  var formattedNumber = phoneNumDigits;
	  if (phoneNumDigits.length >= 6)
		formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
	  else if (phoneNumDigits.length >= 3)
		formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);
	
	  field.value = formattedNumber;
	}
	initLoginForm() {
		// demo message to show
		this.loginForm = this.fb.group({
			businessName: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			address: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			contactEmail: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			contactPhone: ['', Validators.compose([
				Validators.required,
				Validators.min(1000000000),
				// Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
				Validators.max(99999999999)
			])
			],
			businessType: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			state: [''],
			zipCode: [''], city: [''],
			credit: [''],
			Franchisee: [''],
		});
	}
	skip() {
		const id = this.afs.createId();
		
		this.transaction.date = this.today2;
		this.transaction.package = 'Free credits';
		// this.transaction.ido = this.firestore.createId();
		this.transaction.credit = 1;
		this.transaction.Id = this.afs.createId();
		this.transaction.email = this.user.email;
		this.transaction.type = "Free credits";
		this.transaction.ide = id; 
		this.transaction.zipCode = '';
		this.transaction.nomDomaine = this.nomDomaine;
		this.transaction.amount = 0;
		this.transaction.coupon = "";
		this.transaction.state='';
		this.transaction.city='';  
		this.transaction.adress='';
		this.transaction.status='ACTIVE';
		this.transactionDataService.creattransaction(this.transaction, id,this.today2);
		this.authservice.creatDomaine2(this.loginForm.value,id,this.today2,this.user.email).then(
			data => {				
				this.router.navigate(['/'])
			}
		);
	}
	/**
	 * Form Submit
	 */
	async submit() {
		const controls = this.loginForm.controls;
		// this.verifVoucher();
		// if (this.loginForm.invalid && this.voucherValideted == false) {
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		} else {
			this.loading = true;
			this.loginForm.patchValue({ credit: 0 });
			const id = this.afs.createId();

			this.transaction.date = this.today2;
			this.transaction.package = 'Free credits';
			// this.transaction.ido = this.firestore.createId();
			this.transaction.credit = 1;
			this.transaction.Id = this.afs.createId();
			this.transaction.email = this.user.email;
			this.transaction.type = "Valuation";
			this.transaction.ide = id;
			this.transaction.zipCode = '';
			this.transaction.nomDomaine = this.nomDomaine;
			this.transaction.amount = 0;
			this.transaction.coupon = "";
			this.transaction.state = '';
			this.transaction.city = '';
			this.transaction.adress = '';
			this.transaction.status='ACTIVE';
			this.transactionDataService.creattransaction(this.transaction, id,this.today2);
			await this.authservice.creatDomaine(this.loginForm.value, id,this.today2).then(
				data => {
					
					this.router.navigate(['/'])
				},
				error => {
					this.authNoticeService.setNotice(this.translate.instant('Namespace already exists !'), 'danger');
				}
			);
		}



	}
	transaction = new Transaction;
	verifVoucher() {
		this.voucherValideted = false;
		this.vouchers.map(snapshot => {
			if (snapshot.Coupon_code == this.loginForm.value.voucher) {
				this.voucherValideted = true;
				this.withVoucher = true;
			} else if (this.loginForm.value.voucher == "") {
				this.voucherValideted = true;
				this.withVoucher = false;
			}
		})

		if (this.voucherValideted == false) {

			this.snackBar.open('Invalide coupon', '', {
				verticalPosition: 'bottom',
				horizontalPosition: 'start',
				duration: 4000,
				panelClass: ['error-toaster']
			});
		}
		else {

			if (this.withVoucher) {
				this.snackBar.open('You have choosen our most valuable coupon !', '', {
					verticalPosition: 'bottom',
					horizontalPosition: 'start',
					duration: 4000,
					panelClass: ['error-toaster']
				});
			}


		}
	}

	isControlHasError(controlbusinessName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlbusinessName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}